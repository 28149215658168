import { FC } from 'react';
import Dashboard from 'components/routes/analysis/dashboard/dashboard';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Users from 'components/routes/analysis/users/users';
import Process from 'components/routes/analysis/process/process';
import Transactions from 'components/routes/analysis/transactions/transactions';
import Roles from 'components/routes/analysis/roles/roles';
import User from 'components/routes/analysis/user/user';
import Error from 'components/common/error';
import Client from 'components/routes/client/client';
import Login from 'components/routes/authentication/login';
import Report from 'components/routes/analysis/report/report';
import Ruleset from 'components/routes/analysis/ruleset/ruleset';
import { CookieCheck } from 'components/common/helpers';
import Administration from 'components/routes/administration/administration';
import RuleEditor from 'components/routes/ruleset/ruleEditor';
import DisabledAuthorisations from 'components/routes/analysis/disabledAuthorisations/disabledAuthorisations';
import { CookiesProvider } from 'react-cookie';
import { ListType } from 'types/analysis';

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider as JotaiProvider } from 'jotai';
import { Helmet } from 'react-helmet-async';
import { getApi } from 'services/common';
import CookieInformation from 'components/routes/cookies/cookies';

const queryClient = new QueryClient();

const MainTemplate: FC = () => {
  const getUserIndex = (path: string) => path.includes("samerolesprofiles") ? 4 : path.includes("profile") ? 3 : path.includes("role") ? 2 : path.includes("transaction") ? 1 : 0;
  const getClientIndex = (path: string) => path.includes("users") ? 2 : path.includes("systems") ? 1 : 0;
  const getEditorIndex = (path: string) => path.includes("sets") ? 1 : path.includes("tests") ? 2 : path.includes("functions") ? 3 : 0;

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <Error error="Please try again"/>,
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "/login",
          element: <Login />
        },
        {
          path: "/analysis/:analysisId",
          children: [
            {
              index: true,
              element: <Dashboard />
            },
            {
              path: "dashboard",
              element: <Dashboard />
            },
            {
              path: "users",
              children: [
                {
                  index: true,
                  element: <Users type={ListType.All} />,
                },
                {
                  path: "test/:testId",
                  element: <Users type={ListType.Test} />
                },
                {
                  path: "transaction/:transactionId",
                  element: <Users type={ListType.Transaction} />
                }
              ]
            },
            {
              path: "user/:userId",
              children: [
                {
                  index: true,
                  element: <User index={0} />
                },
                ...["test", "test/:testId", "transaction", "transaction/:transactionId", "role", "role/:roleId", "profile", "profile/:profileId", "samerolesprofiles"].map(path => ({
                  path: path,
                  element: <User index={getUserIndex(path)} />
                }))
              ]
            },
            {
              path: "businessprocess",
              element: <Process/>,
              children: [
                {
                  path: ":businessProcessId",
                  element: <Process />
                }
              ]
            },
            {
              path: "transactions",
              element: <Transactions />,
              children: [
                {
                  path: ":transactionId",
                  element: <Transactions />
                }
              ]
            },
            {
              path: "roles",
              children: [
                {
                  index: true,
                  element: <Roles index={0} />
                },
                {
                  path: ":roleId",
                  element: <Roles index={0} />
                }
              ]
            },
            {
              path: "profiles",
              children: [{
                index: true,
                element: <Roles index={1} />
              },
              {
                path: ":profileId",
                element: <Roles index={1} />
              }]
            },
            {
              path: "ruleset",
              element: <Ruleset />
            },
            {
              path: "reporting",
              element: <Report />
            },
            {
              path: "authorisations",
              element: <DisabledAuthorisations />
            }
          ]
        },
        {
          path: "/administration",
          children: [
            {
              index: true,
              element: <Administration />
            },
            {
              path: "users",
              element: <Administration index={1} />
            }
          ]
        },
        {
          path: "/ruleset",
          children: [
            {
              index: true,
              element: <RuleEditor index={0} />
            },
            ...["bags", "bags/:bagId", "sets", "sets/:setId", "tests", "tests/:testId", "functions", "functions/:functionId"].map((path: string) => ({
              path: path,
              element: <RuleEditor index={getEditorIndex(path)} />
            }))
          ]
        },
        {
          path: "/client/:clientId",
          children: [
            {
              index: true,
              element: <Client index={0} />
            },
            ...["users", "systems", "analyses"].map(path => ({
              path: path,
              element: <Client index={getClientIndex(path)} />
            }))
          ]
        },
        {
          path: "/cookies",
          element: <CookieInformation/ >
        }
      ]
    },
  ]);
  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
            default-src 'self' ${getApi().replace("/api/v2/", "")}; 
            object-src 'none';
            script-src 'self'; 
            img-src 'self' data:; 
            style-src 'self' 'unsafe-inline'; 
            form-action 'self';
            `}
            />
      </Helmet>
      <JotaiProvider>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <CookieCheck />
          <RouterProvider router={router} />
        </CookiesProvider>
      </JotaiProvider>
    </QueryClientProvider>
  )
}

export default MainTemplate;

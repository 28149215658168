import ClientTemplate from "components/layout/clientTemplate";
import { FC, useState } from "react";
import { Breadcrumb, BreadcrumbItem, Panel, Tab, Tabs } from "@appkit4/react-components";
import ClientView from "./clientView";
import UserView from "../client/userView";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { UserLevel } from "types/analysis";
import { PwCJwt } from "types/common";
import { useCookies } from "react-cookie";

const Administration: FC<{ index?: number }> = ({ index = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(index);
  const [cookie] = useCookies();
  const navigate = useNavigate();

  const checkUserLevel = (level: UserLevel) => {
    let token = cookie["id_token"];
    if(!token) return false;
    const jwtData = jwtDecode<PwCJwt>(token);
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] <= level;
  }

  const getPage = (index: number) => {
    switch(index)
    {
      case 0:
        return <ClientView />
      case 1:
        return <UserView />
    }
  }
  const handleTabChange = (index: number) => {
    switch(index)
    {
      case 0:
        navigate("/administration");
        setActiveIndex(0);
        break;
      case 1:
        navigate("/administration/users");
        setActiveIndex(1);
        break;
    }
  }
  return (
    <ClientTemplate>
      <Breadcrumb>
        <BreadcrumbItem>Administration</BreadcrumbItem>
        <BreadcrumbItem>{index === 0 ? "Clients" : "Users"}</BreadcrumbItem>
      </Breadcrumb>
      <div className="mt-6"
      >
          <Tabs activeIndex={activeIndex} onTabChange={handleTabChange}>
            <Tab label="Clients" value="0"></Tab>
            <Tab label="Users" value="1" disabled={!checkUserLevel(UserLevel.PwCAdmin)}></Tab>
          </Tabs>
          <Panel>
          {
            getPage(activeIndex)
          }
          </Panel>
      </div>
      </ClientTemplate>
  )
}


export default Administration;
import { FC } from 'react';
import AnalysisTemplate from 'components/layout/analysisTemplate';
import ByProcessGraph from 'components/visualisation/byProcessGraph';
import { AnalysisNavigator } from 'components/common/helpers';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components';
import UserGraph from 'components/visualisation/userGraph';
import ByBusinessGraph from 'components/visualisation/byBusinessGraph';

const Dashboard: FC = () => {
  return (
    <AnalysisTemplate>
      <Breadcrumb>
        <BreadcrumbItem>Analysis</BreadcrumbItem>
        <BreadcrumbItem>Dashboard</BreadcrumbItem>
      </Breadcrumb>
      <h1>Dashboard</h1>
      <AnalysisNavigator />
      <div className="grid 2xl:grid-cols-2 xl:grid-cols-1 gap-4">
        <div className="order-last">
          <ByProcessGraph />
        </div>
        <div className="order-first">
          <UserGraph />
          <ByBusinessGraph />
        </div>
      </div>
    </AnalysisTemplate>
  );
}

export default Dashboard;
import { Badge, Button, Checkbox, Column, Combobox, Drawer, DropdownButton, Input, ItemDataType, Search, Select, Table, Tag } from "@appkit4/react-components";
import { ConfirmationModal, FormError, FormWarning, toastMessage, ValidationError } from "components/common/helpers";
import Loader from "components/common/loader";
import { FC, useCallback, useEffect, useState } from "react";
import { checkFormValues, searchFilter } from "services/common";
import { Bag, BagRelation, RuleStatus, RuleSet } from "types/ruleset";
import { Statuses, rulesetTableRender } from "./ruleEditor";
import { ClientData } from "types/analysis";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import ToolTip from "components/common/tooltip";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FetchClients } from "queries/hooks/administration/client";
import { FetchBags, DeleteBag, PostBag, PutBag } from "queries/hooks/ruleset/bag";
import { FetchSets } from "queries/hooks/ruleset/set";
import { useQueryClient } from "@tanstack/react-query";
import { FetchObjectString } from "queries/hooks/ruleset/util";

const initBag: Bag = {
  bagId: "new",
  clientId: "00000000-0000-0000-0000-000000000000",
  versionNumber: 1,
  versionComments: "Initial version",
  versionStatus: RuleStatus.Draft
}

const BagEditor: FC = () => {
  const { bagId } = useParams();
  const [search, setSearch] = useState("");
  const [deleteVisible, setDeleteVisible] = useState<Bag>();
  const [editItem, setEditItem] = useState<Bag>();
  const [statusFilter, setStatusFilter] = useState<number>();
  const [clientFilter, setClientFilter] = useState<string>();
  const [objectBagId, setObjectBagId] = useState<string>();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: clientData, isPending: clientPending } = FetchClients();
  const { data: bags, isPending: bagPending } = FetchBags();
  const { data: sets, isPending: setPending } = FetchSets();
  const { mutate: deleteBag, isPending: deletePending } = DeleteBag();
  const { data: objectString, isPending } = FetchObjectString(objectBagId);

  const copyBag = (bag: Bag) => {
    let newBag = { ...bag, bagId: "new", versionNumber: bag.versionNumber++, name: "Copy of " + bag.name, versionComments: "Copy of " + bag.versionComments, versionStatus: RuleStatus.Draft };
    setEditItem(newBag);
  }
  const getObjects = async (bagId?: string) => {
    if (!bagId) return;
    await toastMessage("Exporting objects");
    setObjectBagId(bagId);
  }
  const getData = useCallback(() => {
    return bags?.filter(f => searchFilter(f, ["name", "description"], search))
      .filter(f => !clientFilter || searchFilter(f, ["clientId"], clientFilter))
      .filter(f => statusFilter === undefined || f.versionStatus === statusFilter)
      .map(m => ({ ...m, actions: "" })) || [];
  }, [search, bags, clientFilter, statusFilter])

  const handleDelete = async () => {
    if (!deleteVisible) return;
    deleteBag(deleteVisible, {
      onSuccess: () => {
        toastMessage("Bag deleted");
        queryClient.invalidateQueries({ queryKey: ["bags"] });
        setDeleteVisible(undefined);
      },
      onError: () => {
        toastMessage("Unable to delete bag", null, "error");
      }
    });
  }
  useEffect(() => {
    if (bagId) {
      setEditItem(bags?.find(f => f.bagId === bagId));
    }
  }, [bagId, bags]);
  useEffect(() => {
    if (objectString !== undefined) {
      console.log(objectString)
      navigator.clipboard.writeText(objectString || "");
      toastMessage("Object string copied to the clipboard");
      setObjectBagId(undefined);
    }
  }, [objectString]);
  return (bagPending || clientPending || setPending
    ? <Loader loadingType="circular"></Loader>
    : (
      <>
        <h3>Bags</h3>
        <p>blah</p>
        <div className="flex items-center gap-4 mt-4 mb-4">
          <div className="shrink">
            <Button kind='primary' icon="icon-plus-outline" onClick={() => {
              setEditItem(initBag);
            }}>New bag</Button>
          </div>
          <div>
            <Search
              searchType={"secondary"}
              onChange={(value: string) => {
                setSearch(value);
              }}
              searchValue={search}
              className="list-filter"
            />
          </div>
          <div>
            <Combobox
              placeholder="Filter by client"
              data={clientData?.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" }) || []}
              value={clientFilter}
              onSelect={value => setClientFilter(value.toString())}
            />
          </div>
          <div>
            <Combobox
              placeholder="Status"
              data={Statuses}
              value={statusFilter}
              onSelect={value => setStatusFilter(value as number)}
              onClear={() => setStatusFilter(undefined)}
            />
          </div>
        </div>
        <Table
          originalData={getData()}
          hasTitle
          striped
        >
          <Column field="name" sortKey="name" renderCell={(row: Bag, field: string) => (
            <Link to={`/ruleset/bags/${row.bagId}`}>
              <b>{row.name}</b>
              <p>{row.description}</p>
            </Link>
          )}>Name</Column>
          <Column field="clientId" sortKey="clientId" renderCell={(row: Bag, field: string) => rulesetTableRender(row, field, clientData || [])}>Client</Column>
          <Column field="versionNumber" sortKey="versionNumber" renderCell={(row: Bag, field: string) => rulesetTableRender(row, field, clientData || [])}>Version</Column>
          <Column field="versionStatus" sortKey="versionStatus" renderCell={(row: Bag, field: string) => rulesetTableRender(row, field, clientData || [])}>Status</Column>
          <Column field="bagRelations" sortKey="bagRelations" renderCell={(row: Bag, field: string) => (
            <>
              <b>{row.bagRelations?.length}</b>
            </>)}
          >Sets</Column>
          <Column field="actions" sortKey="actions" renderCell={(row: Bag, field: string) =>
          (
            <DropdownButton
              splitButton
              compact
              kind="tertiary"
              data={[
                { label: "Edit", value: 1 },
                { label: "Export objects", value: 2 },
                { label: "Make a copy", value: 3 },
                { label: "Delete", value: 4, disabled: row.versionStatus === RuleStatus.Current }
              ]}
              onSelect={(value) => {
                switch (value) {
                  case 1:
                    navigate(`/ruleset/bags/${row.bagId}`);
                    break;
                  case 2:
                    getObjects(row.bagId);
                    break;
                  case 3:
                    copyBag(row);
                    break;
                  case 4:
                    row.bagId && setDeleteVisible(row);
                    break;
                }
              }}
              onClick={() => {
                navigate(`/ruleset/bags/${row.bagId}`);
              }}
            >
              Edit
            </DropdownButton>
          )
          }>Actions</Column>
        </Table>
        <Drawer
          visible={editItem !== undefined}
          initialFocus={true}
          mask={true}
          resizable={true}
          placement="right"
          title={editItem?.bagId === "new" ? "Add new bag" : editItem?.name || "Edit bag"}
          onClose={() => {
            setEditItem(undefined);
            navigate("/ruleset/bags");
          }}
          style={{ minWidth: 1166 }}
        >
          {editItem && <BagForm clients={clientData || []}
            item={editItem}
            close={() => {
              setEditItem(undefined);
              navigate("/ruleset/bags");
            }}
            sets={sets || []} />}
        </Drawer>
        <ConfirmationModal
          visible={deleteVisible !== undefined}
          title="Delete bag"
          cancel={() => setDeleteVisible(undefined)}
          confirm={async () => await handleDelete()}>
          <p>Are you sure you want to delete {deleteVisible?.name || "this bag"}?</p>
        </ConfirmationModal>
      </>
    )
  );
}

const BagForm: FC<{ item: Bag, clients: ClientData[], sets: RuleSet[], close: () => void }> = ({ clients, item, sets, close }) => {
  const [data, setData] = useState(item);
  const [setSelection, setSetSelection] = useState<string[]>([]);
  const [setList, setSetList] = useState<RuleSet[]>([]);
  const [error, setError] = useState<string>();
  const [errors, setErrors] = useState<(keyof Bag)[]>();

  const queryClient = useQueryClient();

  const { mutate: addBag, isPending: addPending } = PostBag();
  const { mutate: updateBag, isPending: updatePending } = PutBag();

  const validateForm = () => {
    setErrors(undefined);
    let errorList: (keyof Bag)[] = [];
    let check = checkFormValues(data, ["clientId", "name", "description", "versionComments", "versionStatus"]);
    if (Array.isArray(check))
      errorList = check;
    if (!hasAtLeastTwoSets()) errorList.push("bagRelations");
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    if (!validateForm()) return;
    var bag: Bag = {
      ...data,
      bagId: item.bagId === "new" ? undefined : item.bagId,
      bagRelations: setList.map(m => ({
        setId: m.setId,
        bagId: item.bagId === "new" ? undefined : item.bagId,
      }) as BagRelation)
    }
    if (bag.bagId === "new") {
      addBag(bag, {
        onSuccess: () => {
          toastMessage("Bag added");
          queryClient.invalidateQueries({ queryKey: ["bags"] });
          close();
        },
        onError: () => {
          setError("Something went wrong, please try again.");
        }
      });
    } else {
      updateBag(bag, {
        onSuccess: () => {
          toastMessage("Bag updated");
          queryClient.invalidateQueries({ queryKey: ["bags"] });
          close();
        },
        onError: () => {
          setError("Something went wrong, please try again.");
        }
      });
    }
  }

  const getData = useCallback(() => {
    return setList.map(m => ({ ...m, actions: "" }));
  }, [setList]);

  const hasAtLeastTwoSets = () => setList.length >= 2;

  useEffect(() => {
    setData(item);
    setSetList(sets.filter(f => item.bagRelations?.map(m => m.setId).includes(f.setId || "")) || []);
    setError(undefined);
  }, [item, sets]);
  return (
    <div className="ap-input-form">
      {
        (
          item.versionStatus === RuleStatus.Current && <FormWarning message="This bag is currently in use. Any changes that you do may change the results." />
        )
      }
      <FormError error={error} />
      <form autoComplete="off">
        <p className="ap-font-medium">Details</p>
        <div className="flex gap-4 flex-wrap">
          <div className="grow">
            <Input
              title="Name"
              type="text"
              value={data.name}
              onChange={(value: string) => setData({ ...data, name: value })}
              className="ap-field"
              error={errors?.includes("name")}
              errorNode={<ValidationError error="Name is required" />}
              required
            />
          </div>
          <div className="basis-1/3">
            <Select
              placeholder="Status"
              data={Statuses}
              value={data.versionStatus || RuleStatus.Draft}
              onSelect={value => setData({ ...data, versionStatus: value as RuleStatus })}
              error={errors?.includes("versionStatus")}
              errorNode={<ValidationError error="Status is required" />}
              required
            />
          </div>
          <div className="basis-1/3 break-after-column">
            <Combobox
              placeholder="Client"
              data={clients.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" })}
              value={data.clientId}
              onSelect={value => setData({ ...data, clientId: value.toString() })}
              className="ap-field"
              error={errors?.includes("clientId")}
              errorNode={<ValidationError error="Client is required" />}
              required
            />
          </div>
          <div className="basis-1/2">
            <Input
              title="Description"
              type="text"
              value={data.description}
              onChange={(value: string) => setData({ ...data, description: value })}
              className="ap-field"
              error={errors?.includes("description")}
              errorNode={<ValidationError error="Description is required" />}
              required
            />
          </div>
          <div className="grow">
            <Input
              title="Version comments"
              type="text"
              value={data.versionComments}
              onChange={(value: string) => setData({ ...data, versionComments: value })}
              className="ap-field"
              error={errors?.includes("versionComments")}
              errorNode={<ValidationError error="Version comments is required" />}
              required
            />
          </div>
        </div>
        <p className="ap-font-medium">Sets</p>
        <Table
          originalData={getData()}
          hasTitle
          striped
        >
          <Column field="name" sortKey="name" renderCell={(row: RuleSet, field: string) => (
            <>
              <b>{row.name}</b>
              <p>{row.description}</p>
            </>
          )}>Name</Column>
          <Column field="clientId" sortKey="clientId" renderCell={(row: RuleSet, field: string) => rulesetTableRender(row, field, clients)}>Client</Column>
          <Column field="versionNumber" sortKey="versionNumber" renderCell={(row: RuleSet, field: string) => rulesetTableRender(row, field, clients)}>Version</Column>
          <Column field="versionStatus" sortKey="versionStatus" renderCell={(row: RuleSet, field: string) => rulesetTableRender(row, field, clients)}>Status</Column>
          <Column field="actions" sortKey="actions" renderCell={(row: RuleSet, field: string) =>
            <>
              <ToolTip
                content="Delete set" position="bottom">
                <span
                  className="Appkit4-icon icon-delete-fill pointer"
                  style={{ marginRight: 8 }}
                  onClick={() => setSetList(setList.filter(f => f.setId !== row.setId))}>
                </span>
              </ToolTip>
            </>
          }>Actions</Column>
        </Table>
        <div className="flex gap-4 mt-4 min">
          <div className="basis-1/2">
            <Combobox
              placeholder="Select sets"
              data={
                sets.filter(f => f.clientId === "00000000-0000-0000-0000-000000000000" || f.clientId === data.clientId)
                  .map(m => ({
                    label: m.name,
                    value: m.setId,
                    description: m.description,
                    versionStatus: m.versionStatus,
                    versionComments: m.versionComments,
                    client: clients.find(f => f.clientId === m.clientId)?.name || "Default"
                  }))
              }
              value={setSelection}
              onSelect={(value) => Array.isArray(value) ? setSetSelection(value.map(m => m.toString())) : setSetSelection([value.toString()])}
              multiple
              showSelectAll={false}
              itemTemplate={(label: React.ReactNode, item: ItemDataType) => {
                return (
                  <div className="flex gap-4 items-center w-full">
                    <div className="basis-22">
                      <Checkbox checked={setSelection?.includes(item.value as string)} />
                    </div>
                    <div className="basis-1/2">
                      <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{item.label}</b></div>
                      <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.description}</div>
                    </div>
                    <div>
                      <Badge value={item.client} />
                    </div>
                    <div className="basis-1/2">
                      <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{Statuses.find(f => f.value === item.versionStatus)?.label}</b></div>
                      <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.versionComments}</div>
                    </div>
                  </div>
                )
              }}
              valueTemplate={(value: SelectValue, item: ItemDataType | ItemDataType[]) => {
                return Array.isArray(item)
                  ? item.map(m => <Tag key={m.value}>{m.label}</Tag>)
                  : <Tag>{item.label}</Tag>
              }}
            />
          </div>
          <div>
            <Button kind="tertiary" onClick={() => {
              setSetList([...setList.filter(f => !setSelection.includes(f.setId || "")) || [], ...sets.filter(f => setSelection.includes(f.setId || ""))]);
              setSetSelection([]);
            }}>Add</Button>
          </div>
        </div>
        {errors?.includes("bagRelations") && <FormError error="A bag must contain at least two sets." />}
        {error && <FormError error={error} />}
      </form >
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={close} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={handleSubmit} kind="primary" loading={addPending || updatePending}>Save</Button>
        </div>
      </div>
    </div >
  );
}

export default BagEditor;
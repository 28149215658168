import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteData, getData, postData, putData } from "services/api-actions";
import { RuleSet } from "types/ruleset";

export const FetchSets = () => useQuery({
  queryKey: ["sets"],
  queryFn: () => getSets(),
  staleTime: 1000 * 60 * 5
});

export const PostSet = () => useMutation({
  mutationFn: (data?: RuleSet) => postSet(data),
  mutationKey: ["sets"],
});

export const PutSet = () => useMutation({
  mutationFn: (data?: RuleSet) => putSet(data),
  mutationKey: ["sets"],
});

export const DeleteSet = () => useMutation({
  mutationFn: (data?: RuleSet) => deleteSet(data),
  mutationKey: ["sets"],
});

const getSets = async () => {
  const data = await getData<RuleSet[]>(`ruleset/set`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const postSet = async (data?: RuleSet) => {
  if (!data) throw new Error("No data provided");
  var testRelations = data.testRelations;
  data.testRelations = undefined;
  const response = await postData(`ruleset/set`, { set: data, setRelations: testRelations});
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as RuleSet;
}
const putSet = async (data?: RuleSet) => {
  if (!data) throw new Error("No data provided");
  var testRelations = data.testRelations;
  data.testRelations = undefined;
  const response = await putData(`ruleset/set`, { set: data, setRelations: testRelations });
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as RuleSet;
}
const deleteSet = async (data?: RuleSet) => {
  if (!data) throw new Error("No data provided");
  const response = await deleteData(`ruleset/set/${data.setId}`);
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as RuleSet;
}
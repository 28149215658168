import { FC } from "react"
import ToolTip from "./tooltip"
import { themeColours } from "style/theme"

const PercentageBar: FC<{ percentage: number, dark?: boolean, color?: string }> = ({ percentage, dark, color }) => (
  <div className="ap-percentagebar" style={{ backgroundColor: dark ? "rgb(59, 59, 59, 0.1)" : "rgb(235, 235, 235, 0.1)"}}>
    <ToolTip
      content={`${percentage}%`}
      position="bottom"
    >
      <div 
        className="ap-percentagebar-amount" 
        style={{ width: `${percentage}%`, backgroundColor: dark ? themeColours.ORANGE : color ? color : "rgb(235, 235, 235, 0.7)" }}
      />
    </ToolTip>
  </div>
)
export default PercentageBar;
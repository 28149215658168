import { useQuery } from "@tanstack/react-query";
import { getData } from "services/api-actions";
import { ListType, ListUser, UserListData } from "types/analysis";
import { MatchingData, MatchTransactionData, MatchWrapper, UserData, UserMatchData } from "types/user";
import { getBasePayload } from "./analysis";
import { GeneralFilter } from "types/common";

export const FetchUserList = (analysisId?: string, type?: ListType, id?: string, filter?: GeneralFilter, search?: string) => useQuery({
  queryKey: ["userList", analysisId, type, id, filter, search],
  queryFn: () => getUserList(analysisId, type, id, filter, search),
  staleTime: 1000 * 60 * 5
});

export const SearchUsers = (analysisId?: string, search?: string) => useQuery({
  queryKey: ["searchUsers", analysisId, search],
  queryFn: () => getUserList(analysisId, ListType.All, undefined, { tests: [ 1, 2, 4, 8 ] }, search),
  staleTime: 1000 * 60 * 5,
  enabled: search !== undefined && search.length > 2
});

export const FetchUserData = (analysisId?: string, userId?: string, filter?: GeneralFilter) => useQuery({
  queryKey: ["userData", analysisId, userId, filter],
  queryFn: () => getUserData(analysisId, userId, filter),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});

export const FetchUserTransactions = (analysisId?: string, userId?: string, filter?: GeneralFilter) => useQuery({
  queryKey: ["userTransactions", analysisId, userId, filter],
  queryFn: () => getUserTransactions(analysisId, userId, filter),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});
export const FetchUserMatches = (analysisId?: string, userId?: string, filter?: GeneralFilter, type?: ListType, id?: string, userData?: UserData, matchingData?: MatchingData, match?: boolean) => useQuery({
  queryKey: ["userMatches", analysisId, userId, filter, type, id],
  queryFn: () => getUserMatches(analysisId, userId, filter, type, id),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5,
  select: (data: MatchWrapper) => ({
    analysisId: analysisId,
    data: data.data,
    id: id,
    type: type,
    name: data.name,
    description: data.description,
    userId: userId
  }) as UserMatchData
});

export const FetchSameRolesProfiles = (analysisId?: string, userId?: string) => useQuery({
  queryKey: ["sameRolesProfiles", analysisId, userId],
  queryFn: () => getSameRolesProfiles(analysisId, userId),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});

export const getUserList = async (analysisId?: string, type?: ListType, id?: string, filter?: GeneralFilter, search?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter, { listType: type, id: id, s: search });
  const data = await getData<UserListData>(`analysis/${analysisId}/users?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

export const getUserData = async (analysisId?: string, userId?: string, filter?: GeneralFilter) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!userId) throw new Error("No user id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter, { userId: userId });
  const data = await getData<UserData>(`analysis/${analysisId}/user/${userId}?${payload || ""}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

export const getUserTransactions = async (analysisId?: string, userId?: string, filter?: GeneralFilter) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!userId) throw new Error("No user id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter, { userId: userId });
  const data = await getData<MatchTransactionData[]>(`analysis/${analysisId}/transactions?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

export const getUserMatches = async (analysisId?: string, userId?: string, filter?: GeneralFilter, type?: ListType, id?: string, ) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  if (!type) throw new Error("No list type provided");
  if (!id) throw new Error("No id provided");
  const payload = getBasePayload(filter, { userId: userId, listType: type, id: id });
  const data = await getData<MatchWrapper>(`analysis/${analysisId}/matches?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getSameRolesProfiles = async (analysisId?: string, userId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!userId) throw new Error("No user id provided");
  const data = await getData<ListUser[]>(`analysis/${analysisId}/userswithsame/${userId}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

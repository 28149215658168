import { FC, useCallback, useEffect } from "react";

import { BusinessProcess } from "types/analysis";
import { Tooltip } from "@appkit4/react-components";
import PercentageBar from "components/common/percentageBar";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import UserStatistics, { getTrend, parseTooltip, UserCount } from "components/common/userStatistics";
import Loader from "components/common/loader";
import { Chevron, TestIcon, TrendIndicator } from "components/common/helpers";
import { ActiveFilters } from "components/layout/filters";
import { isAutoScroll } from "services/common";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchBusinessProcessTests } from "queries/hooks/analysis/process";

const ProcessList: FC<{ businessProcesses: BusinessProcess[] }> = ({ businessProcesses }) => {
  const { analysisId, businessProcessId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const getComparison = useCallback(() => searchParams.get("comparisonAnalysisId") || undefined, [searchParams]);

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending, error } = FetchBusinessProcessTests(analysisId, businessProcessId, userFilter, getComparison());

  useEffect(() => {
    if (isAutoScroll() && businessProcessId) {
      const element = document.getElementById(businessProcessId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }, [businessProcessId, isPending]);

  return (
    <>
      <ActiveFilters rows={businessProcesses.length || 0} />
      <div
        className="ap-list-header ap-business-process-header flex items-center p-6 gap-4">
        <div
          className="basis-12">
        </div>
        <div className="basis-96">
          Business process
        </div>
        <div className="basis-52">
          Matches out of total
        </div>
        <div className="grow">
          Distinct user matches
        </div>
        {
          getComparison()
            ? (<div className="grow">
              Comparison
            </div>)
            : null
        }
      </div>
      {
        businessProcesses.map((process, index) => (
          <div className={`${businessProcessId === process.businessProcessId && "ap-accordion-open"} list-row`} id={process.businessProcessId} key={process.abbreviation}>
            <Link to={businessProcessId === process.businessProcessId ? `/analysis/${analysisId}/businessProcess/` : `/analysis/${analysisId}/businessProcess/${process.businessProcessId}${location.search}`}>
              <div
                className="flex items-center p-6 gap-4">
                <div
                  className="basis-12">
                  <Chevron open={businessProcessId === process.businessProcessId} />
                </div>
                <div className="basis-96">
                  <b>{process.name}</b>
                </div>
                <div className="basis-52">
                  <PercentageBar percentage={Math.ceil((process.userCountDistinct / process.userTotal) * 100)} dark />
                </div>
                <div className="grow">
                  <Tooltip
                    trigger="hover"
                    position="bottom"
                    distance={4}
                    id="tooltip-user"
                    content="Distinct user matches"
                  >
                    <span className="Appkit4-icon icon-audience-fill"></span> <UserCount>{process.userCountDistinct.toLocaleString()}</UserCount>
                  </Tooltip>
                </div>
                <div className="grow">
                  {
                    process.comparisonUserCount
                      ? <TrendIndicator
                          trend={getTrend(process.comparisonUserCount, process.userCount)}
                          value={() => parseTooltip(process.comparisonUserCount, process.userCount)}
                        />
                      : null
                  }
                </div>
              </div>
            </Link>
            {
              businessProcessId !== process.businessProcessId
                ? ""
                : !isPending && !error
                  ? (
                    <div>
                      <div className="p-8 pl-20">
                        <p>{process.description}</p>
                      </div>
                      <div
                        className="ap-list-header ap-business-process-test-header flex items-center p-6 gap-4">
                        <div
                          className="basis-12">
                        </div>
                        <div className="basis-96">
                          Test
                        </div>
                        <div className="basis-20">
                          Test type
                        </div>
                        <div className="basis-52">
                          Matches out of total
                        </div>
                        <div>
                          Distinct user matches
                        </div>
                      </div>
                      {
                        data?.map(test => (
                          <div className="list-row-1" key={test.testId}>
                            <div
                              className="flex items-center p-4 gap-4"
                              key={test.testId}
                            >
                        <div
                          className="basis-12">
                                <Link to={`/analysis/${analysisId}/users/test/${test.testId}`}>
                                  <span className="Appkit4-icon icon-link-outline"></span>
                                </Link>
                              </div>
                              <div className="basis-96">
                                <b>{test.identifier}</b>
                                <p>{test.functions?.map((m, index) => <span className="ap-function-description" key={test.identifier.concat(index.toString())}>{m.description}</span>)}</p>
                              </div>
                              <div className="basis-20"><TestIcon type={test.type} /></div>
                              <div className="basis-52">
                                <PercentageBar percentage={test.stats ? Math.round((test.stats.userCount / process.userCount) * 100) : 0} dark />
                              </div>
                              <div>
                                <UserStatistics stats={test.stats} comparisonStats={test.comparisonStats} />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                  : <Loader inline loadingType="circular" showTitle={false} error={error?.message} />
            }
          </div>
        ))
      }
    </>
  )
}

export default ProcessList;
import { getData } from "services/api-actions";
import { AnalysisData, BusinessProcess, UserStats } from "types/analysis";
import { getBasePayload } from "./analysis";
import { useQuery } from "@tanstack/react-query";
import { BusinessProcessStatistics } from "types/graphs";
import { GeneralFilter } from "types/common";

export const FetchBusinessProcessGraph = (analysisId?: string, filter?: GeneralFilter, analysisData?: AnalysisData[], comparisonId?: string) => useQuery({
  queryKey: ["businessProcessGraph", analysisId, filter, comparisonId],
  queryFn: () => getBusinessProcessGraph(analysisId, filter, analysisData, comparisonId),
  enabled: !!analysisData
});

export const FetchUserGraph = (analysisId?: string, filter?: GeneralFilter) => useQuery({
  queryKey: ["userGraph", analysisId, filter],
  queryFn: () => getUserGraph(analysisId, filter),
  enabled: !!analysisId
});

export const FetchTestsGraph = (analysisId?: string, filter?: GeneralFilter) => useQuery({
  queryKey: ["testsGraph", analysisId, filter],
  queryFn: () => getTestsGraph(analysisId, filter),
  enabled: !!analysisId
});

const getBusinessProcessGraph = async (analysisId?: string, filter?: GeneralFilter, analysisData?: AnalysisData[], comparisonId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  if (!analysisData) throw new Error("No analysis data provided");

  let currentIndex = analysisData.findIndex(f => f.analysisId === analysisId);

  if(comparisonId === undefined)
    comparisonId = analysisData.length === 1
      ? undefined : currentIndex === analysisData.length - 1
        ? analysisData[currentIndex - 1].analysisId
        : analysisData[currentIndex + 1].analysisId;

  const payload = getBasePayload(filter, { comparisonAnalysisId: comparisonId });
  const data = await getData<BusinessProcess[]>(`analysis/${analysisId}/processes?${payload || ""}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getUserGraph = async (analysisId?: string, filter?: GeneralFilter) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter);
  const data = await getData<UserStats>(`graphs/${analysisId}/users?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getTestsGraph = async (analysisId?: string, filter?: GeneralFilter) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter);
  const data = await getData<BusinessProcessStatistics[]>(`graphs/${analysisId}/usersByProcess?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
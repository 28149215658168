import { useQuery, useMutation } from "@tanstack/react-query";
import { getData, postData, putData, deleteData } from "services/api-actions";
import { ClientData } from "types/analysis";

export const FetchClient = (clientId?: string) => useQuery({
  queryKey: ["client", clientId],
  queryFn: () => getClients(clientId),
  enabled: !!clientId,
  select: (data: ClientData[]) => data[0],
  staleTime: 1000 * 60 * 5
});

export const FetchClients = (clientId?: string) => useQuery({
  queryKey: ["clients"],
  queryFn: () => clientId ? getClients(clientId) : getClients(),
  staleTime: 1000 * 60 * 5
});

export const PutClient = () => useMutation({
  mutationFn: (client: ClientData) => putClient(client),
  mutationKey: ["clients"]
});

export const PostClient = () => useMutation({
  mutationFn: (client: ClientData) => postClient(client),
  mutationKey: ["clients"]
});

export const DeleteClient = () => useMutation({
  mutationFn: (client: ClientData) => deleteClient(client),
  mutationKey: ["clients"]
});

const getClients = async (clientId?: string) => {
  const data = clientId
    ? await getData<ClientData[]>(`client/${clientId}`)
    : await getData<ClientData[]>(`client/`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const postClient = async (client?: ClientData): Promise<ClientData> => {
  if (!client) throw new Error("No client provided");
  const data = await postData(`client/`, { client: client });
  if (!data.result) throw new Error("Unexpected error occurred");
  return data.result as ClientData;
}

const putClient = async (client?: ClientData): Promise<ClientData> => {
  if (!client) throw new Error("No client provided");
  const data = await putData(`client/`, { client: client });
  if (!data.result) throw new Error("Unexpected error occurred");
  return data.result as ClientData;
}

const deleteClient = async (client: ClientData): Promise<boolean> => {
  if (!client) throw new Error("No client provided");
  const data = await deleteData<boolean>(`client/${client.clientId}`);
  if (!data.result) throw new Error("Unable to delete client");
  return data.result;
}
import { useQuery, useMutation } from "@tanstack/react-query";
import { getData, putData, postData, deleteData } from "services/api-actions";
import { SapSystem } from "types/analysis";

export const FetchSystems = (clientId?: string) => useQuery({
    queryKey: ["systems", clientId],
    queryFn: () => getSystems(clientId),
    enabled: !!clientId,
    staleTime: 1000 * 60 * 5
});

export const PutSystem = (clientId?: string) => useMutation({
    mutationFn: (system: SapSystem) => putSystem(system),
    mutationKey: ["systems", clientId]
});

export const PostSystem = (clientId?: string) => useMutation({
    mutationFn: (system: SapSystem) => postSystem(system),
    mutationKey: ["systems", clientId]
});

export const DeleteSystem = (clientId?: string) => useMutation({
    mutationFn: (system: SapSystem) => deleteSystem(system),
    mutationKey: ["systems", clientId]
});

const getSystems = async (clientId?: string) => {
    if (!clientId) throw new Error("No client id provided");
    const data = await getData<SapSystem[]>(`client/${clientId}/systems`);
    if (!data.result) throw new Error("No data found");
    return data.result;
}

const putSystem = async (system?: SapSystem): Promise<SapSystem> => {
    if (!system) throw new Error("No system provided");
    const data = await putData(`client/${system.clientId}/system`, { system: system });
    if (!data.result) throw new Error("Unexpected error occurred");
    return data.result;
}

const postSystem = async (system?: SapSystem): Promise<SapSystem> => {
    if (!system) throw new Error("No system provided");
    const data = await postData(`client/${system.clientId}/system`, { system: system });
    if (!data.result) throw new Error("Unexpected error occurred");
    return data.result;
}

const deleteSystem = async (system: SapSystem): Promise<boolean> => {
    if (!system) throw new Error("No system provided");
    const data = await deleteData<boolean>(`client/${system.clientId}/system/${system.systemId}`);
    if (!data.result) throw new Error("Unable to delete system");
    return data.result;
}
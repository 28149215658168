import { FC, ReactNode } from "react";
import ToolTip from "./tooltip";
import { Trend, UserStats } from "types/analysis";
import { TrendIndicator } from "./helpers";

export const UserCount: FC<{ children: ReactNode, small?: boolean }> = ({ children, small = false}) => {
  return (
    <span style={{ display: "inline-block", fontSize: small ? "11px" : "14px"}}>{children}</span>
  );
}

export const parseTooltip = (comparison?: number, current?: number) => {
  if (!comparison || !current) return null;
  let difference = getDifference(comparison, current)
  return (
    <div>
      {parseDifference(difference)}
      {difference > 0
        ? <span className="Appkit4-icon icon-uptrend-fill ap-trend-up" aria-hidden="true"></span>
        : difference < 0
          ? <span className="Appkit4-icon icon-downtrend-fill ap-trend-down" aria-hidden="true"></span>
          : <span className="Appkit4-icon icon-minus-outline ap-font-12" aria-hidden="true"></span>
      }
    </div>
  )
}
const getDifference = (comparison: number, current: number) => current - comparison;

const parseDifference = (difference: number) => difference > 0
  ? `+${difference.toLocaleString()}`
  : difference.toLocaleString();

export const getTrend = (comparison: number, current: number) => comparison > current ? Trend.DOWN : comparison < current ? Trend.UP : Trend.SAME;

const UserStatistics: FC<{ stats?: UserStats, comparisonStats?: UserStats }> = ({ stats, comparisonStats }) => {
  const WIDTH = 100;
  return (
    <>
      <div className="flex items-center gap-4">
        <div style={{ width: WIDTH }}>
          <ToolTip content="Total distinct matches" position="bottom">
            <UserCount>{stats?.userCount.toLocaleString()}</UserCount>
          </ToolTip>
          {(comparisonStats && stats) &&
            <TrendIndicator
              trend={getTrend(comparisonStats.userCount, stats.userCount)}
              value={() => parseTooltip(comparisonStats.userCount, stats.userCount)}
            />}
        </div>
        <div>
          <div className="flex items-center flex-wrap gap-4">
            <div className="basis-1/2">
              <ToolTip content="Dialog users" position="bottom">
                <UserCount small>
                  <span className={`Appkit4-icon icon-person-fill`}></span>
                  {stats?.userDialog?.toLocaleString()}
                </UserCount>
              </ToolTip>
              {(comparisonStats?.userDialog && stats?.userDialog)
                ? <TrendIndicator
                  trend={getTrend(comparisonStats.userDialog, stats.userDialog)}
                  value={() => parseTooltip(comparisonStats.userDialog, stats.userDialog)}
                />
                : null
              }
            </div>
            <div className="grow break-after-column">
              <ToolTip content="System users" position="bottom">
                <UserCount small>
                  <span className={`Appkit4-icon icon-cogs-fill`}></span>
                  {stats?.userSystem?.toLocaleString()}
                </UserCount>
              </ToolTip>
              {(comparisonStats?.userSystem && stats?.userSystem)
                ? <TrendIndicator
                  trend={getTrend(comparisonStats.userSystem, stats.userSystem)}
                  value={() => parseTooltip(comparisonStats.userSystem, stats.userSystem)}
                />
                : null
              }
            </div>
            <div className="basis-1/2">
              <ToolTip content="Service users" position="bottom">
                <UserCount small>
                  <span className={`Appkit4-icon icon-globe-map-fill`}></span>
                  {stats?.userService?.toLocaleString()}
                </UserCount>
              </ToolTip>
              {(comparisonStats?.userService && stats?.userService)
                ? <TrendIndicator
                  trend={getTrend(comparisonStats.userService, stats.userService)}
                  value={() => parseTooltip(comparisonStats.userService, stats.userService)}
                />
                : null
              }
            </div>
            <div className="grow">
              <ToolTip content="Communication users" position="bottom">
                <UserCount small>
                  <span className={`Appkit4-icon icon-telecom-fill`}></span>
                  {stats?.userCommunication?.toLocaleString()}
                </UserCount>
              </ToolTip>
              {(comparisonStats?.userCommunication && stats?.userCommunication)
                ? <TrendIndicator
                  trend={getTrend(comparisonStats.userCommunication, stats.userCommunication)}
                  value={() => parseTooltip(comparisonStats.userCommunication, stats.userCommunication)}
                />
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserStatistics;

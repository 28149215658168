import { FC, ReactNode } from "react";
import { Tooltip } from "@appkit4/react-components";

const ToolTip:FC<{ position?: "top" | "bottom" | "left" | "right", children: ReactNode, content: string | (() => React.ReactNode)}> = ({ children, content, position }) => (
  <Tooltip
    trigger="hover"
    position={position}
    distance={8}
    content={content}
    mouseLeaveDelay={100}
  >
    {children}
  </Tooltip>
)
export default ToolTip;
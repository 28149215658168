import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigation, NavigationItem } from '@appkit4/react-components/navigation';
import Filters from './filters';
import { Drawer } from '@appkit4/react-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { collapsedAtom, navAtom } from 'jotai/store';

const items: NavigationItem[] = [
  {
    name: "Dashboard",
    prefixIcon: "speedometer"
  },
  {
    name: "Analysis",
    prefixIcon: "bargraph",
    suffixIcon: "down-chevron",
    opened: true,
    children: [
      {
        name: "By business process",
      },
      {
        name: "By users"
      },
      {
        name: "By roles/profiles"
      },
      {
        name: "By transactions"
      },
      {
        name: "Disabled authorisations"
      }
    ]
  },
  {
    name: "Reporting",
    prefixIcon: "table-data"
  },
  {
    name: "Rule set",
    prefixIcon: "circuit-board-box"
  },
  {
    name: "Filters",
    prefixIcon: "filter"
  }
]
enum MainLinks {
  "dashboard" = 0,
  "reporting" = 2,
  "ruleset" = 3
}
enum SubLinks {
  "businessprocess" = 0,
  "users",
  "roles",
  "transactions",
  "authorisations"
}
const Sidebar: FC = () => {
  const { analysisId } = useParams();
  var navigate = useNavigate();

  const analysisNav = useAtomValue(navAtom);
  const setAnalysisNav = useSetAtom(navAtom);
  const collapsed = useAtomValue(collapsedAtom);
  const setCollapsed = useSetAtom(collapsedAtom);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  
  const redirect = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item: NavigationItem, index: number) => {
    if (item.name === "Filters") setShowFilters(!showFilters);
    if (MainLinks[index] === undefined) return;
    setAnalysisNav({ mainNav: index });
    navigate(`/analysis/${analysisId}/${MainLinks[index]}`)
  }
  const redirectSub = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item: NavigationItem | undefined, index: number | undefined, indexParent: number) => {
    if (index === undefined) return;
    setAnalysisNav({ subNav: index });
    navigate(`/analysis/${analysisId}/${SubLinks[index]}`)
  }

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  }
  return (
    <div className="ap-navigation-sidebar">
      <Navigation
        navList={items}
        hasHeader={false}
        hasFooter={true}
        selectedIndex={analysisNav.mainNav || undefined}
        selectedSubIndex={analysisNav.subNav || undefined}
        defaultOpenKeys={["1"]}
        onClickItem={redirect}
        onClickSubItem={redirectSub}
        collapsed={collapsed}
        onClickCollapseEvent={handleCollapse}
        showTooltip={true}
      >
      </Navigation>
      <Drawer
        initialFocus={false}
        mask={false}
        placement="left"
        visible={showFilters}
        resizable={true}
        width={300}
        onClose={() => setShowFilters(false)}
      >
        <Filters />
      </Drawer>
    </div>
  )
}
export default Sidebar;
import { ExportUser } from "types/analysis";
import { MatchTable, MatchTransactionData, RuleSetFlatData } from "types/user";

export type HeaderData = {

}
const matchHeaders: Array<{
  key: keyof MatchTable,
  header: string,
  width?: number
}> = [
    {
      key: "function",
      header: "Function",
      width: 16
    },
    {
      key: "transaction",
      header: "Transaction",
      width: 16
    },
    {
      key: "compositeRole",
      header: "Composite role",
      width: 40
    },
    {
      key: "role",
      header: "Role",
      width: 40
    },
    {
      key: "compositeProfile",
      header: "Composite profile",
      width: 40
    },
    {
      key: "profile",
      header: "Profile",
      width: 16
    },
    {
      key: "authorisation",
      header: "Authorization",
      width: 16
    },
    {
      key: "object",
      header: "Object",
      width: 16
    },
    {
      key: "field",
      header: "Field",
      width: 16
    },
    {
      key: "authFrom",
      header: "From",
      width: 16
    },
    {
      key: "authTo",
      header: "To",
      width: 16
    },
    {
      key: "testedValues",
      header: "Tested values",
      width: 16
    }
  ]

const fullMatchHeaders: Array<{
  key: keyof MatchTable,
  header: string,
  width?: number
}> = [{
  key: "test",
  header: "Test",
  width: 16
}, ...matchHeaders];

const userListHeaders: Array<{
  key: keyof ExportUser,
  header: string,
  width?: number
}> = [
    {
      key: "userName",
      header: "Username",
      width: 16
    },
    {
      key: "firstName",
      header: "First name",
      width: 16
    },
    {
      key: "lastName",
      header: "Last name",
      width: 16
    },
    {
      key: "type",
      header: "Type",
      width: 24
    },
    {
      key: "lockStatus",
      header: "Lock status",
      width: 24
    },
    {
      key: "userGroup",
      header: "User group",
      width: 16
    },
    {
      key: "validFrom",
      header: "Valid from",
      width: 16
    },
    {
      key: "validTo",
      header: "Valid to",
      width: 16
    }
  ]

const transactionHeaders: Array<{
  key: keyof MatchTransactionData,
  header: string,
  width?: number
}> = [
    {
      key: "identifier",
      header: "Transaction",
      width: 32
    },
    {
      key: "description",
      header: "Transaction description",
      width: 128
    },
    {
      key: "type",
      header: "Transaction type",
      width: 16
    }
  ]

const rulesetHeaders: Array<{
  key: keyof RuleSetFlatData,
  header: string,
  width?: number
}> = [
    {
      key: "businessProcess",
      header: "Business process",
      width: 16
    },
    {
      key: "test",
      header: "Test",
      width: 16
    },
    {
      key: "testType",
      header: "Test type",
      width: 16
    },
    {
      key: "function",
      header: "Function",
      width: 16
    },
    {
      key: "functionDescription",
      header: "Function description",
      width: 64
    },
    {
      key: "transaction",
      header: "Transaction",
      width: 32
    },
    {
      key: "transactionDescription",
      header: "Transaction description",
      width: 64
    },
    {
      key: "authObject",
      header: "Object",
      width: 16
    },
    {
      key: "field",
      header: "Field",
      width: 16
    },
    {
      key: "testValues",
      header: "Test values",
      width: 32
    }
  ]
const disabledAuthorisationHeaders: Array<{
  key: keyof { businessProcess: string, test: string, function: string, functionDescription: string, transaction: string, transactionDescription: string },
  header: string,
  width?: number
}> = [
    {
      key: "businessProcess",
      header: "Business Process",
      width: 16
    },
    {
      key: "test",
      header: "Test",
      width: 16
    },
    {
      key: "function",
      header: "Function",
      width: 12
    },
    {
      key: "functionDescription",
      header: "Function description",
      width: 48
    },
    {
      key: "transaction",
      header: "Transaction",
      width: 22
    },
    {
      key: "transactionDescription",
      header: "Transaction description",
      width: 48
    }
  ]
export {
  matchHeaders,
  fullMatchHeaders,
  transactionHeaders,
  userListHeaders,
  rulesetHeaders,
  disabledAuthorisationHeaders as disabledAuthorisationsHeaders
}
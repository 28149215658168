import { BaseData, ListType, Role, RuleTransaction } from "./analysis";

export const userLevels = [
  { value: "0", label: "PwC Administrator", description: "PwC Internal Admin User" },
  { value: "1", label: "PwC User", description: " PwC Internal Display User" },
  { value: "2", label: "Client Admin", description: "Client all display user + ability to add new Client Users" },
  { value: "3", label: "Technical", description: "Client Display User with technical details" },
  { value: "4", label: "Business", description: "Client Display User without technical details" }
];

export type MatchingData = {
  tests: TestData[];
  roles: Role[];
  profiles: ProfileData[];
}

export type UserData = {
  userName: string;
  fullName: string;
  firstName: string;
  lastName: string;
  userGroup: string;
  refUserName: string,
  refUserId: string,
  type: "A" | "B" | "C" | "S";
  validity: string,
  lockStatus: string;
  isValidated: boolean;
  createdAt: string;
  lastLogon: string;
} & BaseData & MatchingData
// export type UserMatchData = {
//   type: "test" | "transaction" | "role" | "profile";
//   id: string;
//   matches: MatchData[];
// }
export type TestData = {
  testId: string;
  businessProcess: string;
  businessProcessId: string;
  identifier: string;
  type: "SA" | "SoD";
  description: string;
  functions: FunctionData[];
}
export type FunctionData = {
  functionId: string;
  identifier: string;
  type: string;
  description: string;
}
export type CompositeProfileData = {
  name: string;
  compositeProfileId: string;
  hasMatches?: boolean;
  profiles: ProfileData[];
}
// export type CompositeRoleData = {
//   compositeRoleId: string;
//   description: string;
//   name: string;
//   hasMatches?: boolean;
//   roles: RoleData[];
// }
// export type RoleData = {
//   roleId: string;
//   name: string;
//   isComposite: boolean,
//   description: string;
//   hasMatches?: boolean;
//   profiles: ProfileData[];
//   roles?: RoleData[];
// }

export type RuleSetFlatData = {
  test: string;
  testType: string;
  businessProcess: string;
  function: string;
  functionDescription: string;
  transaction: string;
  transactionDescription: string;
  authObject: string;
  field: string;
  testValues: string;
}
export type ProfileData = {
  profileId: string;
  name: string;
  isComposite: boolean;
  profiles?: ProfileData[];
}
export type UserMatchData = {
  analysisId: string;
  userId: string;
  type: ListType;
  id: string;
  description?: string;
  name?: string;
  data: MatchTransactionData[];
}

export type MatchWrapper = {
  type: string;
  identifier: string;
  name: string;
  description: string;
  data: MatchTransactionData[];
}

export type MatchTransactionData = RuleTransaction & {
  transactionId: string;
  type: string;
  functionId: string;
  identifier: string;
  hasMatches: string;
  matches: MatchData[];
};

export type MatchData = {
  profileId: string;
  authorisationId: string;
  name: string;
  object: string;
  field: string;
  authFrom: string;
  authTo: string;
  testedValues: string;
}

export type MatchTable = {
  test?: string;
  function?: string | null;
  role?: string;
  compositeRole?: string;
  compositeProfile?: string;
  profile?: string;
  transaction?: string;
  authorisation?: string;
  object: string;
  field: string;
  authFrom: string;
  authTo: string;
  testedValues: string;
}
export type PaginationData = {
  totalItems: number;
  offSet: number;
  items: number;
}
import { useMutation, useQuery } from "@tanstack/react-query";
import { getData, deleteData, putData } from "services/api-actions";
import { AnalysisData, LogEntry } from "types/analysis";

export const FetchAnalyses = (clientId?: string) => useQuery({
  queryKey: ["analyses", clientId],
  queryFn: () => getAnalyses(clientId),
  staleTime: 1000 * 60 * 5
});
export const PublishAnalysis = (analysis?: AnalysisData) => useMutation({
  mutationFn: (analysis: AnalysisData) => putAnalysis(analysis),
  mutationKey: ["analyses", analysis?.clientId],
});
export const DeleteAnalysis = (clientId?: string) => useMutation({
  mutationFn: (analysis: AnalysisData) => deleteAnalysis(analysis),
  mutationKey: ["analyses", clientId]
});
export const FetchAnalysisLog = (analysisId?: string, clientId?: string) => useQuery({
  queryKey: ["analysisLog", analysisId, clientId],
  queryFn: () => getAnalysisLog(analysisId, clientId),
  refetchInterval: 1000 * 10
});

const getAnalyses = async (clientId?: string) => {
  if (!clientId) throw new Error("No client id provided");
  const data = await getData<AnalysisData[]>(`client/${clientId}/analyses/all`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
const putAnalysis = async (analysis?: AnalysisData) => {
  if(!analysis) throw new Error("No analysis provided");
  if(!analysis.analysisId) throw new Error("No analysis id provided");
  if(!analysis.clientId) throw new Error("No client id provided");
  const data = await putData<{}, boolean>(`client/${analysis.clientId}/analysis/${analysis.analysisId}/publish`, { });
  if (!data) throw new Error("Unable to publish analysis");
  return data;
}
const deleteAnalysis = (analysis?: AnalysisData) => {
  if (!analysis) throw new Error("No analysis provided");
  const data = deleteData<boolean>(`client/analysis/${analysis.analysisId}`);
  if (!data) throw new Error("Unable to delete analysis");
  return data;
}
const getAnalysisLog = async (analysisId?: string, clientId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!clientId) throw new Error("No client id provided");
  const data = await getData<LogEntry[]>(`client/${clientId}/log/${analysisId}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
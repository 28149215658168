import { FC, useEffect, useState } from "react";
import AnalysisTemplate from "components/layout/analysisTemplate";
import { Badge, Button, Column, DropdownButton, Panel, Select, Table } from "@appkit4/react-components";
import { ItemDataType, SelectValue } from "@appkit4/react-components/esm/select/Select";
import { ExcelReport, ListUser, ReportStatus, ReportType } from "types/analysis";
import { useParams } from "react-router-dom";
import Loader from "components/common/loader";
import { AnalysisNavigator, ConfirmationModal, toastMessage } from "components/common/helpers";
import { downloadFile } from "services/api-actions";
import saveAs from "file-saver";
import ToolTip from "components/common/tooltip";
import { FetchReportData, FetchAnalysisData, FetchReportList, CreateReport, RemoveReport } from "queries/hooks/analysis/analysis";
import { SearchUsers } from "queries/hooks/analysis/user";
import { getDate } from "services/common";

const Report: FC = () => {
  const [deleteVisible, setDeleteVisible] = useState<ExcelReport | null>(null);
  const [reportType, setReportType] = useState<ReportType>(0);
  const [transactions, setTransactions] = useState<SelectValue>();
  const [test, setTest] = useState<SelectValue>();
  const [userSearch, setUserSearch] = useState<string>("");
  const [businessProcesses, setBusinessProcesses] = useState<string[] | undefined>();
  const [reportUsers, setReportUsers] = useState<ListUser[]>([]);
  const [refreshList, setRefreshList] = useState(false);

  const { analysisId } = useParams();

  const { data, isPending, error } = FetchReportData(analysisId);
  const { data: analysisData } = FetchAnalysisData(analysisId);
  const { data: reportList, isPending: isPendingList, error: listError } = FetchReportList(analysisId, refreshList);
  const { data: users, isPending: isPendingUsers } = SearchUsers(analysisId, userSearch);
  const { mutate: doReport, isPending: isAddingReport } = CreateReport(analysisId);
  const { mutate: deleteReport } = RemoveReport(analysisId);

  var reportTypes: ItemDataType[] = [
    { value: 0, label: "Tests by User", description: "Get a list of tests and their results for all or specific user(s)" },
    { value: 1, label: "Authorisations for Users by Test", description: "Get a list of users and authorisation results on a specific test" },
    { value: 2, label: "Authorisations for Users by Transactions", description: "Get a list of users and authorisation results on a specific transaction" }
  ]

  const renderCustom = (row: ExcelReport, field: string) => {
    switch (field as keyof ExcelReport) {
      case "timestamp":
        return row.timestamp && getDate(row.timestamp);
      case "status":
        switch (row["status"]) {
          case ReportStatus.Processing:
            return (<Badge value="Processing" type="primary" />);
          case ReportStatus.Error:
            return (<Badge value="Error" type="danger" />);
          case ReportStatus.Ready:
            return (<Badge value="Ready" type="success" />);
          case ReportStatus.InQueue:
            return (<Badge value="In queue" type="info-outlined" />);
        }
        break;
      case "actions":
        return row.status === ReportStatus.Ready && (
          <DropdownButton
            compact
            splitButton
            kind="tertiary"
            data={[
              { label: "Download", value: 1 },
              { label: "Delete report", value: 2 },
            ]}
            onSelect={async (value) => {
              switch (value) {
                case 1:
                  saveAs(await downloadFile(`report/${row.analysisId}/download/${row.reportId}`), `${row.request.name}.xlsx`);
                  break;
                case 2:
                  setDeleteVisible(row);
                  break;
                default:
                  break;
              }
            }}
            onClick={async () => saveAs(await downloadFile(`report/${row.analysisId}/download/${row.reportId}`), `${row.request.name}.xlsx`)}
          >
            Download
          </DropdownButton>
        );
    }
  }

  useEffect(() => {
    if (data) {
      setBusinessProcesses(Array.from(new Set(data.tests?.map(m => m.businessProcess))).sort((a, b) => a.localeCompare(b)));
    }
  }, [data]);

  const searchUsers = (search: string) => {
    if (search.length < 3)
      setUserSearch("");
    else
      setUserSearch(search);
  }

  const renderTransaction = (transaction: SelectValue) => {
    var tempTransaction = data?.transactions?.find(f => f.identifier === transaction);
    return (
      <div className="list-row" style={{ padding: 8, borderRadius: ".25rem" }} >
        <b>{tempTransaction?.identifier}</b>
        <p>{tempTransaction?.description}</p>
      </div>
    );
  }
  const renderUser = (user: ListUser) => {
    return (
      <div className="list-row" style={{ padding: 8, borderRadius: ".25rem" }} >
        <div className="flex items-center p-4">
          <div className="grow">
            <b>{user.userName}</b>
            <p>{user.name}</p>
          </div>
          <div>
            <ToolTip content={"Remove user from the list"}>
              <span className="Appkit4-icon icon-close-outline pointer" onClick={() => setReportUsers(reportUsers.filter(f => f.userId !== user.userId))}></span>
            </ToolTip>
          </div>
        </div>
      </div>
    )
  }
  const renderTest = (test: SelectValue) => {
    var tempTest = data?.tests?.find(f => f.testId === test);
    return (
      <div className="list-row" style={{ padding: 8, borderRadius: ".25rem" }} >
        <b>{tempTest?.identifier}</b>
        <p>{tempTest?.description}</p>
      </div>
    );
  }
  const getFileName = () => {
    let base = `${analysisData?.client?.name} - ${analysisData?.sapSystem?.sapNickname} - ${getDate(analysisData?.analysisDate || "")} - ${reportTypes[reportType].label}`;
    switch (reportType) {
      case ReportType.AuthorisationsByTest:
        return base.concat(` - ${data?.tests?.find(f => f.testId === test)?.identifier}`);
      case ReportType.AuthorisationsByTransaction:
        return base.concat(` - ${Array.isArray(transactions) && transactions.join(",")}`);
      default:
        return base.concat(` - ${reportUsers.length > 0 ? reportUsers.map(m => m.userName).join(",") : "All users"}`);
    }
  }
  const handleCreateReport = async () => {
    if (isAddingReport) return;
    if (!analysisId || reportType === undefined) return
    toastMessage("Adding report");
    doReport({
      analysisId: analysisId,
      name: getFileName(),
      reportType: reportType,
      testId: test as string,
      userIds: reportUsers.map(m => m.userId) || null,
      transactions: transactions as string[]
    }, {
      onSuccess: () => {
        toastMessage("Report added successfully");
        setTest(undefined);
        setTransactions([]);
        setReportUsers([]);
        setRefreshList(true);
      },
      onError: () => toastMessage("Something went wrong, report not added", "error")
    });
  }
  const handleDeleteReport = async () => {
    if (!deleteVisible) return;
    setDeleteVisible(null)
    toastMessage("Deleting report");
    deleteReport(deleteVisible, {
      onSuccess: () => {
        toastMessage("Report queued for deletion");
        setDeleteVisible(null);
        setRefreshList(true);
      },
      onError: () => toastMessage("Something went wrong, report not deleted", null, "error")
    });
  }

  const checkSelection = () => {
    if (isAddingReport) return true;
    switch (reportType) {
      case ReportType.AuthorisationsByTest:
        return !test || test === null
      case ReportType.AuthorisationsByTransaction:
        return !transactions || (Array.isArray(transactions) && transactions.length === 0);
      default:
        return false;
    }
  }

  useEffect(() => {
    if (!reportList?.some(s => s.status < ReportStatus.Ready)) setRefreshList(false);
  }, [reportList]);
  return (
    <AnalysisTemplate>
      {
        isPending || !data
          ? <Loader loadingType="circular" error={error?.message} />
          : (
            <>
              <AnalysisNavigator />
              <h1>Reporting</h1>
              <Panel title="Create a new report">
                <div className="flex p-4 mt-8 mb-8 gap-4 items-top">
                  <div>
                    <Select
                      placeholder="Report type"
                      data={reportTypes}
                      value={reportType}
                      onSelect={(value) => {
                        setTest(undefined);
                        setTransactions([]);
                        setReportType(value as number);
                      }}
                    />
                    <div className="mt-4">{reportTypes[reportType as number]?.description}</div>
                  </div>
                  <div className="p-3">
                    <span className="Appkit4-icon icon-arrow-right-outline"></span>
                  </div>
                  <div className="grow">
                    <div style={{ display: reportType !== ReportType.AuthorisationsByTransaction ? "none" : "block" }}>
                      <Select
                        data={data?.transactions?.map(m => ({ value: m.identifier, label: m.identifier, description: m.description }))}
                        value={transactions}
                        onSelect={(value) => setTransactions(value)}
                        multiple
                        searchable={true}
                        showSelectAll={false}
                        placeholder="Transactions"
                      />
                      <div className="mt-4">
                        {transactions && (
                          Array.isArray(transactions) && transactions?.map((transaction: SelectValue) => renderTransaction(transaction))
                        )}
                      </div>
                    </div>
                    <div style={{ display: reportType !== ReportType.AuthorisationsByTest ? "none" : "block" }}>
                      <Select
                        id="select-test"
                        data={businessProcesses?.map(process => {
                          return (
                            {
                              label: process,
                              type: "group",
                              children: data?.tests?.filter(f => f.businessProcess === process).map(m => ({
                                value: m.testId,
                                label: m.identifier,
                                description: m.description
                              }))
                            })
                        })
                        }
                        value={transactions}
                        onSelect={(value) => setTest(value)}
                        searchable={true}
                        showSelectAll={false}
                        placeholder="Test"
                        dropdownMatchWidth={800}
                        dropdownStyle={{ height: 1004 }}
                        itemTemplate={(label: React.ReactNode, item: ItemDataType) => {
                          return (
                            <div>
                              <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{item.label}</b></div>
                              <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.description.trim()}</div>
                            </div>
                          )
                        }}
                      />
                      <div className="mt-4">
                        {test && renderTest(test)}
                      </div>
                    </div>
                    <div style={{ display: reportType !== ReportType.TestsByUser ? "none" : "block" }}>
                      <Select
                        placeholder="Users"
                        multiple={true}
                        searchable={true}
                        value={reportUsers.map(m => m.userId)}
                        data={users?.data.map(m => ({ value: m.userId, label: m.userName }))}
                        onSearch={(value) => {
                          searchUsers(value);
                        }}
                        onSelect={(value) => {
                          if (Array.isArray(value)) {
                            let tempUsers = users?.data.filter(f => value.includes(f.userId));
                            if (tempUsers)
                              setReportUsers(reportUsers.filter(f => !tempUsers?.map(m => m.userId).includes(f.userId)).concat(tempUsers))
                          } else {
                            let user = users?.data.find(f => f.userId === value.toString());
                            if (user)
                              setReportUsers([...reportUsers?.filter(f => f.userId !== user?.userId) || [], user]);
                          }
                        }}
                        onClear={() => setUserSearch("")}
                        noResultFound={isPendingUsers && userSearch.length > 2 ? <Loader inline /> : <><p><b>No results.</b></p><p>Search with a username.</p></>}
                        showSelectAll={false}
                      >
                      </Select>
                      <div className="mt-4">
                        {
                          reportUsers.length > 0
                            ? reportUsers?.map((user: ListUser) => renderUser(user))
                            : <p>No users selected / all users</p>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="p-3">
                    <span className="Appkit4-icon icon-arrow-right-outline"></span>
                  </div>
                  <div className="shrink">
                    <Button kind="primary" disabled={checkSelection()} onClick={handleCreateReport}>Create report</Button>
                  </div>
                </div>
              </Panel>
              <Panel title="Report list" style={{ padding: 0, marginTop: 24, marginBottom: 24 }}>
                {
                  (isPendingList && !reportList) || listError
                    ? <Loader loadingType="circular" error={listError?.message} />
                    : <Table
                      originalData={reportList?.map(m => ({ ...m, name: m.request.name, actions: "" })) || []}
                      hasTitle
                      striped
                      sortActive="timestamp"
                      animatedSorting={false}
                    >
                      <Column field="name" sortKey="name">Report name</Column>
                      <Column field="timestamp" sortKey="timestamp" renderCell={renderCustom}>Created</Column>
                      <Column field="status" sortKey="status" renderCell={renderCustom}>Status</Column>
                      <Column field="actions" sortKey="actions" renderCell={renderCustom}>Actions</Column>
                    </Table>
                }

              </Panel>
            </>
          )
      }
      <ConfirmationModal
        visible={deleteVisible !== null}
        title="Delete report"
        cancel={() => setDeleteVisible(null)}
        confirm={async () => deleteVisible && await handleDeleteReport()}>
        <p>Are you sure you want to delete {deleteVisible?.request.name}?</p>
      </ConfirmationModal>
    </AnalysisTemplate>
  );
}

export default Report;
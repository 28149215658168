import { FC } from "react";
import AnalysisTemplate from "components/layout/analysisTemplate";
import Loader from "components/common/loader";
import { useParams } from "react-router-dom";
import TransactionList from "./transactionList";
import { AnalysisNavigator } from "components/common/helpers";
import { Breadcrumb, BreadcrumbItem } from "@appkit4/react-components";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchTransactions } from "queries/hooks/analysis/transaction";

const Transactions: FC = () => {
  const { analysisId, transactionId } = useParams();

  const userFilter = useAtomValue(filterAtom);
  
  const { data, isPending, error } = FetchTransactions(analysisId, userFilter);

  return (
    <AnalysisTemplate>
      <Breadcrumb>
        <BreadcrumbItem>Analysis</BreadcrumbItem>
        <BreadcrumbItem>Transactions</BreadcrumbItem>
        {transactionId && <BreadcrumbItem>{data?.find(f => f.transactionId === transactionId)?.identifier}</BreadcrumbItem>}
      </Breadcrumb>
      <AnalysisNavigator/>
      {isPending || error
        ? <Loader loadingType="circular" error={error?.message}/>
        : (
          <>
            <h1>By Transaction</h1>
            <TransactionList
              transactionList={data || []}
            />
          </>
        )
      }
    </AnalysisTemplate>
  );
}

export default Transactions;

import { Footer } from "@appkit4/react-components";
import { FC } from "react";

const PageFooter: FC = () => {
  const footerContent = "© 2024 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. <br />Please see <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.";

  const footerType = 'links';
  const footerLinks = [
    { name: "Privacy", href: "https://www.pwc.fi/en/privacy.html" },
    { name: "Site provider", href: "https://www.pwc.fi/en/site-provider.html" }
  ]
  return(
    <Footer style={{ padding: 16, backgroundColor: "#F3F3F3" }} content={footerContent} type={footerType} links={footerLinks}/>
  )
}

export default PageFooter;
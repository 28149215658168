import { Button, Table, Column, Badge, Search, Drawer, Input, Switch, List, ListItem, DropdownButton } from "@appkit4/react-components";
import { ConfirmationModal, FormError, toastMessage, ValidationError } from "components/common/helpers";
import Loader from "components/common/loader";
import { FC, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkFormValues, getDate } from "services/common";
import { ClientData } from "types/analysis";
import { ActiveFilters } from "components/layout/filters";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteClient, FetchClients, PostClient, PutClient } from "queries/hooks/administration/client";

const initClient: ClientData = {
  analyses: [],
  clientId: "new",
  description: "",
  name: "",
  disabled: true,
  domains: "",
  sapSystems: [],
  systemUsers: []
}

const ClientView: FC = () => {
  const [search, setSearch] = useState("");
  const [editVisible, setEditVisible] = useState<ClientData>();
  const [deleteVisible, setDeleteVisible] = useState<ClientData>();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: clientData, isPending: isPendingClients } = FetchClients();

  const { mutate: deleteClient } = DeleteClient();

  const getData = useCallback(() => {
    return (clientData && clientData.sort((a, b) => a.name < b.name ? -1 : 1).map(m => ({ ...m, actions: true }))) || [];
  }, [clientData]);

  const handleDelete = async (client: ClientData) => {
    if (!client) return;
    deleteClient(client, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["clients"] });
        setDeleteVisible(undefined);
        toastMessage("Client deleted successfully");
      },
      onError: () => {
        toastMessage("Unable to delete client", null, "error");
      }
    });
  }

  const customRender = (row: ClientData, field: string) => {
    switch (field as keyof ClientData) {
      case "name":
        return (
          <>
            <Link to={`/client/${row.clientId}`} reloadDocument>
              <b>{row.name} <span className="Appkit4-icon icon-hyperlink-fill"></span></b>
              <p>{row.description}</p>
            </Link>
          </>
        );
      case "sapSystems":
        return (
          <>
            <b>{row.sapSystems.length}</b>
          </>
        );
      case "analyses":
        let latestAnalysis = row.analyses.length > 0 && row.analyses.sort((a, b) => (a.analysisDate && b.analysisDate) && a.analysisDate > b.analysisDate ? -1 : 1)[0];
        return (
          <>
            <b>{row.analyses.length}</b>
            {latestAnalysis && <Link to={`/analysis/${latestAnalysis.analysisId}`} reloadDocument><p>Last analysis on {getDate(latestAnalysis.extractionDate || "")}</p></Link>}
          </>
        );
      case "disabled":
        return (
          <>
            {row.disabled ? <Badge value="Disabled" type="danger" /> : <Badge value="Active" type="success" />}
          </>
        )
      case "systemUsers":
        return (
          <>
            <b>{row.systemUsers.length}</b>
          </>
        );
      case "actions":
        return (
          <div className="split-button">
            <DropdownButton
              splitButton
              kind="tertiary"
              compact
              data={
                [
                  { label: "View analyses", value: 1 },
                  { label: "View users", value: 2 },
                  { label: "Edit client", value: 3 },
                  { label: "Delete client", value: 4 }
                ]
              }
              onSelect={(item) => {
                switch (item) {
                  case 1:
                    navigate(`/client/${row.clientId}`);
                    break;
                  case 2:
                    navigate(`/client/${row.clientId}/users`);
                    break;
                  case 3:
                    setEditVisible(row);
                    break;
                  case 4:
                    setDeleteVisible(row);
                    break;
                }
              }}
              onClick={() => window.location.href = `/client/${row.clientId}`}
            >
              View
            </DropdownButton>
          </div>
        )
      case "domains":
        return (
          <>
            {/* {row.domains.split(",").map((domain, i) => domain.length > 1 && <Tag color={shadeRainbow[i]} style={{ color: shadeRainbowText[i] }} className="ap-badge-process" key={domain.trim()} closable={false}>{domain.trim()}</Tag>)} */}
            {row.domains.split(",").join(", ")}
          </>
        )
    }
  }

  return isPendingClients
    ? <Loader loadingType="circular" />
    : (
      <>
        <h3>Clients</h3>
        <p>List of clients</p>
        <div className="flex items-center gap-4 mt-4 pt-4">
          <div className="shrink">
            <Button kind='primary' icon="icon-plus-outline" onClick={() => setEditVisible(initClient)}>New Client</Button>
          </div>
          <div className="grow">
            <Search
              searchType={"secondary"}
              onChange={(value: string) => {
                setSearch(value);
              }}
              searchValue={search}
              className="list-filter"
            />
          </div>
        </div>
        <ActiveFilters rows={clientData?.length || 0} />
        <Table
          originalData={getData()}
          hasTitle
          striped
        >
          <Column field="name" sortKey="name" renderCell={customRender}>Client</Column>
          <Column field="disabled" sortKey="disabled" renderCell={customRender}>Status</Column>
          <Column field="domains" sortKey="domains" renderCell={customRender}>Domains</Column>
          <Column field="sapSystems" sortKey="sapSystems" renderCell={customRender}>Systems</Column>
          <Column field="analyses" sortKey="analyses" renderCell={customRender}>Analyses</Column>
          <Column field="systemUsers" sortKey="systemUsers" renderCell={customRender}>Users</Column>
          <Column field="actions" sortKey="actions" renderCell={customRender}>Actions</Column>
        </Table>
        <Drawer
          initialFocus={false}
          mask={true}
          resizable={true}
          visible={editVisible !== undefined}
          placement="right"
          title={(editVisible && editVisible?.name) || "Add new client"}
          onClose={() => {
            setEditVisible(undefined)
          }}
        >
          {!editVisible ? <Loader loadingType="circular" /> : <ClientForm cancel={() => setEditVisible(undefined)} clientData={editVisible} />}
        </Drawer>
        <ConfirmationModal
          visible={deleteVisible !== undefined}
          title="Delete client"
          cancel={() => setDeleteVisible(undefined)}
          confirm={async () => deleteVisible && await handleDelete(deleteVisible)}>
          <p>Are you sure you want to delete {deleteVisible?.name}?</p>
        </ConfirmationModal>
      </>
    )
}

const ClientForm: FC<{ clientData?: ClientData, cancel: () => void }> = ({ clientData, cancel }) => {
  const [client, setClient] = useState<ClientData>(clientData || initClient);
  const [addDomain, setAddDomain] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState<(keyof ClientData)[] | undefined>();

  const queryClient = useQueryClient();

  const { mutate: addClient, isPending: isAddingClient } = PostClient();
  const { mutate: editClient, isPending: isEditingClient } = PutClient();

  const handleSubmit = () => {
    if (!validateForm(client)) return;
    if (client.clientId === "new") {
      client.clientId = crypto.randomUUID();
      addClient(client, {
        onSuccess: () => {
          toastMessage("Client added");
          queryClient.invalidateQueries({ queryKey: ["clients"] });
          cancel();
        },
        onError: (error) => {
          setError(error.message);
        }
      })
    } else {
      editClient(client, {
        onSuccess: () => {
          toastMessage("Client updated");
          queryClient.invalidateQueries({ queryKey: ["clients"] });
          cancel();
        },
        onError: (error) => setError(error.message)
      })
    }
  }
  const validateForm = (client: ClientData) => {
    if (!client) return;
    setErrors(undefined);
    let errorList: (keyof ClientData)[] = [];
    let check = checkFormValues(client, ["name", "domains", "description"]);
    if (addDomain.length > 0) check.push("domains");
    if (Array.isArray(check))
      errorList = check;
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    setErrors(undefined);
    return true;
  }
  const checkDomainValidity = (domain: string) => {
    if (domain.length === 0) return false;
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return !domainRegex.test(domain);
  }
  const renderDomain = (item: { id: string, domain: string }) => {
    return (
      <ListItem key={item.id} role="option">
        <span className='primary-text'>{item.domain}</span>
        <span aria-label="close" tabIndex={0} role="button" className="Appkit4-icon icon-close-outline" aria-hidden="true" onClick={() => {
          let domains = client.domains.split(",").filter(f => f !== item.domain);
          let domainString = domains.length > 0 ? domains.join(",") : undefined;
          setClient({ ...client, domains: domainString || "" })
        }
        }></span>
      </ListItem>
    )
  }
  return (
    <div className="ap-input-form">
      <div className="flex flex-col gap-2">
        <div>
          <Input
            type="text"
            value={client?.name || ""}
            title="Client name"
            onChange={(value: string) => setClient({ ...client, name: value })}
            required
            error={errors?.includes("name")}
            errorNode={<ValidationError error="Name is required" />}
          />
        </div>
        <div>
          <Input
            type="text"
            value={client?.description || ""}
            title="Client description"
            onChange={(value: string) => setClient({ ...client, description: value })}
            required
            error={errors?.includes("description")}
            errorNode={<ValidationError error="Description is required" />}
          />
        </div>
        <div>
          <p className="ap-font-medium mb-4">Enabled</p>
          <Switch
            checked={!client.disabled}
            onChange={(value) => setClient({ ...client, disabled: !client.disabled })}
            showIndicator
            className="mb-4"
          />
          <p>If the account is disabled, only PwC administrators have access to the client data.</p>
        </div>
        <div>
          <h3>Domains</h3>
          <b>Current domains</b>
          {
            client.domains === ""
              ? <p>No domains added</p>
              : <List
                itemKey="id"
                data={client.domains.split(",").map((domain, index) => ({ id: index, domain: domain }))}
                renderItem={renderDomain}
              />
          }
        </div>
        <div>
          <div className="flex gap-4">
            <div className="grow">
              <Input
                type="text"
                value={addDomain}
                title="Add domain"
                onChange={(value: string) => setAddDomain(value)}
                error={checkDomainValidity(addDomain) || errors?.includes("domains")}
                errorNode={<ValidationError error={errors?.includes("domains") ? "Add the domain or clear the box" : "Invalid domain"} />}
              />
            </div>
            <div>
              <Button 
              className="mt-4"
              add 
              onClick={() => {
                if (checkDomainValidity(addDomain)) return;
                let domains = client.domains.split(",");
                domains.push(addDomain);
                setClient({ ...client, domains: domains.filter(f => f !== "").join(",") })
                setAddDomain("");
              }}>Add</Button>
            </div>
          </div>
          <p>At least one domain is required. All users must have email addresses from the domains defined below.</p>
        </div>
        <div>
          <FormError error={error} />
        </div>
      </div>
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={cancel} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button
            onClick={handleSubmit}
            kind="primary"
            loading={isAddingClient || isEditingClient}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ClientView;
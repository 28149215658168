import { Button, Badge, Upload, Combobox, FileModel, DropdownButton, Search, Table, Column, Drawer } from "@appkit4/react-components";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { useQueryClient } from "@tanstack/react-query";
import { ConfirmationModal, FormError, LogViewer, ValidationError, toastMessage } from "components/common/helpers";
import Loader from "components/common/loader";
import { jwtDecode } from "jwt-decode";
import { FetchAnalyses, DeleteAnalysis, PublishAnalysis } from "queries/hooks/administration/analysis";
import { FetchRulesets } from "queries/hooks/administration/ruleset";
import { FetchSystems } from "queries/hooks/administration/system";
import { FC, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { uploadData } from "services/api-actions";
import { checkFormValues, getDate } from "services/common";
import { AnalysisData, AnalysisStatus, RuleBag, SapSystem, UserLevel } from "types/analysis";
import { PwCJwt } from "types/common";

const AnalysisView: FC = () => {
  const { clientId } = useParams();
  const [visible, setVisible] = useState<string | null>(null);
  const [showPublish, setShowPublish] = useState<AnalysisData | undefined>();
  const [deleteVisible, setDeleteVisible] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [cookie] = useCookies();

  const navigate = useNavigate();

  console.log(showPublish)

  const [showConfirmation, setShowConfirmation] = useState(false);

  const { data: analyses, isPending: isAnalysisPending } = FetchAnalyses(clientId);

  const { data: systems } = FetchSystems(clientId);

  const { data: ruleBags } = FetchRulesets(clientId);

  const { mutate: doDelete } = DeleteAnalysis();

  const handleDelete = async (analysis: AnalysisData) => {
    if (!analysis) return;
    setDeleteVisible(null);
    await doDelete(analysis, {
      onError: () => {
        toastMessage("Deletion failed", null, "error");
      },
      onSuccess: () => {
        toastMessage("Analysis queued for deletion");
      }
    })
  }
  const checkUserLevel = (level: UserLevel) => {
    const jwtData = jwtDecode<PwCJwt>(cookie["id_token"]);
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] <= level;
  }
  const customizeColumn = (row: AnalysisData, field: string) => {
    switch (field) {
      case "analysisDate":
        return getDate(row.analysisDate || "");
      case "extractionDate":
        return (<>{getDate(row[field] || "")}</>);
      case "systemId":
        {
          let system = row.sapSystem;
          return (<>
            <b>{system?.sapNickname}</b>
            <p>{system?.sapSystemName} <span className="Appkit4-icon icon-arrow-right-small-outline" /> {system?.sapNickname} <span className="Appkit4-icon icon-arrow-right-small-outline" /> {system?.sapClient}</p>
          </>)
        }
      case "bag":
        return (<>
          <b>{row[field]?.name}</b>
          <p>{row[field]?.description}</p>
        </>);
      case "status":
        switch (row["status"]) {
          case AnalysisStatus.Analyzing:
            return (<Badge value="Processing" type="info-outlined" />);
          case AnalysisStatus.Error:
            return (<Badge value="Error" type="danger" />);
          case AnalysisStatus.Complete:
            return (<Badge value="Analysed" type="primary" />);
          case AnalysisStatus.Published:
            return (<Badge value="Published" type="success" />);
          case AnalysisStatus.Deleting:
            return (<Badge value="Queued for deletion" type="danger" />);
        }
        break;
      case "actions":
        return (
          <DropdownButton
            compact
            splitButton
            kind="tertiary"
            data={[
              { label: "View analysis", onClick: () => { }, value: 1 },
              { label: "View analysis log", value: 2, disabled: !checkUserLevel(UserLevel.PwCAdmin) },
              { label: "Publish", value: 3, disabled: row["status"] !== 2 || !checkUserLevel(UserLevel.PwCAdmin) },
              { label: "Delete analysis", value: 4, disabled: !checkUserLevel(UserLevel.PwCAdmin) }
            ]}
            onSelect={(value) => {
              switch (value) {
                case 1:
                  navigate(`/analysis/${row["analysisId"]}`);
                  break;
                case 2:
                  setVisible(row["analysisId"] || null);
                  break;
                case 3:
                  setShowPublish(row);
                  break;
                case 4:
                  setDeleteVisible(row['analysisId'] || null);
                  break;
                default:
                  break;
              }
            }}
            onClick={() => navigate(`/analysis/${row["analysisId"]}`)}
          >
            View
          </DropdownButton>
        );
      default:
        return row[field as keyof AnalysisData];
    }
  }

  return (
    (
      <>
        <h3>Analyses</h3>
        {!clientId
          ? <p>If you are an admin, this is the first view you see when logging in. Here you can see a combined list of all analyses from all clients. If you want to create a new analysis, you'll first need to pick the client from the first column. </p>
          : <p>If you are a regular user, this is the first view you will see. Admins can access this view by selecting a client. This is where you can create new analyses for the client.</p>
        }
        <div className="flex items-center gap-4 mt-4 mb-4">
          {
            clientId && (<div className="shrink">
              <Button kind='primary' icon="icon-plus-outline" onClick={() => setShowAdd(true)} disabled={!checkUserLevel(UserLevel.PwCAdmin)}>New analysis</Button>
            </div>)
          }
          <div>
            <Search
              searchType={"secondary"}
              onChange={(value: string) => {
                setSearch(value);
              }}
              searchValue={search}
              className="list-filter"
            />
          </div>
        </div>
        {isAnalysisPending
          ? <Loader loadingType="circular" inline/>
          : <Table
            originalData={analyses?.map(m => ({ ...m, actions: "" })) || []}
            hasTitle
            striped
            sortActive="analysisDate"
          >
            <Column field="analysisId" renderCell={(row) => <Link to={`/analysis/${row.analysisId}`}><span className="Appkit4-icon icon-hyperlink-fill"></span> View analysis</Link>}></Column>
            <Column field="analysisDate" sortKey="analysisDate" renderCell={customizeColumn}>Analysis date</Column>
            <Column field="extractionDate" sortKey="extractionDate" renderCell={customizeColumn}>Extraction date</Column>
            <Column field="bag" sortKey="bag" renderCell={customizeColumn}>Rule set</Column>
            <Column field="systemId" sortKey="systemId" renderCell={customizeColumn}>System</Column>
            <Column field="status" sortKey="status" renderCell={customizeColumn}>Status</Column>
            <Column field="actions" renderCell={customizeColumn}>Actions</Column>
          </Table>
        }

        <Drawer
          initialFocus={false}
          mask={true}
          resizable={true}
          footer={
            <>
              <Button onClick={() => setVisible(null)}>Ok</Button>
            </>
          }
          visible={visible !== null}
          placement="right"
          title="Log"
          onClose={() => setVisible(null)}
        >
          {visible && <LogViewer analysisId={visible || ""} />}
        </Drawer>
        <ConfirmationModal
          visible={deleteVisible !== null}
          title="Delete analysis"
          cancel={() => setDeleteVisible(null)}
          confirm={async () => (deleteVisible && analyses) && await handleDelete(analyses.filter(f => f.analysisId === deleteVisible)[0])}>
          <>
            <p>Are you sure you want to delete this analysis?</p>
            <p>Analysis will be queued for deletion, and will be gone within the next 24 hours.</p>
          </>
        </ConfirmationModal>
        {
          <Drawer
            initialFocus={false}
            mask={true}
            resizable={true}
            visible={showAdd}
            placement="right"
            title="Add new analysis"
            onClose={() => setShowAdd(false)}
          >
            <AnalysisForm
              systems={systems || []}
              ruleBags={ruleBags || []}
              cancel={() => {
                setShowAdd(false);
              }}
              confirmed={() => {
                setShowAdd(false);
                setShowConfirmation(true);
              }}
            />
          </Drawer>
        }
        <ConfirmationModal
          visible={showConfirmation}
          title="Analysis created"
          cancel={() => {
            setShowConfirmation(false);
          }}
          confirm={() => {
            setShowConfirmation(false);
          }}
        >
          <p>Analysis has been created and is now being processed.</p>
          <p>It will appear on the list of analyses within the next 15 minutes. You can follow the progress via log view.</p>
        </ConfirmationModal>
        <PublishForm analysis={showPublish} close={() => setShowPublish(undefined)} />
      </>
    )
  )
}

const PublishForm: FC<{analysis: AnalysisData | undefined, close: () => void}> = ({ analysis , close }) => {
  const { clientId } = useParams();
  const queryClient = useQueryClient();
  const { mutate: publishAnalysis, isPending: addingSystem } = PublishAnalysis(analysis);

  const handleSubmit = async () => {
    if (!analysis) return;
    await publishAnalysis(analysis, {
      onError: () => {
        toastMessage("Publishing failed", null, "error");
      },
      onSuccess: () => {
        toastMessage("Analysis published");
        queryClient.invalidateQueries({ queryKey: ["analyses", clientId] });
        close();
      }
    });
  }
  return(
    <ConfirmationModal
    visible={analysis !== undefined}
    title="Publish analysis"
    cancel={ () => close()}
    confirm={ () => handleSubmit() }
  >
    <p>Are you sure you want to publish this analysis?</p>
    <p>Once published, the analysis will be available for the client to view.</p>
  </ConfirmationModal>
  )
}

interface AnalysisFormProps {
  systems: SapSystem[];
  ruleBags: RuleBag[];
  cancel: () => void;
  confirmed: () => void;
}
type AnalysisFormType = {
  system?: SelectValue;
  ruleset?: SelectValue;
  file?: FileModel;
}

const AnalysisForm: FC<AnalysisFormProps> = ({ cancel, ruleBags, systems, confirmed }) => {
  const { clientId } = useParams();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState<AnalysisFormType>();
  const [errors, setErrors] = useState<(keyof AnalysisFormType)[] | undefined>();

  const handleSubmit = async (file: FileModel, system: string, bag: string) => {
    if (!clientId) return false;
    if (!validateForm()) return false;
    if (!analysis?.file || !analysis.ruleset || !analysis.system) return false;
    setLoading(true);
    let payload = new FormData();
    payload.append("file", analysis.file.originFile as Blob, analysis?.file.name);
    payload.append("systemId", analysis.system.toString());
    payload.append("bagId", analysis.ruleset.toString());
    payload.append("clientId", clientId);
    let request = await uploadData(`client/${clientId}/analysis`, payload, (progress) => console.log(progress));
    if (!request.isError) {
      confirmed();
      setLoading(false);
    }
    setError("Unable to create new analysis, please contact support if the issue persists");
    setLoading(false);
  }
  const validateForm = () => {
    if (!analysis) return;
    setErrors(undefined);
    let errorList: (keyof AnalysisFormType)[] = [];
    let check = checkFormValues(analysis, ["system", "ruleset"]);
    if (!analysis.file)
      check.push("file");
    if (Array.isArray(check))
      errorList = check;
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    setErrors(undefined);
    return true;
  }
  const fileChange = (file: FileModel, files: FileList) => {
    setAnalysis({ ...analysis, file: file });
  }
  return (
    <div className="ap-input-form">
      <FormError error={error} />
      <form autoComplete="off">
        <div className="flex flex-col gap-4">
          <div>
            <div className="ap-pattern-form">
              <span className='ap-pattern-form-title'>
                Basic information
              </span>
              <span className='ap-pattern-form-required-indicator'>
                Required Fields
              </span>
            </div>
          </div>
          <div>
            <Combobox
              data={systems.map(system => ({ value: system.systemId, label: system.sapNickname, description: system.sapSystemName }))}
              onSelect={(value) => setAnalysis({ ...analysis, system: value })}
              value={analysis?.system}
              placeholder="System"
              dropdownRenderMode="portal"
              required
              suffixTemplate={item => {
                return (item.description && <span>{item.description}</span>)
              }}
              error={errors?.includes("system")}
              errorNode={<ValidationError error="System is required" />}
            />
          </div>
          <div>
            <Combobox
              data={ruleBags.map(bag => ({ value: bag.bagId, label: bag.name, description: bag.description }))}
              onSelect={(value) => setAnalysis({ ...analysis, ruleset: value })}
              value={analysis?.ruleset}
              required
              placeholder="Rule set"
              dropdownRenderMode="portal"
              suffixTemplate={item => {
                return (item.description && <span>{item.description}</span>)
              }}
              error={errors?.includes("ruleset")}
              errorNode={<ValidationError error="Rule set is required" />}
            />
          </div>
          <div>
            <h3>File</h3>
            <Upload
              onChange={fileChange}
              autoUpload={false}
              multiple={false}
              acceptFileType=".zip"
              uploadTitle="Upload extracted data"
              uploadInstruction="You can only upload files that are in PwC Extract format. Otherwise the analysis process will fail."
            />
            {errors?.includes("file") && <ValidationError error="File is required" />}
          </div>
        </div>
      </form>
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={cancel} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button
            onClick={handleSubmit}
            kind="primary"
            loading={loading}
          >Save</Button>
        </div>
      </div>
    </div>
  )
}

export default AnalysisView;
import { Select, Search, Button } from "@appkit4/react-components";
import { SelectValue } from "@appkit4/react-components/esm/select/Select";
import { Floater, toastMessage } from "components/common/helpers";
import Paginate from "components/common/paginate";
import ToolTip from "components/common/tooltip";
import { FC, useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getDate, paginate, searchFilter, selectFilter, updateParams } from "services/common";
import { exportClipboard, exportExcel } from "services/export";
import { transactionHeaders } from "types/exportHeaders";
import { TransactionType } from "types/analysis";
import { MatchTransactionData } from "types/user";
import TransactionDetails from "./transactionDetails";
import { ActiveFilters } from "components/layout/filters";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchTransactionFunctions } from "queries/hooks/analysis/transaction";
import { FetchAnalysisData } from "queries/hooks/analysis/analysis";

interface TransactionListProps {
  transactionList: MatchTransactionData[];
}

const TransactionList: FC<TransactionListProps> = ({
  transactionList
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { analysisId, transactionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [transactionTypeFilter, setTransactionTypeFilter] = useState<SelectValue>(searchParams.get("type")?.split(":") || "");
  const [filteredData, setFilteredData] = useState(transactionList);

  const getCurrentPage = useCallback(() => parseInt(searchParams.get("p") || "1"), [searchParams]);
  const getOffset = useCallback(() => parseInt(searchParams.get("o") || "100"), [searchParams]);
  const getTotalPages = useCallback(() => Math.ceil((filteredData.length || getOffset()) / getOffset()), [getOffset, filteredData]);

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending } = FetchTransactionFunctions(analysisId, transactionId, userFilter);

  const { data: analysisData } = FetchAnalysisData(analysisId);

  useEffect(() => {
    console.time("processing");
    let tempData = transactionList
      .filter(f => searchFilter(f, ["description", "identifier"], search))
      .filter(f => selectFilter(f, "type", transactionTypeFilter))

    setFilteredData(tempData);
    console.timeEnd("processing");
  }, [transactionList, transactionTypeFilter, search]);

  const fileExport = () => {
    let data = analysisData;
    if (!data) return;
    let pageHeaders = [
      ["Insights - Access Risks"],
      ["SAP System", `${data.sapSystem?.sapSystemName} - ${data.sapSystem?.sapClient} - ${data.sapSystem?.sapNickname}`],
      ["Client", data.client?.name || ""],
      ["Extraction date", getDate(data.extractionDate || "")],
      ["List type:", "Transactions"]
    ]
    const tempData = filteredData.map(m => ({
      ...m,
      type: TransactionType[m.type as keyof typeof TransactionType]
    }));
    let filename = `${data.client?.name}-${data.sapSystem?.sapNickname}-Transactions`;
    toastMessage("Exporting data");
    exportExcel(tempData, transactionHeaders, filename, pageHeaders);
  }
  const copyToClipboard = async () => {
    await toastMessage("Processing data");
    exportClipboard(filteredData, transactionHeaders);
    await toastMessage("Data copied to the clipboard");
  }
  return (
    <>
      <div className="flex items-center gap-4 mt-4">
        <div>
          <Search
            searchType={"secondary"}
            defaultValue={search}
            onChange={(value: string) => {
              setSearch(value);
              setSearchParams(updateParams("search", value, searchParams));
            }}
            className="list-filter"
          />
        </div>
        <div>
          <Select
            placeholder="Transaction type"
            data={Array.from(new Set(transactionList.map(m => m.type))).map((m) => ({ label: TransactionType[m as keyof typeof TransactionType], value: m }))}
            multiple={true}
            onSelect={(value: SelectValue) => {
              setTransactionTypeFilter(value);
              setSearchParams(updateParams("type", value, searchParams));
            }}
            className="list-filter"
          />
        </div>
        <div>
          <Button kind="secondary">
            Clear
          </Button>
        </div>
        <div className="grow"></div>
        <div>
          <Button icon="icon-copy-outline" onClick={() => copyToClipboard()}>Copy</Button>
        </div>
        <div>
          <Button icon="icon-table-data-outline" onClick={() => fileExport()}>Export</Button>
        </div>
      </div>
      <ActiveFilters rows={filteredData.length || 0} />
      <Floater>
        <div
          className="ap-list-header ap-business-process-header flex gap-4 p-4 pt-6 pb-6 items-center w-full">
          <div className="basis-12">
          </div>
          <div className="basis-96">
            Transaction
          </div>
          <div className="grow">
            Transaction type
          </div>
        </div>
      </Floater>
      <div className="list-transaction">
        {
          paginate(filteredData, getCurrentPage(), getOffset())
            .map((transaction, index) => (
              <div
                key={transaction.transactionId}
                id={transaction.identifier}
                className="list-row"
                style={{ scrollMarginTop: 64 }}
              >
                <TransactionItem transaction={transaction} open={transactionId === transaction.transactionId} onClick={() => navigate(transactionId === transaction.transactionId ? `/analysis/${analysisId}/transactions${location.search}` : `/analysis/${analysisId}/transactions/${transaction.transactionId}${location.search}#${transaction.identifier}`)} />
                {(transactionId === transaction.transactionId) && (
                  <TransactionDetails
                    transaction={transaction.identifier}
                    data={data}
                  />
                )}
              </div>
            ))}
      </div>
      <Paginate
        getTotalPages={getTotalPages()}
      />
    </>
  );
};

export const TransactionItem: FC<{ transaction: MatchTransactionData, open: boolean, onClick: () => void }> = ({ transaction, open, onClick }) => (
  <div className="flex items-center p-4 gap-4">
    <div className="basis-12">
      {
        transaction.hasMatches && <span
          className={
            open
              ? "Appkit4-icon icon-down-chevron-outline pointer"
              : "Appkit4-icon icon-right-chevron-outline pointer"
          }
          onClick={onClick}
        />
      }
    </div>
    <div className="basis-96">
      <b>{transaction.identifier}</b>
      <p>{transaction.description}</p>
    </div>
    <div className="grow">
      {transaction.type === "T"
        ? <ToolTip content="Transaction"><span className="Appkit4-icon icon-convert-outline"></span></ToolTip>
        : transaction.type === "R"
          ? <ToolTip content="Function module"><span className="Appkit4-icon icon-circuit-board-box-outline"></span></ToolTip>
          : <ToolTip content="Special transaction"><span className="Appkit4-icon icon-star-outline"></span></ToolTip>
      }
    </div>
  </div>
)

export default TransactionList;

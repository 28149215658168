import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteData, getData, postData, putData } from "services/api-actions";
import { Test } from "types/ruleset";

export const FetchTests = () => useQuery({
  queryKey: ["tests"],
  queryFn: () => getTests(),
  staleTime: 1000 * 60 * 5
});
export const PostTest = () => useMutation({
  mutationFn: (data?: Test) => postTest(data),
  mutationKey: ["tests"],
});
export const PutTest = () => useMutation({
  mutationFn: (data?: Test) => putTest(data),
  mutationKey: ["tests"],
});
export const DeleteTest = () => useMutation({
  mutationFn: (data?: Test) => deleteTest(data),
  mutationKey: ["tests"],
});

const getTests = async () => {
  const data = await getData<Test[]>(`ruleset/test`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
const postTest = async (data?: Test) => {
  if (!data) throw new Error("No data provided");
  var functionRelations = data.functionRelations;
  delete data.functionRelations;
  const response = await postData(`ruleset/test`, { test: data, testRelations: functionRelations });
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as Test;
}

const putTest = async (data?: Test) => {
  if (!data) throw new Error("No data provided");
  var functionRelations = data.functionRelations;
  delete data.functionRelations;
  const response = await putData(`ruleset/test`, { test: data, testRelations: functionRelations });
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as Test;
}

const deleteTest = async (data?: Test) => {
  if (!data) throw new Error("No data provided");
  const response = await deleteData(`ruleset/test/${data.testId}`);
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as Test;
}
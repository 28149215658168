import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, Tab } from "@appkit4/react-components/tabs";

import AnalysisTemplate from "components/layout/analysisTemplate";
import RoleList from "./roleList";
import ProfileList from "./profileList";
import { AnalysisNavigator } from "components/common/helpers";
import { Breadcrumb, BreadcrumbItem } from "@appkit4/react-components";

const Roles: FC<{ index: number }> = ({ index }) => {
  const navigate = useNavigate();
  const { analysisId } = useParams();
  const showPage = (index: number) => {
    switch (index) {
      case 0:
        return <RoleList />;
      case 1:
        return <ProfileList />;
    }
  }
  return (
    <AnalysisTemplate>
      <Breadcrumb>
        <BreadcrumbItem>Analysis</BreadcrumbItem>
        <BreadcrumbItem>Roles</BreadcrumbItem>
      </Breadcrumb>
      <AnalysisNavigator/>
      <h1>By roles/profiles</h1>
      <Tabs type="underline" activeIndex={index || 0} onTabChange={(i) => {
        switch (i) {
          case 0:
            navigate(`/analysis/${analysisId}/roles`);
            break;
          case 1:
            navigate(`/analysis/${analysisId}/profiles`);
            break;
        }
      }}>
        <Tab label="Roles">
        </Tab>
        <Tab label="Profiles">
        </Tab>
      </Tabs>
      {showPage(index)}
    </AnalysisTemplate>
  );
};

export default Roles;

import { useQuery, useMutation } from "@tanstack/react-query";
import { getData, postData, putData, deleteData } from "services/api-actions";
import { SystemUser } from "types/analysis";

export const FetchClientUsers = (clientId?: string) => useQuery({
  queryKey: ["clientUsers", clientId],
  queryFn: () => getClientUsers(clientId),
  staleTime: 1000 * 60 * 5
});

export const PostClientUser = (clientId?: string) => useMutation({
  mutationFn: (user: SystemUser) => postClientUser(user, clientId),
  mutationKey: ["clientUsers", clientId]
});

export const PutClientUser = (clientId?: string) => useMutation({
  mutationFn: (user: SystemUser) => putClientUser(user, clientId),
  mutationKey: ["clientUsers", clientId]
});

export const DeleteClientUser = (clientId?: string) => useMutation({
  mutationFn: (user: SystemUser) => deleteClientUser(user),
  mutationKey: ["clientUsers", clientId]
});

const getClientUsers = async (clientId?: string) => {
  const data = await getData<SystemUser[]>(`client/users/${clientId || "all"}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const postClientUser = async (user?: SystemUser, clientId?: string): Promise<SystemUser> => {
  if (!user) throw new Error("No user provided");
  var payload = { 
    user: user,
    clientId: clientId
  };
  const data = await postData(`client/user`, payload);
  if (!data.result) throw new Error("Unexpected error occurred");
  return data.result;
}

const putClientUser = async (user?: SystemUser, clientId?: string): Promise<SystemUser> => {
  if (!user) throw new Error("No user provided");
  var payload = { 
    user: user,
    clientId: clientId
  };
  const data = await putData(`client/user`, payload);
  if (!data.result) throw new Error("Unexpected error occurred");
  return data.result;
}

const deleteClientUser = async (user: SystemUser): Promise<boolean> => {
  if (!user) throw new Error("No user provided");
  const data = await deleteData<boolean>(`client/user/${user.userId}`);
  if (!data.result) throw new Error("Unable to delete user");
  return data.result;
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import MainTemplate from 'components/layout/mainTemplate';
import 'index.scss';
import reportWebVitals from 'reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <MainTemplate />
    </HelmetProvider>
  </React.StrictMode>
);
reportWebVitals();

import Loader from "components/common/loader";
import UserListWrapper from "components/common/userListWrapper";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ListType } from "types/analysis";
import { useAtomValue } from "jotai";
import { filterAtom } from "jotai/store";
import { FetchUserList } from "queries/hooks/analysis/user";
import { FetchAnalysisData } from "queries/hooks/analysis/analysis";


const UsersInline: FC<{ type: ListType, typeId: string }> = ({ typeId, type }) => {
  const { analysisId } = useParams();

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending, error } = FetchUserList(analysisId, type, typeId, userFilter);
  const {data: analysisData} = FetchAnalysisData(analysisId);

  return(
    isPending || !data || !analysisData
      ? <Loader inline loadingType="circular"/>
      : <UserListWrapper analysisData={analysisData} userList={data} showMatch={false} inline/>
    )
}

export default UsersInline;
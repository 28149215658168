import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteData, getData, postData, putData } from "services/api-actions";
import { RuleFunction, RuleTransaction } from "types/ruleset";

export const FetchFunctions = () => useQuery({
  queryKey: ["functions"],
  queryFn: () => getFunctions(),
  staleTime: 1000 * 60 * 5
});
export const FetchTransactions = (functionId?: string) => useQuery({
  queryKey: ["transactions", functionId],
  queryFn: () => getTransactions(functionId),
  staleTime: 1000 * 60 * 5
});
export const PostFunction = () => useMutation({
  mutationFn: (data?: RuleFunction) => postFunction(data),
  mutationKey: ["functions"],
});
export const PutFunction = () => useMutation({
  mutationFn: (data?: RuleFunction) => putFunction(data),
  mutationKey: ["functions"],
});
export const DeleteFunction = () => useMutation({
  mutationFn: (data?: RuleFunction) => deleteFunction(data),
  mutationKey: ["functions"],
});

const getFunctions = async () => {
  const data = await getData<RuleFunction[]>(`ruleset/function`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
const postFunction = async (data?: RuleFunction) => {
  if (!data) throw new Error("No data provided");
  data.functionId = crypto.randomUUID();
  let transactions = data.transactions?.map(m => ({...m, functionId: data.functionId}));
  let objects = transactions?.flatMap(f => f.objects);
  delete data.transactions;
  transactions?.map(m => delete m.objects);
  const response = await postData(`ruleset/function`, { function: data, transactions: transactions, objects: objects });
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as RuleFunction;
}
const putFunction = async (data?: RuleFunction) => {
  if (!data) throw new Error("No data provided");
  let transactions = data.transactions;
  let objects = transactions?.flatMap(f => f.objects);
  delete data.transactions;
  transactions?.map(m => delete m.objects);
  const response = await putData(`ruleset/function`, { function: data, transactions: transactions, objects: objects });
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as RuleFunction;
}
const deleteFunction = async (data?: RuleFunction) => {
  if (!data) throw new Error("No data provided");
  const response = await deleteData(`ruleset/function/${data.functionId}`);
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as RuleFunction;
}
const getTransactions = async (functionId?: string) => {
  if (!functionId) return [];
  const data = await getData<RuleTransaction[]>(`ruleset/function/${functionId}/transactions`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

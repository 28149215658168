import { toaster, Notification, Tooltip, Search, Tabs, Tab, Table, Column, Modal, Button, Select, Panel } from "@appkit4/react-components";
import UserMatches from "components/routes/analysis/user/userMatches";
import UsersInline from "components/routes/analysis/users/usersInline";
import { CSSProperties, FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useParams } from "react-router-dom";
import { getDate, searchFilter, selectFilter } from "services/common";
import { AnalysisStatus, ListType, LogEntry, Profile, Role, RoleListData, RuleTest, Trend } from "types/analysis";
import { TestData } from "types/user";
import Loader from "./loader";
import PercentageBar from "./percentageBar";
import ToolTip from "./tooltip";
import { UserCount } from "./userStatistics";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import { FetchAnalysisData, FetchAnalysisList, FetchTestDetails } from "queries/hooks/analysis/analysis";
import { FetchAnalysisLog } from "queries/hooks/administration/analysis";
import { Props as LegendProps } from "recharts/types/component/Legend";
import { ActiveFilters } from "components/layout/filters";

function delay(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}
export const scrollToAnchor = (hash: string) => {
  let hashElement = hash
    ? document.getElementById(hash)
    : null;
  if (hashElement !== null) {
    let position = hashElement.getBoundingClientRect().top - 56;
    window.scrollTo({
      top: position,
      behavior: "smooth"
    });
  }
}

export const getRechartsLegend = (props: LegendProps) => {
  const { payload } = props;
  if (!payload) return null;
  return (
    <ul className="ap-legend">
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className="ap-legend-item">
          <span className="ap-legend-icon" style={{ backgroundColor: entry.color }} />
          <span className="ap-legend-label">{entry.value}</span>
        </li>
      ))}
    </ul>
  );
}
export const toastMessage = async (data: string | ReactNode, hyperlink?: ReactNode, status?: "warning" | "error" | string, duration: number = 3000, position: string = "bottomRight") => {
  await delay(100);
  const item = (<Notification
    id={new Date().toString()}
    message={typeof data === "string" ? <div>{data}</div> : data}
    status={status
      ? status
      : "icon-circle-warning-outline"
    }
    hyperLink={hyperlink}
    closeable={duration === 0 ? true : false}
  />)
  toaster.notify(item, { position: position, duration: duration })
}

export const Floater: FC<{ children: React.ReactNode, offset?: number }> = ({ children, offset = 0 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [float, setFloat] = useState(false);
  const [width, setWidth] = useState<number>(document.getElementById("dataview")?.getBoundingClientRect().width || 1420);
  useEffect(() => {
    const handleScroll = () => {
      let floatLimit = ref.current ? ref.current.getBoundingClientRect().y : offset;
      if (floatLimit <= offset && !float)
        setFloat(true);
      else if (floatLimit > offset && float)
        setFloat(false);
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [float, offset, width]);
  return (
    <div ref={ref}>
      {children}
      {float && <div style={{ width: width - 32, top: offset, zIndex: offset > 0 ? 999 : 1000 }} className="ap-floater">
        {children}
      </div>}
    </div>
  )
}

export const FormError: FC<{ error: string | null | undefined }> = ({ error }) => {
  return error
    ? (
    <div className="ap-error flex items-center p-4 gap-4 mt-6 mb-6">
      <div className="p-3">
        <span className="Appkit4-icon icon-error-fill ap-font-40"></span>
      </div>
      <div className="grow">
        <p className="ap-font-medium">Error</p>
        <p>{error}</p>
      </div>
    </div>)
    : null;
}
export const FormWarning: FC<{ message: string }> = ({ message }) => {
  return (
    <div className="ap-warning items-center gap-4 p-4 mb-6">
      <div>
        <span className="Appkit4-icon icon-warning-fill ap-font-40"></span>
      </div>
      <div className="grow">
        <p className="ap-font-medium">Warning</p>
        <p>{message}</p>
      </div>
    </div>)
}
export const TrendIndicator: FC<{ trend: number, value?: string | (() => React.ReactNode) }> = ({ trend, value }) => (
  <Tooltip
    trigger="hover"
    position="bottom"
    distance={4}
    appendAfterTarget={true}
    content={value ? value : trend === Trend.UP ? "The number of risks has increased" : trend === Trend.DOWN ? "The number of risks has decreased" : "The number of risks has not changed"}
  >
    {
      trend === Trend.UP
        ? <span className="Appkit4-icon icon-uptrend-fill ap-font-12 ap-trend-up" aria-hidden="true"></span>
        : trend === Trend.DOWN
          ? <span className="Appkit4-icon icon-downtrend-fill ap-font-12 ap-trend-down" aria-hidden="true"></span>
          : <span className="Appkit4-icon icon-minus-outline ap-font-12" aria-hidden="true"></span>
    }
  </Tooltip>
)
export const CompositeIcon: FC<{ isComposite?: boolean, type: "role" | "profile" }> = ({ isComposite, type }) => (
  <Tooltip
    trigger="hover"
    position="bottom"
    distance={4}
    appendAfterTarget={true}
    content={isComposite ? `composite ${type}` : `${type}`}
    style={{ textTransform: "capitalize" }}
  >
    {
      type === "role"
        ? isComposite
          ? <span className="Appkit4-icon icon-venn-ab-horizontal-outline" />
          : <span className="Appkit4-icon icon-circle-empty-outline" />
        : isComposite
          ? <span className="Appkit4-icon icon-group-outline" />
          : <span className="Appkit4-icon icon-placeholder-outline ap-font-16" />
    }
  </Tooltip>
)
export const TestIcon: FC<{ type: "SA" | "SoD" }> = ({ type }) => (
  <div style={{ textAlign: "center", width: 36, display: "inline-block" }}>
    {
      type === "SoD"
        ? <p>SoD</p>
        : <p>SA</p>
    }
  </div>
)

export const Chevron: FC<{ open: Boolean, onClick?: () => void }> = ({ open, onClick }) => {
  return (<span
    className={open ? "Appkit4-icon icon-down-chevron-outline pointer" : "Appkit4-icon icon-right-chevron-outline pointer"}
    onClick={() => onClick && onClick()}
  />)
}

export const AnchorCatcher: FC = () => {
  let location = useLocation();
  let hashElement = useMemo(() => {
    let hash = location.hash.slice(1);
    return hash
      ? document.getElementById(hash)
      : null
  }, [location]);
  useEffect(() => {

    if (hashElement) {
      var headerOffset = 56;
      var elementPosition = hashElement.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.scrollY - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  });
  return null;
}

export const RoleItem: FC<{ role: Role, open?: Boolean, onClick?: () => void }> = ({ role, open, onClick }) => {
  return (
    <div
      className={`flex items-center p-4 gap-4 ${open ? "ap-accordion-open" : ""}`}
    >
      <div className="basis-12">
        {
          (role.hasMatches) && open !== undefined && <Chevron open={open} onClick={onClick} />
        }
      </div>
      <div className="basis-80">
        <b>{role.name}</b>
        <p>{role.description}</p>
      </div>
      <div className="basis-32">
        <CompositeIcon isComposite={role.isComposite} type="role" />
      </div>
      <div className="basis-28">
        {role.hasMatches
          ? <Tooltip
            trigger="hover"
            position="bottom"
            distance={8}
            id="tooltip-matches"
            content="Role has matches"
          >
            <span className="Appkit4-icon icon-alert-fill access-risk"></span>
          </Tooltip>
          : ""
        }
      </div>
      {(role.validFrom && role.validTo) && (
        <div className="basis-96">
          <p>{getDate(role.validFrom)} - {getDate(role.validTo)}</p>
        </div>
      )}
      {
        role.userCount && role.userTotal
          ? <div className="basis-52"><PercentageBar percentage={Math.ceil((role.userCount / role.userTotal) * 100)} dark /></div>
          : null
      }
      {
        role.userCount
          ? <div><ToolTip content="Number of users with the role (may contain duplicates)." ><span className="Appkit4-icon icon-audience-outline"></span><UserCount>{role.userCount.toLocaleString()}</UserCount></ToolTip></div>
          : null
      }
    </div>
  )
}

export const ProfileItem: FC<{ profile: Profile, open: Boolean, onClick?: () => void }> = ({ profile, open, onClick }) => (
  <div
    className={`${open ? "ap-accordion-open" : ""} flex items-center p-4 gap-4`}
  >
    <div className="basis-12">
      {
        open !== undefined && <Chevron open={open} onClick={onClick} />
      }
    </div>
    <div className="basis-80">
      {profile.name}
    </div>
    <div className="basis-32">
      <CompositeIcon isComposite={profile.isComposite} type="profile" />
    </div>
    <div className="basis-28">
      {profile.hasMatches &&
        <Tooltip
          trigger="hover"
          position="right"
          distance={8}
          id="tooltip-matches"
          content="Profile has matches"
        >
          <span className="Appkit4-icon icon-alert-fill access-risk"></span>
        </Tooltip>
      }
    </div>
    <div className="grow">
      {
        profile.userCount
          ? <ToolTip content="Number of users with the role (may contain duplicates)." ><span className="Appkit4-icon icon-audience-outline"></span><UserCount>{profile.userCount.toLocaleString()}</UserCount></ToolTip>
          : null
      }
    </div>
  </div>
)

export const TestInfo: FC<{ test: TestData, open: boolean, onClick?: () => void }> = ({ test, open, onClick }) => (
  <div
    key={test.testId}
    className="ap-accordion-open flex items-center p-4 gap-4"
  >
    <div className="basis-12">
      <span
        onClick={onClick}
        className={open ? "Appkit4-icon icon-down-chevron-outline pointer" : "Appkit4-icon icon-right-chevron-outline pointer"}
      />
    </div>
    <div className="basis-96">
      <b>{test.identifier}</b>
      <p>{test.functions?.map(m => <span className="ap-function-description" key={test.testId}>{m.description}</span>)}</p>
    </div>
    <div className="basis-20"><TestIcon type={test.type || "SA"} /></div>
    <div className="grow">
      {test.functions?.map(f => (
        <div key={`${test.identifier}-${f.identifier}`}>
          <b>{f.identifier}</b>
        </div>
      ))}
    </div>
  </div>
)

export const RoleInfo: FC<{ role: Role }> = ({ role }) => (
  <>
    <h1>Role</h1>
    <div
      className="flex items-center p-4 gap-4"
      key={role.roleId}
    >
      <div className="basis-96">
        <b>{role.name}</b>
        <p>{role.description}</p>
      </div>
      <div className="basis-20">
        <CompositeIcon isComposite={role.isComposite} type="role" />
      </div>
      <div className="grow">
        {role.profiles?.map(profile => (
          <p key={profile.profileId}>{profile.name}</p>
        ))}
      </div>
    </div>
  </>
)

export const AnalysisNavigator: FC = () => {
  const location = useLocation();
  const { analysisId } = useParams();

  const { data: analysisData } = FetchAnalysisData(analysisId);

  const { isPending, data, error } = FetchAnalysisList(analysisId);

  const getActiveIndex = data ? data.findIndex(w => w.analysisId === analysisId) : 0;
  return !isPending && data
    ? (
      <div className="pb-4">
        <div className="ap-analysis-nav">
          <h2>{analysisData?.client?.name}</h2>
          <div className="flex gap-4">
            <div className="basis-1/4">
              <Select
                data={data.map(m => ({ value: m.analysisId, label: getDate(m.extractionDate || "") }))}
                placeholder="Extraction date"
                value={analysisId}
                dropdownRenderMode="portal"
                onSelect={(value) => {
                  if (!analysisId) return;
                  window.location.reload();
                  window.location.assign(location.pathname.replace(analysisId, value.toString()));
                }} />
            </div>
            <div className="grow">
              <p className="ap-font-medium">SAP system</p>
              {data[getActiveIndex]?.sapSystem && <p>{data[getActiveIndex].sapSystem?.sapSystemName || ""} <span className="Appkit4-icon icon-arrow-right-small-outline" /> {data[getActiveIndex].sapSystem?.sapNickname || ""} <span className="Appkit4-icon icon-arrow-right-small-outline" /> {data[getActiveIndex].sapSystem?.sapClient || ""}</p>}
            </div>
            <div className="basis-1/4">
              <p className="ap-font-medium">Rule set</p>
              {data[getActiveIndex]?.bag && <p>{data[getActiveIndex].bag?.name}</p>}
            </div>
            <div className="basis-1/4">
              <p className="ap-font-medium">Status</p>
              {data[getActiveIndex]?.status && <p>{AnalysisStatus[data[getActiveIndex]?.status || 2] as string}</p>}
            </div>
          </div>
        </div>
      </div>
    )
    : <Loader inline loadingType="circular" error={error?.message} />
}

export const ValidationError: FC<{ error: string }> = ({ error }) => (
  <div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">{error}</div>
)

export const CookieCheck: FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [approval, setApproval] = useState(cookies['_cookies'] || null)

  const acceptAll = () => {
    setCookie("_cookies", Date.now(), {
      maxAge: 60 * 60 * 25 * 180,
      secure: true,
      sameSite: "strict"
    });
    setApproval("close");
  }
  const rejectAll = () => {

  }
  return !approval
    ? (
      <div className="ap-cookie-bar">
        <div className="flex items-center gap-4 p-4">
          <div className="grow">
            <h3>Cookies: The choice is yours</h3>
            <p>
              We use cookies to make our site work well for you and so we can continually improve it.
              The cookies that keep the site functioning are always on.
              We use analytics cookies to help us understand what content is of most interest and to personalize your experience.
              <a href="/cookies" aria-label="More information the cookies used" >Cookie information</a>
            </p>
          </div>
          <div>
            <Button kind="negative" onClick={rejectAll}>Reject all cookies</Button>
          </div>
          <div>
            <Button kind="primary" onClick={acceptAll}>Accept all cookies</Button>
          </div>
        </div>
      </div>
    )
    : null
}

export const MatchesAndUsers: FC<{ profile?: Profile, role?: Role, roleData: RoleListData }> = ({ profile, role, roleData }) => {
  const [matchIndex, setMatchIndex] = useState(0);
  const onTabChange = (i: number) => {
    setMatchIndex(i);
  }
  const getType = () => {
    if (profile) return profile?.isComposite ? ListType.CompositeProfile : ListType.Profile;
    if (role) return role?.isComposite ? ListType.CompositeRole : ListType.Role;
    return ListType.All;
  }

  const getMatchingData = (role: Role) => {
    return { roles: (role && roleData.roles.filter(f => f.roleId === role.roleId)) || [], compositeRoles: [], tests: [], compositeProfiles: [], profiles: [] }
  }
  const getView = (index: number) => {
    switch (index) {
      case 0:
        return <UserMatches
          type={getType()}
          typeId={profile?.profileId || role?.roleId || ""}
          matchingData={role && getMatchingData(role)} />
      case 1:
        return <UsersInline type={getType()} typeId={profile?.profileId || role?.roleId || ""} />
    }
  }
  return <div className="ap-wrapper-matches ap-accordion-open">
    <Tabs type="underline" activeIndex={matchIndex} style={{ padding: 8 }} onTabChange={onTabChange}>
      <Tab label="Matches" value="matches"></Tab>
      <Tab label="Users" value="users"></Tab>
      <Tab label="Test details" value="testdetails"></Tab>
    </Tabs>
    <div className="ap-wrapper-inline">
      {getView(matchIndex)}
    </div>
  </div>;
}

export const TechnicalRoles: FC<{ roles: Role[] }> = ({ roles }) => {
  const [search, setSearch] = useState("");
  const getRoles = () => {
    return roles.filter(f => searchFilter(f, ["description", "name"], search)).flatMap(role => role.profiles?.map(profile => ({
      name: role.name,
      description: role.description,
      profile: profile.name,
      hasMatches: profile.hasMatches
    })));
  }
  return (
    <Panel>
      <div className="flex items-center gap-4 mt-4">
        <div>
          <Search
            searchType={"secondary"}
            value={search}
            onChange={(value: string) => {
              setSearch(value);
            }}
            className="list-filter"
          />
        </div>
      </div>
      <ActiveFilters rows={getRoles().length} />
      <Table
        hasTitle
        condensed
        striped
        originalData={getRoles()}>
        <Column field="name" sortKey="name">Role name</Column>
        <Column field="description" sortKey="description">Role description</Column>
        <Column field="profile" sortKey="profile">Profile</Column>
        <Column field="hasMatches" sortKey="hasMatches" renderCell={
          (row: { name: string, description: string, profile: string, hasMatches: boolean }, field: string) => {
            return row.hasMatches && <span className="Appkit4-icon icon-alert-fill access-risk"></span>
          }}>Access risks</Column>
      </Table>
    </Panel>
  )
}

export const LogViewer: FC<{ analysisId: string }> = ({ analysisId }) => {
  const { clientId } = useParams();
  const parseLog = (log: LogEntry[]) => {
    return log?.map((item, index, array) => {
      let timeElapsed = index === array.length - 1 ? null : (((new Date(array[index + 1].timestamp)).getTime() - new Date(item.timestamp).getTime()) / 1000);
      return ({
        ...item,
        timestamp: (new Date(item.timestamp)).toLocaleString(),
        timeElapsed: `${timeElapsed?.toFixed(2) || 0} seconds`
      })
    });
  }

  const { data: logData } = FetchAnalysisLog(analysisId, clientId);

  return logData
    ? (
      <Table
        style={{ padding: 0 }}
        originalData={parseLog(logData)}
        condensed
        hasTitle
        striped
      >
        <Column field="timestamp" sortKey="timeStamp">Timestamp</Column>
        <Column field="timeElapsed" sortKey="timeElapsed">Processing time</Column>
        <Column field="type">Type</Column>
        <Column field="message">Message</Column>
      </Table>
    )
    : <Loader loadingType="circular" />;
}
export interface ModalProps {
  visible: boolean,
  children: ReactNode,
  title?: string,
  cancel?: () => void,
  confirm?: () => void
  modalStyle?: CSSProperties
}

export const ConfirmationModal: FC<ModalProps> = ({ children, visible, title, cancel, confirm, modalStyle }) => {
  return (
    <Modal
      visible={visible}
      title={title || "Confirm"}
      ariaLabel={title || "Confirm"}
      onCancel={cancel}
      modalStyle={modalStyle || { width: '33.75rem' }}
      footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
      header={""}
      icons={""}
      footer={
        <>
          <Button onClick={cancel} kind="secondary">Cancel</Button>
          <Button onClick={confirm} kind="negative">Confirm</Button>
        </>
      }
    >
      {children}
    </Modal>
  );
}

export const EditModal: FC<ModalProps> = ({ children, title, cancel, visible, modalStyle }) => {
  return (
    <Modal
      visible={visible}
      // title={title || "Add new"}
      ariaLabel={title || "Add new"}
      onCancel={cancel}
      modalStyle={modalStyle || { width: '33.75rem' }}
      header={""}
      icons={""}
      id="modal-user"
    >
      {children}
    </Modal>
  )
}

export const TestDetails: FC<{ test: TestData }> = ({ test }) => {
  type TestRow = {
    function: string;
    description: string;
    transaction: string;
    transactionDescription: string
  };
  const [rowData] = useState<TestRow[]>([]);
  const [search, setSearch] = useState("");
  const [selectedFunction, setSelectedFunction] = useState<SelectValue>([]);

  const parseData = useCallback((testData?: RuleTest): TestRow[] => {
    if (!testData) return [];
    const result: TestRow[] = [];
    for (let f of testData?.functions || [])
      for (let t of f.transactions || [])
        result.push({ function: f.identifier, description: f.description, transaction: t.identifier, transactionDescription: t.description });
    return result;
  }, []);

  const { data, isPending } = FetchTestDetails(test.testId);

  return (isPending
    ? <Loader loadingType="circular" inline />
    : (
      <Panel>
        <h3>{data?.identifier}</h3>
        <p>{data?.functions?.map(m => <span className="ap-function-description">{m.description}</span>)}</p>
        <div className="flex items-center p-4 gap-4"
        >
          <div>
            <Search
              searchType={"secondary"}
              value={search}
              onChange={(value: string) => {
                setSearch(value);
              }}
              className="list-filter"
            />
          </div>
          <div>
            <Select
              placeholder="Function"
              data={Array.from(new Set(rowData.map(m => m.function))).map(m => ({ value: m, label: m }))
              }
              multiple={true}
              value={selectedFunction}
              suffixTemplate={item => {
                return (item.descValue > 0 && <span>{item.descValue} items</span>)
              }}
              onSelect={(v) => {
                setSelectedFunction(v);
              }}
              className="list-filter"
            />
          </div>
        </div>
        <Table
          originalData={
            parseData(data)
              .filter(f => searchFilter(f, ["function", "description", "transaction", "transactionDescription"], search))
              .filter(f => selectFilter(f, "function", selectedFunction))}
          striped
          condensed
          hasTitle
        >
          <Column field="function" sortKey="function" renderCell={(row: TestRow) => (
            <>
              <b>{row.function}</b>
              <p>{row.description}</p>
            </>
          )}>Function</Column>
          <Column field="transaction" sortKey="description" renderCell={(row: TestRow) => (
            <>
              <b>{row.transaction}</b>
              <p>{row.transactionDescription}</p>
            </>
          )}>Transaction</Column>
        </Table>
      </Panel>
    )
  )
}
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteData, getData, postData } from "services/api-actions";
import { objToParams, parseTestFilter } from "services/common";
import { AnalysisData, DisabledItem, ExcelReport, ReportData, RuleSetData, RuleTest } from "types/analysis";
import { GeneralFilter, ReportRequest } from "types/common";

export const FetchAnalysisData = (analysisId?: string) => useQuery({
  queryKey: ["analysis", analysisId],
  queryFn: () => getAnalysisData(analysisId),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});

export const FetchAnalysisList = (analysisId?: string) => useQuery({
  queryKey: ["analysisList", analysisId],
  queryFn: () => getAnalysisList(analysisId),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});

export const FetchTestDetails = (testId: string) => useQuery({
  queryKey: ["testDetails", testId],
  queryFn: () => getTestDetails(testId),
  staleTime: 1000 * 60 * 5
});

export const FetchReportData = (analysisId?: string) => useQuery({
  queryKey: ["reportData", analysisId],
  queryFn: () => getReportData(analysisId),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});

export const FetchReportList = (analysisId?: string, timer?: boolean) => useQuery({
  queryKey: ["reportList", analysisId],
  queryFn: () => getReportList(analysisId),
  refetchInterval: timer ? 1000 * 5 : undefined,
  staleTime: 1000 * 60 * 5
});

export const FetchRuleset = (analysisId?: string) => useQuery({
  queryKey: ["ruleset", analysisId],
  queryFn: () => getRuleSet(analysisId),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});

export const FetchDisabledAuthorisations = (analysisId?: string) => useQuery({
  queryKey: ["disabledAuthorisations", analysisId],
  queryFn: () => getDisabledAuthorisations(analysisId),
  enabled: !!analysisId,
  staleTime: 1000 * 60 * 5
});

export const CreateReport = (analysisId?: string) => useMutation({
  mutationFn: ( report: ReportRequest) => postReport(report),
  mutationKey: ["reportList", analysisId],
});

export const RemoveReport = (analysisId?: string) => useMutation({
  mutationFn: (report: ExcelReport) => deleteReport(report),
  mutationKey: ["reportList", analysisId],
});
const getAnalysisData = async (analysisId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  const data = await getData<AnalysisData>(`analysis/${analysisId}/data`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getAnalysisList = async (analysisId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  const data = await getData<AnalysisData[]>(`analyses/${analysisId}`);
  if (!data.result) throw new Error("No data found");
  return data.result
}

const getTestDetails = async (testId: string) => {
  const data = await getData<RuleTest>(`ruleset/test/${testId}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getReportData = async (analysisId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  const data = await getData<ReportData>(`report/${analysisId}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getReportList = async (analysisId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  const data = await getData<ExcelReport[]>(`report/${analysisId}/list`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getRuleSet = async (analysisId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  const data = await getData<RuleSetData>(`analysis/${analysisId}/ruleset`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const getDisabledAuthorisations = async (analysisId?: string) => {
  if (!analysisId) throw new Error("No analysis id provided");
  const data = await getData<DisabledItem[]>(`analysis/${analysisId}/disabled`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

const postReport = async (report?: ReportRequest) => {
  if(!report) throw new Error("No report provided");
  const data = await postData(`report/${report.analysisId}`, report);
  if(!data.result) throw new Error("Request failed");
  return data.result;
}

const deleteReport = async (report?: ExcelReport) => {
  if(!report) throw new Error("No report provided");
  const data = await deleteData(`report/${report.analysisId}/${report.reportId}`);
  if(!data.result) throw new Error("Request failed");
  return data.result;
}

export const getBasePayload = (filter: GeneralFilter, extra?: object) => {
  return objToParams({
    tests: parseTestFilter(filter.tests) || undefined,
    bukrs: filter.bukrs?.join(",") || undefined,
    werks: filter.werks?.join(",") || undefined,
    vkorg: filter.vkorg?.join(",") || undefined,
    ...extra
  });
}
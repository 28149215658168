enum themeColours {
  BLUE = "#415385",
  ORANGE = "#D04A02",
  TEAL = "#26776D",
  PINK = "#D93954",
  RED = "#E0301E",
  BLACK = "#2D2D2D",
  LIGHTBLUE = "#62719A",
  LIGHTORANGE = "#E45C2B",
  LIGHTTEAL = "#27897E",
  LIGHTPINK = "#E27588",
  LIGHTRED = "#E44F3F",
  GREY = "#D1D1D1"
}
const themeRainbow = [
  "#415385",
  "#D04A02",
  "#26776D",
  "#D93954",
  "#E0301E",
  "#2D2D2D",
  "#62719A",
  "#E45C2B",
  "#27897E",
  "#E27588",
  "#E44F3F",
  "#4D4D4D",
  "#9AA4BE",
  "#FB7C4D",
  "#299D8F",
  "#E998A6",
  "#E96E61"
]
const shadeRainbow = [
    "#FEB791",
    "#FD6412",
    "#D04A02",
    "#933401",
    "#571F01",
    "#FFDCA9",
    "#FFA929",
    "#EB8C00",
    "#AE6800",
    "#714300",
    "#FFECBD",
    "#FFC83D",
    "#FFB600",
    "#C28A00",
    "#855F00",
    "#F7C8C4",
    "#E86153",
    "#E0301E",
    "#AA2417",
    "#741910",
    "#F1BAC3",
    "#E27588",
    "#D93954",
    "#A43E50",
    "#6E2A35"
];
const shadeRainbowText = [
  "#2D2D2D",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#2D2D2D",
  "#2D2D2D",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#2D2D2D",
  "#2D2D2D",
]
export {
  themeColours,
  themeRainbow,
  shadeRainbow,
  shadeRainbowText
}
import { GeneralFilter } from "./common";
import { MatchTransactionData, PaginationData, TestData } from "./user";

// export type AnalysisData = {
//   analysisId?: string;
//   extractionDate?: string;
//   analysisDate?: string;
//   sapSystem?: string;
//   sapName?: string;
//   clientId?: string;
//   clientName?: string;
// }
export type RuleSetData = {
  ruleBag: RuleBag;
  businessProcesses: BusinessProcess[];
  analysisId: string;
}
export type AnalysisData = {
  analysisId?: string;
  clientId?: string;
  systemId?: string;
  analysisDate?: string;
  extractionDate?: string;
  filters?: AnalysisFilter[];
  bag?: RuleBag;
  client?: ClientData;
  sapSystem?: SapSystem;
  log?: LogEntry[];
  status?: AnalysisStatus;
  isDeleted?: boolean;
  publisher?: string;
  bagId?: string;
  file?: File;
}
export type ClientData = {
  clientId: string;
  name: string;
  description: string;
  domains: string;
  analyses: AnalysisData[];
  sapSystems: SapSystem[];
  businessProcesses?: BusinessProcess[];
  systemUsers: string[];
  disabled: boolean;
  actions?: boolean;
}
export enum AnalysisStatus {
  Analyzing = 0,
  Error,
  Complete,
  Published,
  Deleting
}
export enum FilterType {
  TESTS = 0,
  BUKRS = 1,
  WERKS = 2,
  VKORG = 3,
}
export type AnalysisFilter = {
  filterId: string;
  identifier: string;
  type: FilterType;
  name: string;
}
export type SapSystem = {
  systemId?: string,
  clientId?: string,
  sapNickname?: string,
  sapSystemName?: string,
  sapClient?: string
}
export type SystemUser = {
  userId?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  isActive?: boolean;
  userLevel?: number;
  claims?: Claim[];
  clients?: string[];
  clientAccess?: string[];
}
export type Claim = {
  clientId: string;
  systemId?: string;
  type: ClaimType;
}
export enum ClaimType {
  CLIENT = 0,
  SYSTEM = 1,
  RULES = 2,
  FIN = 10,
  HR = 11,
  IT = 12,
  MMI = 13,
  O2C = 14,
  P2P = 15,
  PB = 16,
  SB = 17
}
export type SystemUserData = {
  users: SystemUser[];
  clientId: string | "all";
}
export enum UserLevel {
  "PwCAdmin" = 0,
  "PwCUser",
  "ClientAdmin",
  "Technical",
  "Business",
}
export type LogEntry = {
  timestamp: string,
  type: number,
  message: string
  timeElapsed?: number
}
export type RuleBag = {
  bagId: string;
  analysisId: string;
  name: string;
  description: string;
  clientId: string;
  sets: RuleSet[];
  processes?: BusinessProcess[];
}
export type RuleSet = {
  name: string;
  description: string;
  tests: RuleTest[];
}
export type BaseData = {
  analysisId: string,
  id?: string,
  listType?: ListType,
  filter: GeneralFilter,
  userId?: string,
  testId?: string,
  transactionId?: string,
  roleId?: string,
  compositeRoleId?: string,
  businessProcessId?: string,
  functionId?: string,
  attached?: boolean,
  searchString?: string
  comparisonAnalysisId?: string
}
export type TokenData = {
  uid?: string
}
export type UserListData = {
  test?: RuleTest,
  tests?: RuleTest[],
  transaction?: RuleTransaction,
  role?: Role,
  data: ListUser[],
  pagination: PaginationData
} & BaseData;

export type TransactionData = {
  data: MatchTransactionData[]
} & BaseData;

export type UserTransactionData = {
  userId: string;
} & TransactionData;

export type TransactionFunctionData = {
  analysisId: string,
  transactionId: string,
  data: RuleFunction[]
}
export type BusinessProcessData = {
  data: BusinessProcess[];
  comparisonAnalysisId?: string;
} & BaseData;

export type BusinessProcessTestData = {
  data: RuleTest[]
} & BaseData;

export type RoleListData = {
  roles: Role[]
  pagination: PaginationData
} & BaseData;

export type ProfileListData = {
  profiles: Profile[]
  pagination: PaginationData
} & BaseData;

export type ReportData = {
  tests?: TestData[];
  transactions?: MatchTransactionData[];
  reports?: ExcelReport[];
} & BaseData

export type ExcelReport = {
  reportId?: string;
  analysisId: string;
  timestamp?: string;
  request: ReportDetails;
  status: ReportStatus;
  userId?: string,
  actions?: string;
}
export type ReportDetails = {
  type: ReportType;
  transactions?: string[],
  testId?: string
  name: string;
}
export enum ReportType {
  TestsByUser = 0,
  AuthorisationsByTest,
  AuthorisationsByTransaction
}
export enum ReportStatus {
  InQueue = 0,
  Processing,
  Ready,
  Error
}

export enum ListType {
  Profile = 1,
  CompositeProfile,
  Role,
  CompositeRole,
  Transaction,
  Test,
  All
}

// export type UserListType = "function" | "test" | "transaction" | "profile" | "role" | "compositerole" | "compositeprofile" | "all";

export type AnalysisNav = {
  mainNav?: number,
  subNav?: number
}

// export type Analysis = {
//   analysisId: string;
//   clientId: string;
//   nickname: string;
//   systemId: string;
//   ruleset: string;
//   extractionDate: string;
//   analysisDate: string;
//   status: AnalysisStatus;
//   rulesetId: string;
//   systemName: string;
//   client: string;
//   published?: string;
//   publisher?: string;
// };
export type MatchResult = {
  functionId: string;
  function: string;
  type: string;
  roles?: Role[];
  profiles: Profile[];
};

export type Role = {
  roleId: string;
  name: string;
  description: string;
  isComposite?: boolean;
  userCount?: number;
  userTotal?: number;
  validFrom?: string;
  validTo?: string;
  hasMatches?: boolean;
  roles?: Role[];
  stats?: UserStats;
  profiles?: Profile[]
};
export type Profile = {
  profileId: string;
  name: string;
  isComposite: boolean;
  userCount?: number;
  hasMatches?: boolean;
  profiles?: Profile[];
  authorisations?: Authorisation[];
};
export type Authorisation = {
  name: string;
  objects: MatchObject[];
};
export type MatchObject = {
  name: string;
  field: string;
  testedValues?: string;
  valueFrom: string;
  valueTo: string;
};
export type ListUser = {
  userId: string;
  userName: string;
  type: "A" | "B" | "C" | "S";
  lockStatus: number;
  userGroup: string;
  name: string;
  validity: string;
  hasMatches: boolean;
};
export type ExportUser = {
  userName: string;
  firstName: string;
  lastName: string;
  type: string;
  userGroup: string;
  validFrom: string;
  validTo: string;
  lockStatus: string;
}
export const UserType = {
  A: {
    name: "Dialog user",
    icon: "icon-person-fill",
  },
  B: {
    name: "System user",
    icon: "icon-cogs-fill",
  },
  C: {
    name: "Communication user",
    icon: "icon-telecom-fill",
  },
  S: {
    name: "Service user",
    icon: "icon-globe-map-fill",
  },
};
export enum TransactionType {
  R = "Function module",
  T = "Transaction",
  S = "Special transaction"
}
export type UserStats = {
  userCount: number;
  userDialog?: number;
  userSystem?: number;
  userCommunication?: number;
  userService?: number;
  userTotal: number;
};
export enum Trend {
  SAME = 0,
  UP = 1,
  DOWN = 2
}
export type BusinessProcess = {
  businessProcessId: string;
  name: string;
  abbreviation: string;
  description: string;
  userCount: number;
  userTotal: number;
  tests?: RuleTest[];
  userCountDistinct: number;
  comparisonUserCount?: number;
};

export type RuleTest = {
  testId: string;
  type: "SA" | "SoD";
  identifier: string;
  businessProcessId: string;
  description: string;
  stats?: UserStats;
  comparisonStats?: UserStats;
  functions?: RuleFunction[];
};
export type RuleFunction = {
  functionId: string;
  identifier: string;
  type: string;
  description: string;
  tests?: RuleTest[];
  stats?: UserStats;
  transactions?: RuleTransaction[];
  transactionId?: string;
}
export type RuleTransaction = {
  transactionId?: string;
  functionId: string;
  identifier: string;
  description: string;
  type: string;
  objects?: RuleObject[];
};
export type RuleObject = {
  objectId?: string;
  field: string;
  matchValues: string;
  authObject: string;
}

export enum DisabledItemType {
  Transaction,
  Missing
}

export type DisabledItem = {
  type: DisabledItemType;
  businessProcess?: string;
  test?: string;
  function?: string;
  functionDescription?: string;
  transaction?: string;
  transactionDescription?: string;
}
export type UsersWithSame = {
  userId: string;
  analysisId: string;
  users: ListUser[]
}
export type RoleTestData = {
  analysisId: string;
  composite: boolean;
  roleId?: string;
  tests: TestData[];
}
import { useQuery } from "@tanstack/react-query";
import { getData, postData } from "services/api-actions";

export const FetchToken = (code?: string) => useQuery({
  queryKey: ["token", code],
  queryFn: () => getToken(code),
  enabled: !!code
});
export const RevokeToken = (token?: string) => useQuery({
  queryKey: ["revokeToken"],
  queryFn: () => revokeToken(token),
  enabled: !!token
});

const getToken = async (code?: string) => {
  if (!code) throw new Error("No code provided");
  const data = await getData<{ id_token: string, access_token: string, refresh_token: string, clientId?: string }>(`authentication/token/${code}`);
  if (!data.result) throw new Error(data.message);
  return data.result;
}

const revokeToken = async (token?: string) => {
  if (!token) throw new Error("No token provided");
  const data = await postData<{refreshToken: string}, boolean>(`authentication/revoke`, { refreshToken: token });
  if (!data) throw new Error("Unable to revoke token");
  localStorage.removeItem("access_token");
  return data;
}
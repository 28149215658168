import { useQuery } from "@tanstack/react-query";
import { getData } from "services/api-actions";
import { MatchTransactionData } from "types/user";
import { RuleFunction } from "types/analysis";
import { getBasePayload } from "./analysis";
import { GeneralFilter } from "types/common";

export const FetchTransactions = (analysisId?: string, filter?: GeneralFilter) => useQuery({
  queryKey: ["transactions", analysisId, filter],
  queryFn: () => getTransactions(analysisId, filter),
  staleTime: 1000 * 60 * 5
});

export const FetchTransactionFunctions = (analysisId?: string, transactionId?: string, filter?: GeneralFilter) => useQuery({
  queryKey: ["transactionFunctions", analysisId, transactionId, filter],
  queryFn: () => getTransactionFunctions(analysisId, transactionId, filter),
  staleTime: 1000 * 60 * 5
});

const getTransactions = async (analysisId?: string, filter?: GeneralFilter) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter);
  const data = await getData<MatchTransactionData[]>(`analysis/${analysisId}/transactions?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
const getTransactionFunctions = async (analysisId?: string, transactionId?: string, filter?: GeneralFilter) => {
  if (!analysisId) throw new Error("No analysis id provided");
  if (!transactionId) throw new Error("No transaction id provided");
  if (!filter) throw new Error("No filter provided");
  const payload = getBasePayload(filter);
  const data = await getData<RuleFunction[]>(`analysis/${analysisId}/transaction/${transactionId}?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
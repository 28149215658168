import { useQuery } from "@tanstack/react-query";
import { getData } from "services/api-actions";
import { BusinessProcess, RuleTest } from "types/analysis";
import { getBasePayload } from "./analysis";
import { GeneralFilter } from "types/common";

export const FetchBusinessProcessList = (analysisId?: string, filter?: GeneralFilter, comparisonAnalysisId?: string) => useQuery({
    queryKey: ["businessProcessList", analysisId, filter, comparisonAnalysisId],
    queryFn: () => getBusinessProcesses(analysisId, filter, comparisonAnalysisId),
    enabled: !!analysisId,
    staleTime: 1000 * 60 * 5
});

export const FetchBusinessProcessTests = (analysisId?: string, businessProcessId?: string, filter?: GeneralFilter, comparisonAnalysisId?: string) => useQuery({
    queryKey: ["businessProcessTests", analysisId, businessProcessId, filter, comparisonAnalysisId],
    queryFn: () => getBusinessProcessTests(analysisId, businessProcessId, filter, comparisonAnalysisId),
    enabled: !!analysisId,
    staleTime: 1000 * 60 * 5
});

export const getBusinessProcesses = async (analysisId?: string, filter?: GeneralFilter, comparisonAnalysisId?: string) => {
    if (!analysisId) throw new Error("No analysis id provided");
    if (!filter) throw new Error("No filter provided");
    const payload = getBasePayload(filter, { comparisonAnalysisId: comparisonAnalysisId });
    const data = await getData<BusinessProcess[]>(`analysis/${analysisId}/processes?${payload || ""}`);
    if (!data.result) throw new Error("No data found");
    return data.result;
}

export const getBusinessProcessTests = async (analysisId?: string, businessProcessId?: string, filter?: GeneralFilter, comparisonAnalysisId?: string) => {
    if (!analysisId) throw new Error("No analysis id provided");
    if (!businessProcessId) throw new Error("No business process id provided");
    if (!filter) throw new Error("No filter provided");
    const payload = getBasePayload(filter, { comparisonAnalysisId: comparisonAnalysisId });
    const data = await getData<RuleTest[]>(`analysis/${analysisId}/process/${businessProcessId}?${payload || ""}`);
    if (!data.result) throw new Error("No data found");
    return data.result;
}
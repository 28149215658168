import { FC, useCallback, useState } from "react";
import { ListUser, UserType } from "types/analysis";
import { Column, Table } from "@appkit4/react-components";
import Paginate from "./paginate";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ToolTip from "./tooltip";
import { getDate, lockStatuses, parseLockStatus } from "services/common";

export interface UserListProps {
  data: ListUser[];
  showMatch?: boolean;
  anchor?: string;
  inline?: boolean;
}

const UserList: FC<UserListProps> = ({ anchor, data, showMatch, inline = false }) => {
  const { analysisId, transactionId, testId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(100);
  const [searchParams] = useSearchParams();

  const getCurrentPage = useCallback(() => !inline ? parseInt(searchParams.get("p") || "1") : currentPage, [searchParams, currentPage, inline]);
  const getOffset = useCallback(() => !inline ? parseInt(searchParams.get("o") || "100") : offset, [searchParams, offset, inline]);
  const getTotalPages = useCallback(() => Math.ceil((data.length || getOffset()) / getOffset()), [getOffset, data]);

  const customizeColumn = (row: ListUser, field: string) => {
    switch (field) {
      case "userName":
        let link = getLink(row);
        return link 
          ? <Link to={link}>{row.userName}</Link>
          : <span>{row.userName}</span>;
      case "lockStatus":
        return (
          row[field] > 0 && lockStatuses.includes(row.lockStatus) && (
            <span className="table-cell">
              <ToolTip content={parseLockStatus(row["lockStatus"] || 0) || ""} position="bottom">
                <span className="Appkit4-icon icon-lockclosed-locked-fill"></span>
              </ToolTip>
            </span>
          )
        );
      case "type":
        return (
          <ToolTip content={UserType[row[field]].name} position="bottom">
            <span
              className={`Appkit4-icon ${UserType[row[field]].icon}`}
            ></span>
          </ToolTip>
        );
      case "validity":
        let validity = row.validity.split(" - ");
        return (
          <span>
            {getDate(validity[0], true)} - {getDate(validity[1], true)}
          </span>
        );
      case "hasMatches":
        return (
          row.hasMatches && <span className="Appkit4-icon icon-warning-fill access-risk"></span>
        )
    }
  };
  const getLink = (data: ListUser) => {
    if (transactionId) return `/analysis/${analysisId}/user/${data.userId}/transaction/${transactionId}#${anchor}`;
    else if (testId) return `/analysis/${analysisId}/user/${data.userId}/test/${testId}#${anchor}`;
    else return data.hasMatches && `/analysis/${analysisId}/user/${data.userId}`;
  }

  return (
    <>
      <Table
        originalData={data || []}
        currentPage={getCurrentPage()}
        pageSize={getOffset()}
        hasTitle
        striped
        condensed
      >
        <Column field="userName" sortKey="userName" renderCell={customizeColumn}>
          Username
        </Column>
        <Column field="name" sortKey="name">
          Name
        </Column>
        <Column field="type" sortKey="type" renderCell={customizeColumn}>
          User type
        </Column>
        <Column
          field="lockStatus"
          sortKey="lockStatus"
          renderCell={customizeColumn}
        >
          Locked
        </Column>
        <Column field="userGroup" sortKey="userGroup">
          User Group
        </Column>
        <Column field="validity" sortKey="validity" renderCell={customizeColumn}>
          Validity
        </Column>
      </Table>
      <Paginate
        getTotalPages={getTotalPages()}
        currentPage={inline ? currentPage : undefined}
        pageOffset={inline ? offset : undefined}
        setCurrentPage={inline ? setCurrentPage : undefined}
        setPageOffset={inline ? setOffset : undefined}
      />
    </>
  );
};
export default UserList;

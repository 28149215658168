import { Button } from "@appkit4/react-components";
import PageFooter from "components/layout/pageFooter";
import { FC } from "react";

const Error: FC<{ error: string }>= ({error}) => {
  return (
    <div className="w-full flex h-screen">
      <div className="xl:w-7/12 lg:w-full">
        <div className="login-wrapper">
          <div className="login-logo" />
          <div className="login-body p-8">
            <div className="form-header">
              <h3>An unexpected error occurred</h3>
              <h1>{error}</h1>
              <p>The error has been logged in our system.</p>
              <Button kind="tertiary" onClick={() => console.log("oops")} style={{ marginTop: 8, marginBottom: 8 }}>Try again</Button>
              <p>Or refresh the page</p>
            </div>
          </div>
          <div className="login-footer">
            <PageFooter />
          </div>
        </div>
      </div>
      <div className="xl:grow lg:hide">
        <div className="background-right" />
      </div>
    </div>
  )
}


export default Error;
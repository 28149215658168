import { FC, useEffect } from "react";
import Loader from "components/common/loader";
import { Link, useParams } from "react-router-dom";
import { TestIcon } from "components/common/helpers";
import { RuleFunction } from "types/analysis";
import { isAutoScroll } from "services/common";

const TransactionDetails: FC<{
  data?: RuleFunction[];
  transaction: string;
}> = ({ data, transaction }) => {
  const { analysisId } = useParams();
  useEffect(() => {
    if (isAutoScroll() && transaction) {
      const element = document.getElementById(transaction);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [transaction]);
  return !data ? (
    <Loader inline loadingType="circular" />
  ) : (
    <>
      <div
        className="ap-list-header ap-business-process-test-header flex gap-4 p-4 pt-6 pb-6 items-center"
        id={transaction}
      >
        <div className="basis-12"></div>
        <div className="basis-96">
          Transaction code included in tests
        </div>
        <div className="basis-20">
          Test type
        </div>
        <div className="grow">
          Function including the transaction
        </div>
      </div>
      {data.map((f) => f.tests?.map(test =>
        <div key={`${transaction}-${f.functionId}`} className="list-row-1">
          <div className="ap-accordion-open flex items-center p-4 gap-4">
            <div className="basis-12"></div>
            <div className="basis-96">
              <Link to={`/analysis/${analysisId}/users/test/${test.testId}`} key={`${transaction}:${test.testId}`}>
                <p style={{ height: 60 }}>
                  <b>{test.identifier}</b>
                  <p>{test.functions?.map(m => m.description).join(" AND ")}</p>
                </p>
              </Link>
            </div>
            <div className="basis-20">
              <p style={{ height: 60 }}><TestIcon type={test.type} /></p>
            </div>
            <div className="grow">
              <Link to={`/analysis/${analysisId}/users/transaction/${f.transactionId}`}>
                <b>{f.identifier}</b>
                <p>{f.description}</p>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TransactionDetails;

import { ApiResponse } from "types/common";
import axios, { AxiosProgressEvent } from "axios";

export const STORAGE_KEYS = {
  // Key for id_token
  TOKEN: 'id_token',
  // Key for denied_url
  DENIED_URL: 'denied_url',
};

const API_BASEURL = process.env.REACT_APP_API_URI || "";

const instance = axios.create({
  baseURL: API_BASEURL,
  timeout: 60000,
  withCredentials: true
});

instance.interceptors.request.use((request) => {
  if(request.headers)
    request.headers['Authorization'] = `Bearer ${localStorage.getItem("access_token")}`;
  return request;
});

/**
 * Downloads a file from the specified URL.
 * @param url - The URL of the file to download.
 * @returns A Promise that resolves to the downloaded file data.
 */
export const downloadFile = async (url: string) => {
  try {
    const { data } = await instance.request({
      url: url,
      method: "GET",
      responseType: "blob"
    });
    if(data.statusCode !== 200) data.isError = true;
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
    {
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
    }
  }
  return ({isError: true, statusCode: 500})
}

export const getData = async <T>(endpoint: string): Promise<ApiResponse<T>> =>  {
  try {
    const { data } = await instance.get(endpoint);
    if(data.statusCode !== 200) data.isError = true;
    return data as ApiResponse<T>;
  } catch (error) {
    if(axios.isAxiosError(error))
    {
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
    }
  }
  return ({isError: true, statusCode: 500})
}

export const postData = async <T, U>(endpoint: string, payload: T): Promise<ApiResponse<U>> => {
  try {
    const { data } = await instance.post(endpoint, payload);
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500}) 
}

export const putData = async <T, U>(endpoint: string, payload: T): Promise<ApiResponse<U>> => {
  try {
    const { data } = await instance.put(endpoint, payload);
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500}) 
}

export const deleteData = async <T>(endpoint: string): Promise<ApiResponse<T>> => {
  try {
    const { data } = await instance.delete(endpoint);
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500}) 
}

/**
 * Uploads data to the specified endpoint.
 * 
 * @param endpoint - The endpoint to upload the data to.
 * @param payload - The data to be uploaded.
 * @param progress - An optional callback function to track the upload progress.
 * @returns A promise that resolves to the API response.
 */
export const uploadData = async <FormData, U>(endpoint: string, payload: FormData, progress?: (progress: number) => void): Promise<ApiResponse<U>> => {
  const onUploadProgress = (event: AxiosProgressEvent) => {
    if(event.total) {
      const percentage = Math.round((100 * event.loaded) / event.total);
      if(progress)
        progress(percentage);
    }
  };
  try {
    const { data } = await instance.post(endpoint, payload, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "multipart/form-data"
      }, onUploadProgress});
    return data;
  } catch (error) {
    if(axios.isAxiosError(error))
      return ({isError: true, statusCode: error.response?.status||500 ,...error})
  }
  return ({isError: true, statusCode: 500}) 
}

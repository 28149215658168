import { Badge, Button, Checkbox, Column, Combobox, Drawer, DropdownButton, Input, ItemDataType, Search, Select, Table, Tag, TextArea } from '@appkit4/react-components';
import Loader from 'components/common/loader';
import React, { useCallback, useEffect, useState } from 'react';
import { checkFormValues, searchFilter } from 'services/common';
import { RuleSet, RuleStatus, Test } from 'types/ruleset';
import { Statuses, rulesetTableRender } from './ruleEditor';
import { ClientData } from 'types/analysis';
import ToolTip from 'components/common/tooltip';
import Paginate from 'components/common/paginate';
import { ConfirmationModal, FormError, FormWarning, ValidationError, toastMessage } from 'components/common/helpers';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { FetchClients } from 'queries/hooks/administration/client';
import { DeleteSet, FetchSets, PostSet, PutSet } from 'queries/hooks/ruleset/set';
import { FetchTests } from 'queries/hooks/ruleset/test';

const initSet: RuleSet = {
  setId: "new",
  name: "",
  description: "",
  clientId: "00000000-0000-0000-0000-000000000000",
  versionNumber: 1,
  versionComments: "Initial version",
  versionStatus: RuleStatus.Draft,
  testRelations: []
}

const SetEditor: React.FC = () => {
  const [search, setSearch] = useState("");
  const [clientFilter, setClientFilter] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<number | undefined>(undefined);
  const [editItem, setEditItem] = useState<RuleSet>();
  const [deleteVisible, setDeleteVisible] = useState<RuleSet>();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(25);

  const { setId } = useParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: clientData, isPending: clientPending } = FetchClients();
  const { data: sets, isPending: setsPending } = FetchSets();
  const { data: tests, isPending: testsPending } = FetchTests();
  const { mutate: deleteSet } = DeleteSet();

  const getTotalPages = useCallback(() => Math.ceil((sets?.length || offset) / offset), [sets, offset]);

  const copySet = (set: RuleSet) => {
    let newSet = { ...set, setId: "new", name: "Copy of " + set.name, versionNumber: set.versionNumber++, versionComments: "Copy of " + set.versionComments, versionStatus: RuleStatus.Draft };
    setEditItem(newSet);
  }

  const handleDelete = async () => {
    if (!deleteVisible) return;
    deleteSet(deleteVisible, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["sets"] });
        setDeleteVisible(undefined);
        toastMessage("Set deleted");
      },
      onError: () => {
        toastMessage("Unable to delete set", "error");
      }
    });
  }

  const getData = useCallback(() => {
    return sets?.filter(f => searchFilter(f, ["name", "description"], search))
      .filter(f => !clientFilter || searchFilter(f, ["clientId"], clientFilter))
      .filter(f => statusFilter === undefined || f.versionStatus === statusFilter)
      .map(m => ({ ...m, actions: "" })) || [];
  }, [search, sets, clientFilter, statusFilter]);

  useEffect(() => {
    if (setId) {
      let item = sets?.find(f => f.setId === setId);
      if (item) setEditItem(item);
    }
  }, [setId, sets]);

  return (clientPending || setsPending || testsPending)
    ? <Loader loadingType="circular"></Loader>
    : (
      <>
        <h3 id="list-top">Sets</h3>
        <p>blah</p>
        <div className="flex items-center gap-4 mt-4 mb-4">
          <div className="shrink">
            <Button kind='primary' icon="icon-plus-outline" onClick={() => {
              setEditItem(initSet);
            }}>New Set</Button>
          </div>
          <div>
            <Search
              searchType={"secondary"}
              onChange={(value: string) => {
                setSearch(value);
              }}
              searchValue={search}
              className="list-filter"
            />
          </div>
          <div>
            <Combobox
              placeholder="Filter by client"
              data={clientData?.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" }) || []}
              value={clientFilter}
              onSelect={value => setClientFilter(value.toString())}
            />
          </div>
          <div>
            <Combobox
              placeholder="Status"
              data={Statuses}
              value={statusFilter}
              onSelect={value => setStatusFilter(value as number)}
              onClear={() => setStatusFilter(undefined)}
            />
          </div>
        </div>
        <Table
          originalData={getData()}
          hasTitle
          striped
          currentPage={currentPage}
          pageSize={offset}
        >
          <Column field="name" sortKey="name" renderCell={(row: RuleSet, field: string) => (
            <Link to={`/ruleset/sets/${row.setId}`}>
              <b>{row.name}</b>
              <p>{row.description}</p>
            </Link>
          )}>Name</Column>
          <Column field="clientId" sortKey="clientId" renderCell={(row: RuleSet, field: string) => rulesetTableRender(row, field, clientData || [])}>Client</Column>
          <Column field="versionNumber" sortKey="versionNumber" renderCell={(row: RuleSet, field: string) => rulesetTableRender(row, field, clientData || [])}>Version</Column>
          <Column field="versionStatus" sortKey="versionStatus" renderCell={(row: RuleSet, field: string) => rulesetTableRender(row, field, clientData || [])}>Status</Column>
          <Column field="testRelations" sortKey="testRelations" renderCell={(row: RuleSet, field: string) => (
            <>
              <b>{row.testRelations?.length}</b>
            </>)}
          >Tests</Column>
          <Column field="actions" sortKey="actions" renderCell={(row: RuleSet, field: string) =>
            <DropdownButton
              compact
              kind="tertiary"
              splitButton
              data={[
                { label: "Edit", value: 1 },
                { label: "Make a copy", value: 2 },
                { label: "Delete", value: 3, disabled: row.versionStatus === RuleStatus.Current }
              ]}
              onSelect={(value) => {
                switch (value) {
                  case 1:
                    navigate(`/ruleset/sets/${row.setId}`);
                    break;
                  case 2:
                    copySet(row);
                    break;
                  case 3:
                    row.setId && setDeleteVisible(row);
                    break;
                  default:
                    break;
                }
              }}
              onClick={() => { navigate(`/ruleset/sets/${row.setId}`) }}
            >
              Edit
            </DropdownButton>
          }>Actions</Column>
        </Table>
        <Paginate
          getTotalPages={getTotalPages()}
          currentPage={currentPage}
          pageOffset={offset}
          setCurrentPage={setCurrentPage}
          setPageOffset={setOffset}
        />
        <Drawer
          visible={editItem !== undefined}
          initialFocus={true}
          mask={true}
          resizable={true}
          placement="right"
          title={editItem?.setId === "new" ? "New Set" : editItem?.name || "Edit Set"}
          onClose={() => {
            setEditItem(undefined);
            navigate("/ruleset/sets");
          }}
          style={{ minWidth: 1166 }}
        >
          {editItem && <SetForm clients={clientData || []} item={editItem} close={() => {
            navigate("/ruleset/sets");
            setEditItem(undefined);
          }} tests={tests || []} />}
        </Drawer>
        <ConfirmationModal
          visible={deleteVisible !== undefined}
          title="Delete set"
          cancel={() => setDeleteVisible(undefined)}
          confirm={async () => await handleDelete()}>
          <p>Are you sure you want to delete { deleteVisible?.name || "this set" }?</p>
        </ConfirmationModal>
      </>
    )
}

const SetForm: React.FC<{ clients: ClientData[], item: RuleSet, close: () => void, tests: Test[] }> = ({ clients, item, close, tests }) => {
  const [data, setData] = useState(item);
  const [testList, setTestList] = useState<Test[]>([]);
  const [testSelection, setTestSelection] = useState<string[]>([]);
  const [errors, setErrors] = useState<(keyof RuleSet)[]>();

  const queryClient = useQueryClient();

  const { mutate: addSet, isPending: isAdding } = PostSet();
  const { mutate: updateSet, isPending: isUpdating } = PutSet();

  const handleSubmit = async () => {
    if (!validateForm()) return;
    let tempData = data;
    tempData.testRelations = testList.map(m => ({ setId: undefined, testId: m.testId || "" }));
    if (tempData.setId === "new") {
      tempData.setId = undefined;
      addSet(tempData, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["sets"] });
          close();
        },
        onError: () => setErrors(["setId"])
      });
    } else {
      updateSet(tempData, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["sets"] });
          close();
        },
        onError: () => setErrors(["setId"])
      });
    }
  }

  const validateForm = () => {
    setErrors(undefined);
    let errorList: (keyof RuleSet)[] = [];
    let check = checkFormValues(data, ["clientId", "name", "description", "versionComments", "versionStatus"]);
    let testCheck = testList.length > 0;
    if (Array.isArray(check))
      errorList = check;
    if (!testCheck) errorList.push("testRelations");
    if (errorList.length > 0) {
      setErrors(errorList);
      return false;
    }
    return true;
  };

  const getData = useCallback(() => {
    return testList.map(m => ({ ...m, actions: "" }));
  }, [testList])

  useEffect(() => {
    setErrors(undefined);
    setData(item);
    setTestList(item.testRelations?.map(m => tests.find(f => f.testId === m.testId) as Test) || []);
  }, [item, tests])

  return (
    <div className="ap-input-form">
      {
        (
          item.versionStatus === RuleStatus.Current && <FormWarning message="This bag is currently in use. Any changes that you do may change the results." />
        )
      }
      <FormError error={""} />
      <form autoComplete="off">
        <p className="ap-font-medium">Details</p>
        <div className="flex gap-4 flex-wrap">
          <div className="grow">
            <Input
              title="Name"
              type="text"
              value={data.name}
              onChange={(value: string) => setData({ ...data, name: value })}
              className="ap-field"
              error={errors?.includes("name")}
              errorNode={<ValidationError error="Name is required" />}
              required
            />
          </div>
          <div className="basis-1/3">
            <Select
              placeholder="Status"
              data={Statuses}
              value={data.versionStatus}
              onSelect={value => setData({ ...data, versionStatus: value as RuleStatus })}
              error={errors?.includes("versionStatus")}
              errorNode={<ValidationError error="Status is required" />}
              required
            />
          </div>
          <div className="basis-1/3 break-after-column">
            <Combobox
              placeholder="Client"
              data={clients.map(client => ({ label: client.name, value: client.clientId })).concat({ label: "Default", value: "00000000-0000-0000-0000-000000000000" })}
              value={data.clientId}
              onSelect={value => setData({ ...data, clientId: value.toString() })}
              className="ap-field"
              error={errors?.includes("clientId")}
              errorNode={<ValidationError error="Client is required" />}
              required
            />
          </div>
          <div className="basis-1/2">
            <TextArea
              title="Description"
              value={data.description}
              onChange={(value: string) => setData({ ...data, description: value })}
              className="ap-field"
              error={errors?.includes("description")}
              errorNode={<ValidationError error="Description is required" />}
              required
            />
          </div>
          <div className="grow">
            <TextArea
              title="Version comments"
              value={data.versionComments}
              onChange={(value: string) => setData({ ...data, versionComments: value })}
              className="ap-field"
              error={errors?.includes("versionComments")}
              errorNode={<ValidationError error="Version comments is required" />}
              required
            />
          </div>
        </div>
        <p className="ap-font-medium">Tests</p>
        <Table
          originalData={getData()}
          hasTitle
          striped
        >
          <Column field="identifier" sortKey="identifier" renderCell={(row: Test, field: string) => (
            <>
              <b>{row.identifier}</b>
              <p>{row.description}</p>
            </>
          )

          }>Name</Column>
          <Column field="clientId" sortKey="clientId" renderCell={(row: Test, field: string) => rulesetTableRender(row, field, clients)}>Client</Column>
          <Column field="versionNumber" sortKey="versionNumber" renderCell={(row: Test, field: string) => rulesetTableRender(row, field, clients)}>Version</Column>
          <Column field="versionStatus" sortKey="versionStatus" renderCell={(row: Test, field: string) => rulesetTableRender(row, field, clients)}>Status</Column>
          <Column field="actions" sortKey="actions" renderCell={(row: Test, field: string) =>
            <>
              <ToolTip
                content="Delete set" position="bottom">
                <span
                  className="Appkit4-icon icon-delete-fill pointer"
                  style={{ marginRight: 8 }}
                  onClick={() => setTestList(testList.filter(f => f.testId !== row.testId))}>
                </span>
              </ToolTip>
            </>
          }>Actions</Column>
        </Table>
        <div className="flex gap-4 mt-4 min">
          <div className="basis-1/2">
            <Combobox
              placeholder="Select tests"
              data={
                tests.filter(f => f.clientId === "00000000-0000-0000-0000-000000000000" || f.clientId === data.clientId)
                  .map(m => ({
                    label: m.identifier,
                    value: m.testId,
                    description: m.description,
                    versionStatus: m.versionStatus,
                    versionComments: m.versionComments,
                    client: clients.find(f => f.clientId === m.clientId)?.name || "Default"
                  }))
              }
              value={testSelection}
              onSelect={(value) => Array.isArray(value) ? setTestSelection(value.map(m => m.toString())) : setTestSelection([value.toString()])}
              multiple
              showSelectAll={false}
              itemTemplate={(label: React.ReactNode, item: ItemDataType) => {
                return (
                  <div className="flex gap-4 items-center w-full">
                    <div className="basis-22">
                      <Checkbox checked={testSelection?.includes(item.value as string)} />
                    </div>
                    <div className="basis-1/2">
                      <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{item.label}</b></div>
                      <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.description}</div>
                    </div>
                    <div>
                      <Badge value={item.client} />
                    </div>
                    <div className="basis-1/2">
                      <div style={{ padding: 8, marginRight: 16, display: "block", height: 30 }}><b>{Statuses.find(f => f.value === item.versionStatus)?.label}</b></div>
                      <div style={{ marginLeft: 8, fontSize: 12, display: "block" }}>{item.versionComments}</div>
                    </div>
                  </div>
                )
              }}
              valueTemplate={(value: SelectValue, item: ItemDataType | ItemDataType[]) => {
                return Array.isArray(item)
                  ? item.map(m => <Tag key={m.value}>{m.label}</Tag>)
                  : <Tag>{item.label}</Tag>
              }}
            />
          </div>
          <div>
            <Button kind="tertiary" onClick={() => {
              setTestList([...testList.filter(f => !testSelection.includes(f.testId || "")) || [], ...tests.filter(f => testSelection.includes(f.testId || ""))]);
              setTestSelection([]);
            }}>Add</Button>
          </div>
        </div>
        {errors && errors.includes("testRelations") && <ValidationError error="At least one test is required" />}
      </form>
      {errors?.includes("setId") && <FormError error="Unable to save the set" />}
      <div className="ap-footer flex gap-4">
        <div className="grow">
          <Button onClick={close} kind="secondary">Cancel</Button>
        </div>
        <div>
          <Button onClick={handleSubmit} kind="primary" loading={isAdding || isUpdating}>Save</Button>
        </div>
      </div>
    </div>
  )
}

export default SetEditor;
import { Button, Panel, Search, Select } from "@appkit4/react-components";
import { FC, useEffect, useState } from "react";
import { AnalysisData, ExportUser, ListType, ListUser, UserListData, UserType } from "types/analysis";
import UserList from "components/common/userList";
import { SelectValue } from "@appkit4/react-components/esm/select/Select";
import { getDate, lockStatuses, parseLockStatus, searchFilter, selectFilter, updateParams } from "services/common";
import { useSearchParams } from "react-router-dom";
import { toastMessage } from "./helpers";
import { exportClipboard, exportExcel } from "services/export";
import { userListHeaders } from "types/exportHeaders";
import { ActiveFilters } from "components/layout/filters";

interface UserListWrapperProps { analysisData: AnalysisData, userList: UserListData, showMatch?: boolean, inline?: boolean }

const UserListWrapper: FC<UserListWrapperProps> = ({ analysisData, userList, showMatch, inline = false }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userTypeFilter, setUserTypeFilter] = useState<SelectValue | undefined>(searchParams.get("type")?.split(":"));
  const [userGroupFilter, setUserGroupFilter] = useState<SelectValue | undefined>(searchParams.get("group")?.split(":"));
  const [lockStatusFilter, setLockStatusFilter] = useState<SelectValue | undefined>(searchParams.get("lock")?.split(":"));
  const [matches, setMatches] = useState(searchParams.get("match") === "1" ? true : false || true);
  const [search, setSearch] = useState<string | undefined>(searchParams.get("search") || undefined);
  const [filteredData, setFilteredData] = useState(userList.data);

  useEffect(() => {
    console.time("processing")
    let tempData = userList.data;

    tempData = userList.data
      .filter(f => !showMatch || f.hasMatches === matches)
      .filter(f => searchFilter(f, ["name", "userName"], search))
      .filter(f => selectFilter(f, "type", userTypeFilter))
      .filter(f => selectFilter(f, "userGroup", userGroupFilter))
      .filter(f => selectFilter(f, "lockStatus", lockStatusFilter));

    setFilteredData(tempData);
    console.timeEnd("processing");
  }, [matches, search, userList.data, userTypeFilter, userGroupFilter, lockStatusFilter, showMatch]);

  const getType = (type?: ListType) => {
    switch (type) {
      case ListType.Role:
        return { value: userList.role?.name, type: "Role" };
      case ListType.Transaction:
        return { value: userList.transaction?.identifier, type: "Transaction" };
      case ListType.Test:
        return { value: userList.test?.identifier, type: "Test" };
      default:
        return { value: "All", type: "Users" };
    }
  }
  const parseUsers = (data: ListUser[]): ExportUser[] => {
    return data.map(m => ({
      firstName: m.name.split(", ")[1],
      lastName: m.name.split(", ")[0],
      userName: m.userName,
      lockStatus: parseLockStatus(m.lockStatus) || "",
      type: UserType[m.type].name,
      userGroup: m.userGroup,
      validFrom: getDate(m.validity.split(" - ")[0], true),
      validTo: getDate(m.validity.split(" - ")[1])
    }));
  }
  const fileExport = () => {
    let category = getType(userList.listType);
    let data = analysisData;
    let pageHeaders = [
      ["Insights Access Risks"],
      ["Client", `${data.client?.name}`],
      ["SAP System", `${data.sapSystem?.sapSystemName} - ${data.sapSystem?.sapClient} - ${data.sapSystem?.sapNickname}`],
      ["Extraction date", getDate(data.extractionDate || "")],
      [category.type || "Users", category.value || "All"],
    ];
    let filePrefix = `${data.client?.name}-${data.sapSystem?.sapNickname}`;
    let filename = userList.listType
      ? `${filePrefix}-Users-${category.type}-${category.value}`
      : !matches
        ? `${filePrefix}-Users-no-risks`
        : `${filePrefix}-Users-with-risks`;

    toastMessage("Exporting data");
    exportExcel(parseUsers(filteredData), userListHeaders, filename, pageHeaders);
  }
  const copyToClipboard = async () => {
    await toastMessage("Processing data");
    exportClipboard(parseUsers(filteredData), userListHeaders);
    await toastMessage("Data copied to the clipboard");
  }

  const getAnchor = () => {
    switch (userList.listType) {
      case ListType.Test:
        return userList.test?.identifier;
      case ListType.Role:
        return userList.role?.name;
      case ListType.Transaction:
        return userList.transaction?.identifier;
    }
  }

  return (
    <Panel id="list-top">
      {
        showMatch &&
        <Search
          searchType={"secondary"}
          value={search}
          onChange={(value: string) => {
            setSearch(value);
            setSearchParams(updateParams("search", value, searchParams));
          }}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        />
      }
      <div className="flex items-center items-stretch gap-4 mt-4">
        {
        !showMatch &&
        <div className="flex-grow">
          <Search
            searchType={"secondary"}
            value={search}
            onChange={(value: string) => {
              setSearch(value);
              setSearchParams(updateParams("search", value, searchParams));
            }}
            className="list-filter"
          />
        </div>
      }
        <div>
          <Select
            placeholder="User type"
            data={Array.from(new Set(userList.data.map(m => m.type)))
              .map(m => ({ value: m, label: UserType[m].name, descValue: filteredData.filter(f => f.type === m).length }))
            }
            multiple={true}
            value={userTypeFilter}
            dropdownMatchWidth={512}
            suffixTemplate={item => {
              return (item.descValue > 0 && <span>{item.descValue} items</span>)
            }}
            onSelect={(v) => {
              setUserTypeFilter(v);
              !inline && setSearchParams(updateParams("type", v, searchParams));
            }}
            className="list-filter"
          />
        </div>
        <div>
          <Select
            placeholder="Lock status"
            data={Array.from(new Set(userList.data.map(m => m.lockStatus).filter(f => lockStatuses.includes(f)))).map(m => ({ value: m, label: parseLockStatus(m) }))}
            multiple
            value={lockStatusFilter}
            dropdownMatchWidth={512}
            onSelect={(v) => {
              setLockStatusFilter(v);
              !inline && setSearchParams(updateParams("lock", v, searchParams));
            }}
          />

        </div>
        <div>
          <Select
            placeholder="User group"
            data={Array.from(new Set(userList.data.map(m => m.userGroup))).map(m => ({ value: m, label: m, descValue: filteredData.filter(f => f.userGroup === m).length }))}
            searchable={true}
            multiple={true}
            value={userGroupFilter}
            dropdownMatchWidth={512}
            suffixTemplate={item => {
              return (item.descValue > 0 && <span>{item.descValue} items</span>)
            }}
            onSelect={(v) => {
              setUserGroupFilter(v)
              !inline && setSearchParams(updateParams("group", v, searchParams));
            }}
            className="list-filter"
          />
        </div>
          {
            showMatch && <div className="flex-grow">
            <Select
              placeholder="Access risks"
              data={[
                { value: 0, label: "Users without test matches", descValue: userList.data.filter(f => f.hasMatches === false).length || 0 },
                { value: 1, label: "Users with test matches", descValue: userList.data.filter(f => f.hasMatches === true).length || 0 }
              ]}
              suffixTemplate={item => {
                return (item.descValue > 0 && <span>{item.descValue} users</span>)
              }}
              value={matches ? 1 : 0}
              onSelect={(v) => {
                setMatches(v === 1);
                !inline && setSearchParams(updateParams("match", v, searchParams))
              }}
              className="list-filter"
            />
            </div>
          }
        <div className="flex-none">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button icon="icon-copy-outline" onClick={() => copyToClipboard()} style={{ marginRight: 16 }}>Copy</Button>
            <Button icon="icon-table-data-outline" onClick={() => fileExport()}>Export</Button>
          </div>
        </div>
      </div>
      <ActiveFilters rows={filteredData.length || 0} />
      <UserList data={filteredData || []} showMatch={showMatch} anchor={getAnchor()} inline={inline} />
    </Panel>
  )
}

export default UserListWrapper;
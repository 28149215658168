import { Header, HeaderOptionItem, Avatar, DropdownButton, ListItem, Modal, Switch } from "@appkit4/react-components"
import { jwtDecode } from "jwt-decode";
import { FC, useCallback, useEffect, useState } from "react"
import { useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserLevel } from "types/analysis";
import { PwCJwt } from "types/common";
import { userLevels } from "types/user";
import { isAutoScroll } from "services/common";
import Loader from "components/common/loader";
import { FetchClient } from "queries/hooks/administration/client";
import { FetchAnalysisData } from "queries/hooks/analysis/analysis";


const PageHeader: FC = () => {
  const { analysisId, clientId } = useParams();
  const [cookie, setCookie, removeCookie] = useCookies();
  const [jwtData, setJwtData] = useState<PwCJwt>();
  const [showPreferences, setShowPreferences] = useState(false);

  const navigate = useNavigate();

  const { isPending, data } = FetchAnalysisData(analysisId);

  const { isPending: clientPending, data: clientData } = FetchClient(clientId);

  const getUserDetails = useCallback(() => {
    const token = cookie["id_token"];
    if (token)
      setJwtData(jwtDecode<PwCJwt>(token));
  }, [cookie]);

  const getInitials = `${jwtData?.given_name.charAt(0)}${jwtData?.family_name.charAt(0)}`;

  const checkUserLevel = useCallback((level: UserLevel) => {
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] < level;
  }, [jwtData]);

  useEffect(() => {
    if (!jwtData) getUserDetails();
  }, [clientId, analysisId, getUserDetails, jwtData]);
  return <>
      <Header
        type="transparent"
        titleTemplate={() => "Insights Access  Risks"}
        subTitleTemplate={
          () => 
            <>
              {
                analysisId
                  ? isPending ? <Loader/> : data?.client?.name
                  : clientId
                    ? clientPending ? <Loader/> : clientData?.name
                    :"Administration"
              }
            </>
        }
        contentTemplate={() => <>
          {data?.client?.clientId && <Link to={`/client/${data.client?.clientId}`}><HeaderOptionItem iconName="home-outline" label="Home"></HeaderOptionItem></Link>}
        </>}
        optionsTemplate={() => {
          return (
            <>
              <HeaderOptionItem iconName="help-question-outline" label="Help"></HeaderOptionItem>
              {(checkUserLevel(UserLevel.PwCUser)) && 
                <>
                  <DropdownButton
                    customTriggerNode={true}
                    customTriggerClassName="custom-node"
                    data={[
                      {
                        value: "administration",
                        label: "Clients and users",
                        iconName: "account-box-outline"
                      },
                      {
                        value: "ruleset",
                        label: "Rule sets",
                        iconName: "circuit-board-box-outline"
                      }
                    ]}
                    onSelect={(item) => {
                      switch(item) {
                        case "administration":
                          navigate("/administration");
                          break;
                        case "ruleset":
                          navigate("/ruleset");
                          break;
                      }
                    }}
                    prefixTemplate={(item) => <span className={`Appkit4-icon icon-${item.iconName}`}></span>}
                  >
                  <HeaderOptionItem iconSlot="end" iconName="down-chevron-outline" label="Menu"></HeaderOptionItem>
                  </DropdownButton>
                </>
              }
            </>
          )
        }}
        userTemplate={() => (
          <>
            <DropdownButton
              customTriggerNode={true}
              customTriggerClassName="custom-node"
              itemTemplate={(label, item) => {
                switch (item.value) {
                  case "user":
                    return (
                      <div className="flex flex-col px-4 gap-4">
                        <div><p className="ap-font-medium">{jwtData?.family_name}, {jwtData?.given_name}</p></div>
                        <div><p className="ap-font-small">{jwtData?.userLevel && userLevels.find(f => f.value === UserLevel[jwtData.userLevel].toString())?.label}</p></div>
                      </div>
                    )
                  default:
                    return <ListItem description={label} avatar={<span className={`Appkit4-icon icon-${item.iconName}`}></span>} />
                }
              }}
              onSelect={(item) => {
                switch(item) {
                  case "preferences":
                    setShowPreferences(true);
                    break;
                  case "logout":
                    navigate("/login?action=logout");
                    break;
                }
              }}
              data={[
                { value: "user", label: "", iconName: "person-outline" },
                { value: 'preferences', label: 'Preferences', iconName: 'setting-outline' },
                {
                  value: 'logout', label: 'Logout', iconName: 'lockclosed-locked-outline'
                }
              ]}
              prefixTemplate={(item) => <span className={`Appkit4-icon icon-${item.iconName}`}></span>}
            >
              <Avatar label={getInitials} role="button" disabled={false} ariaLabel="settings menu"></Avatar>
            </DropdownButton>
          </>)}>
      </Header>
      <Modal
        title="Preferences"
        visible={showPreferences}
        onClose={() => setShowPreferences(false)}
        closable={true}
        onCancel={() => setShowPreferences(false)}
        footer={<>
          <button className="ap-button ap-button-primary" onClick={() => setShowPreferences(false)}>Close preferences</button>
          </>}
      >
        <div className="ap-preferences-modal" style={{ minWidth: "35rem"}}>
          <div className="header">General</div>
          <div>
            <Switch 
            defaultChecked={isAutoScroll()}
            onChange={(value) => {
              if(value)
                localStorage.setItem("autoScroll", "true");
              else
                localStorage.removeItem("autoScroll");
            }}>Auto-scroll to results</Switch>
          </div>
        </div>
      </Modal>
      </>
}

export default PageHeader;
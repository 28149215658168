import PageFooter from "components/layout/pageFooter";
import { FC, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { Button, Loading } from "@appkit4/react-components";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FetchToken, RevokeToken } from "queries/hooks/authentication/login";

const PwCID_URI = process.env.REACT_APP_PWCID_URI || "";

const Login: FC = () => {
  const [searchParams] = useSearchParams();
  const [code] = useState<string | undefined>(searchParams.get("code") || undefined);
  const [action] = useState<string | undefined>(searchParams.get("action") || undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const SSOLogin = () => {
    window.location.assign(PwCID_URI);
  };

  const { data: tokenData, error } = FetchToken(code);
  const { data: revokeData, isPending: revokePending } = RevokeToken(token);

  const showForm = (action?: string) => {
    switch (action) {
      case "logout":
        return !revokePending
          ? <>
            <h3>Thank you for using</h3>
            <h1>Insights Access Risks</h1>
            <Link to="/login" reloadDocument><Button kind="primary">Login again</Button></Link>
          </>
          : <>
            <h3>Logging you out of</h3>
            <h1>Insights Access Risks</h1>
            <Loading loadingType='linear' indeterminate={true} compact={true} linearWidth={480}></Loading>
          </>;
      case "session-expired":
        return <>
            <h3>Thank you for using</h3>
            <h1>Insights Access Risks</h1>
          <p>Your session has expired. Please log in again.</p>
          <Button kind="primary" onClick={SSOLogin}>SSO Login</Button>
        </>;
      default:
        if (code) {
          return (<>
            <h3>Welcome to</h3>
            <h1>Insights Access Risks</h1>
            <Loading loadingType='linear' indeterminate={true} compact={true} linearWidth={480}></Loading>
            {error
              ? <><p>{error.message}</p><Button kind="primary" onClick={SSOLogin}>Try again</Button></>
              : <p>Logging you in, please wait.</p>}
          </>);
        } else {
          return <>
            <h3>Welcome to</h3>
            <h1>Insights Access Risks</h1>
            <Button kind="primary" onClick={SSOLogin}>SSO Login</Button>
          </>;
        }

    }
  }

  useEffect(() => {
    if (tokenData && tokenData.id_token) {
      let redirect = sessionStorage.getItem("redirect");
      sessionStorage.clear();
      localStorage.setItem("access_token", tokenData.access_token);
      setCookie("id_token", tokenData.id_token, {
        maxAge: 1800,
        secure: true,
        sameSite: "lax"
      });
      setCookie("refresh_token", tokenData.refresh_token, {
        maxAge: 1800,
        secure: true,
        sameSite: "strict"
      });
      navigate(redirect || (tokenData.clientId !== undefined ? `/client/${tokenData.clientId}` : "/administration"));
    }
  }, [navigate, setCookie, tokenData]);

  useEffect(() => {
    if (action === "logout" && !revokeData) {
      let refreshToken = cookies["refresh_token"];
      if(refreshToken) {
        setToken(refreshToken);
        removeCookie("id_token");
        removeCookie("refresh_token");
      }
    }
  }, [action, cookies, removeCookie, revokeData]);

  return (
    <div className="w-full flex h-screen">
      <div className="xl:w-7/12 lg:w-full">
        <div className="login-wrapper">
          <div className="login-logo" />
          <div className="login-body p-8">
            <div className="form-header">
              {showForm(action)}
            </div>
          </div>
          <div className="login-footer">
            <PageFooter />
          </div>
        </div>
      </div>
      <div className="xl:grow lg:hide">
        <div className="background-right" />
      </div>
    </div>
  );
}

export default Login;
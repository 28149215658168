import { Column, Table } from "@appkit4/react-components";
import PageFooter from "components/layout/pageFooter";
import { FC } from "react";


const CookieInformation: FC = () => {
  const loadBalancing = [
    { domain: "insights.accessrisks.pwc.fi", name: "ARRAffinity", lifespan: "Session" },
    { domain: "insights.accessrisks.pwc.fi", name: "ARRAffinitySameSite", lifespan: "Session" }
  ];
  const sessionManagement = [
    { domain: "insights.accessrisks.pwc.fi", name: "id_token", lifespan: "30 minutes" },
    { domain: "insights.accessrisks.pwc.fi", name: "refresh_token", lifespan: "30 minutes" }
  ];
  const security = [
    { domain: "insights.accessrisks.pwc.fi", name: "incap_ses_", lifespan: "Session" },
    { domain: "insights.accessrisks.pwc.fi", name: "nlbi_", lifespan: "Session" },
    { domain: "insights.accessrisks.pwc.fi", name: "visid_incap_", lifespan: "365 days" }
  ];
  const miscellaneous = [
    { domain: "insights.accessrisks.pwc.fi", name: "_cookies", lifespan: "7 months" }
  ];

  return (
    <div className="container mx-auto" id="dataview">
      <h1>Cookie Information</h1>
      <p>This page provides information about the cookies used on this website. Cookies are small text files that are stored on your device when you visit a website. They help the site remember your preferences, track your activity, and provide a personalized experience. </p>
      <h2>Load Balancing</h2>
      <p>These cookies are used to distribute web traffic evenly across servers. When you visit a website, these cookies help ensure that your requests are sent to the same server during your session, which can improve performance and reliability.</p>
      <Table
        originalData={loadBalancing}
        hasTitle
        striped
      >
        <Column field="name" sortKey="name">Name</Column>
        <Column field="domain" sortKey="domain">Domain</Column>
        <Column field="lifespan" sortKey="lifespan">Lifespan</Column>
      </Table>
      <h2>Session Management</h2>
      <p>These cookies help manage your login session on a website. They store information that verifies your identity, allowing you to stay logged in and access secure parts of the site without having to log in repeatedly.</p>
      <Table
        originalData={sessionManagement}
        hasTitle
        striped>
        <Column field="name" sortKey="name">Name</Column>
        <Column field="domain" sortKey="domain">Domain</Column>
        <Column field="lifespan" sortKey="lifespan">Lifespan</Column>
      </Table>
      <h2>Security</h2>
      <p>These cookies are used to protect the website from security threats, such as distributed denial-of-service (DDoS) attacks. They help identify and filter out malicious traffic, ensuring that legitimate users can access the site safely.</p>
      <Table
        originalData={security}
        hasTitle
        striped>
        <Column field="name" sortKey="name">Name</Column>
        <Column field="domain" sortKey="domain">Domain</Column>
        <Column field="lifespan" sortKey="lifespan">Lifespan</Column>
      </Table>
      <h2>Miscellaneous</h2>
      <p>These cookies are used for various purposes that don't fit neatly into other categories. They may store information about your preferences, help personalize your experience, or track your activity on the site.</p>
      <Table
        originalData={miscellaneous}
        hasTitle
        striped>
        <Column field="name" sortKey="name">Name</Column>
        <Column field="domain" sortKey="domain">Domain</Column>
        <Column field="lifespan" sortKey="lifespan">Lifespan</Column>
      </Table>
      <PageFooter />
    </div>
  );
}

export default CookieInformation;
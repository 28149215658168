import { FC, ReactNode } from "react";
import Authentication from "services/authentication";
import Sidebar from "./sidebar";
import PageFooter from "./pageFooter";
import PageHeader from "./pageHeader";

const AnalysisTemplate: FC<{ children?: ReactNode }> = ({ children }) => (
  <Authentication>
    <div>
      <PageHeader />
      <div className="flex flex-row" style={{ maxWidth: 1700 }}>
        <div className="basis-1/5">
          <Sidebar />
        </div>
        <div className="grow p-4 pt-7" id="dataview">
          {children}
          <PageFooter />
        </div>
      </div>
    </div>
  </Authentication>
);

export default AnalysisTemplate;
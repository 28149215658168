import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteData, getData, postData, putData } from "services/api-actions";
import { Bag } from "types/ruleset";

export const FetchBags = () => useQuery({
  queryKey: ["bags"],
  queryFn: () => getBags(),
  staleTime: 1000 * 60 * 5
});

export const PostBag = () => useMutation({
  mutationFn: (data?: Bag) => postBag(data),
  mutationKey: ["bags"],
});

export const PutBag = () => useMutation({
  mutationFn: (data?: Bag) => updateBag(data),
  mutationKey: ["bags"],
});

export const DeleteBag = () => useMutation({
  mutationFn: (data?: Bag) => deleteBag(data),
  mutationKey: ["bags"],
});

const getBags = async () => {
  const data = await getData<Bag[]>(`ruleset/bag`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
const postBag = async (data?: Bag) => {
  if (!data) throw new Error("No data provided");
  let bagRelations = data.bagRelations;
  data.bagRelations = undefined;
  const response = await postData(`ruleset/bag`, { bag: data, bagRelations: bagRelations });
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as Bag;
}
const updateBag = async (data?: Bag) => {
  if (!data) throw new Error("No data provided");
  let bagRelations = data.bagRelations;
  data.bagRelations = undefined;
  const response = await putData(`ruleset/bag`, { bag: data, bagRelations: bagRelations });
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as Bag;
}
const deleteBag = async (data?: Bag) => {
  if (!data) throw new Error("No data provided");
  const response = await deleteData(`ruleset/bag/${data.bagId}`);
  if (!response.result) throw new Error("Unexpected error occurred");
  return response.result as Bag;
}
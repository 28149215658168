import { Button, Column, Panel, Search, Switch, Tab, Table, Tabs } from '@appkit4/react-components';
import { ProfileItem } from 'components/common/helpers';
import Loader from 'components/common/loader';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { paginate, searchFilter, updateParams } from 'services/common';
import { ListType, Profile,  } from 'types/analysis';
import { ActiveFilters } from 'components/layout/filters';
import Paginate from 'components/common/paginate';
import UsersInline from '../users/usersInline';
import { useAtomValue } from 'jotai';
import { filterAtom } from 'jotai/store';
import { FetchProfiles } from 'queries/hooks/analysis/role';

const ProfileList: FC = () => {
  const { analysisId, profileId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const getCurrentPage = () => parseInt(searchParams.get("p") || "1");
  const getOffset = () => parseInt(searchParams.get("o") || "100");
  const getAttached = () => searchParams.get("attached") === "1" ? true : false;

  const navigate = useNavigate();

  const getData = useCallback(() => {
    return data?.profiles.filter(f => f.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, getAttached]);

  const userFilter = useAtomValue(filterAtom);

  const { data, isPending } = FetchProfiles(analysisId, userFilter, getAttached());

  const handleClick = (id: string) => {
    if (profileId && id === profileId)
      navigate(`/analysis/${analysisId}/profiles`);
    else
      navigate(`/analysis/${analysisId}/profiles/${id}`);
  }

  return (
    <>
      <div className="flex items-center gap-4 mt-4">
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => {
              setSearch(value);
            }}
            searchValue={search}
            className="list-filter"
          />
        </div>
        <div>
          <div>
            <Switch onChange={(value: boolean) => {
              setSearchParams(updateParams("attached", value ? 1 : 0, searchParams));
            }} checked={getAttached()} showIndicator>Profiles with users only</Switch>
          </div>
        </div>
        <div>
          {(search || getAttached()) && <Button onClick={() => {
            setSearch("");
            setSearchParams(updateParams("attached", [], searchParams));
            setSearchParams(updateParams("search", "", searchParams));
          }} kind="secondary" style={{ display: "block" }}>Clear</Button>}
        </div>
      </div>
      {data && <ActiveFilters rows={data?.pagination.totalItems}></ActiveFilters>}
      <div
        className="ap-list-header ap-business-process-header flex gap-4 p-4 pt-6 pb-6 items-center w-full"
      >
        <div className="basis-12">
        </div>
        <div className="basis-80">
          Profile name
        </div>
        <div className="basis-32">
          Profile type
        </div>
        <div className="basis-28">
          One or more matches
        </div>
        <div className="grow">Users with the profile</div>
      </div>
      {
        isPending
          ? <Loader inline loadingType="circular" />
          : paginate(getData(), getCurrentPage(), getOffset())?.map(profile => (
            <div className="list-row" key={profile.name} id={profile.name}>
              <ProfileItem key={profile.profileId} profile={profile} open={profileId === profile.profileId} onClick={() => handleClick(profile.profileId)} />
              {
                profileId === profile.profileId && (
                  <ProfileDetails profile={profile} />
                )
              }
            </div>
          ))
      }
      {data && !isPending && (<Paginate
        getTotalPages={Math.ceil(data?.pagination.totalItems / getOffset())}
      />)}
    </>
  );
}

const ProfileDetails: FC<{ profile: Profile }> = ({ profile }) => {
  const [activeIndex, setActiveIndex] = useState(profile.isComposite ? 0 : 1);
  const [search, setSearch] = useState("");
  const handleTabChange = (index: number) => {
    setActiveIndex(index);
  }
  useEffect(() => {
    let element = document.getElementById(profile.name);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        inline: "nearest"
      });
    }
  });
  const showData = (index: number) => {
    switch (index) {
      case 0:
        return (
          <Panel>
            <div className="flex items-center gap-4 mt-4">
            <div>
                <Search
                  searchType={"secondary"}
                  value={search}
                  onChange={(value: string) => {
                    setSearch(value);
                  }}
                  className="list-filter"
                />
              </div>
            </div>
            <ActiveFilters rows={profile.profiles?.length || 0} />
            <Table
              hasTitle
              condensed
              striped
              originalData={profile.profiles?.filter(f => searchFilter(f, ["name"], search))}
            >
              <Column field="name" sortKey="name" >Profile name</Column>
              <Column field="hasMatches" sortKey="hasMatches" renderCell={(row: Profile, field: string) => {
                return row.hasMatches ? <span className="Appkit4-icon icon-alert-fill access-risk"></span> : null;
              }}>One or more matches</Column>
            </Table>
          </Panel>
        );
      case 1:
        return (
          <UsersInline type={ profile.isComposite ? ListType.CompositeProfile : ListType.Profile } typeId={profile.profileId}/>
        )
      default:
        return null;
    }
  }
  return (
    <div className="ap-accordion-open">
      <div className="tabs-wrapper">
        <Tabs activeIndex={activeIndex} onTabChange={handleTabChange}>
          <Tab label="Profiles" disabled={!profile.isComposite}></Tab>
          <Tab label="Users"></Tab>
        </Tabs>
      </div>
      {showData(activeIndex)}
    </div>
  );
}

export default ProfileList;
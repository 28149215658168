import { useQuery } from "@tanstack/react-query";
import { getData } from "services/api-actions";
import { objToParams } from "services/common";
import { RuleBag } from "types/analysis";

export const FetchRulesets = (clientId?: string) => useQuery({
  queryKey: ["rulesets", clientId],
  queryFn: () => getRuleSets(clientId),
  enabled: !!clientId,
  staleTime: 1000 * 60 * 5
});

const getRuleSets = async (clientId?: string) => {
  if (!clientId) throw new Error("No client id provided");
  var payload = objToParams({
    clientId: clientId
  });
  const data = await getData<RuleBag[]>(`ruleset/bag?${payload}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}

import { FC, useEffect, useState } from "react"
import AnalysisTemplate from "components/layout/analysisTemplate"
import Loader from "components/common/loader";
import { ProfileData, TestData, UserData } from "types/user";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Accordion, AccordionItem, Breadcrumb, BreadcrumbItem, Button, Column, Panel, Search, Select, Tab, Table, Tabs, TextArea } from "@appkit4/react-components";
import { ListType, ListUser, Role, UserLevel, UserType } from "types/analysis";
import { getDate, parseLockStatus, searchFilter, selectFilter } from "services/common";
import { Floater, ProfileItem, RoleItem, TechnicalRoles, TestInfo, toastMessage } from "components/common/helpers";
import UserMatches from "./userMatches";
import ToolTip from "components/common/tooltip";
import { exportAllUserTests, exportExcel } from "services/export";
import { fullMatchHeaders } from "types/exportHeaders";
import { SelectValue } from "@appkit4/react-components/esm/combobox/Combobox";
import Paginate from "components/common/paginate";
import { ActiveFilters } from "components/layout/filters";
import { jwtDecode } from "jwt-decode";
import { useCookies } from "react-cookie";
import { PwCJwt } from "types/common";
import { useAtomValue } from "jotai";
import { collapsedAtom, filterAtom } from "jotai/store";
import { FetchSameRolesProfiles, FetchUserData, FetchUserTransactions } from "queries/hooks/analysis/user";
import { FetchAnalysisData } from "queries/hooks/analysis/analysis";

enum UserTab {
  TESTS,
  TRANSACTIONS,
  ROLES,
  PROFILES,
  SAME
}

const User: FC<{ index: number }> = ({ index }) => {
  const [tabIndex, setTabIndex] = useState(index);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { analysisId, userId, testId, transactionId, roleId } = useParams();

  const userFilter = useAtomValue(filterAtom);

  const { data: userData, isPending } = FetchUserData(analysisId, userId, userFilter);
  const { data: analysisData } = FetchAnalysisData(analysisId);

  const showPage = (tabIndex: number) => {
    switch (tabIndex) {
      case UserTab.TESTS:
        return <UserTests businessProcesses={Array.from(new Set(userData?.tests.map((m: TestData) => m.businessProcess.split(":")[1])))} userData={userData} />
      case UserTab.TRANSACTIONS:
        return <UserTransactions userData={userData} />
      case UserTab.ROLES:
        return <UserRoles userData={userData} />
      case UserTab.PROFILES:
        return <UserProfiles userData={userData} />
      case UserTab.SAME:
        return <SameRolesProfiles userData={userData} />
    }
  }

  const exportAll = async () => {
    if (!analysisId || !userId || !userData) return;
    let temp = analysisData;
    if (!temp) {
      toastMessage("Unable to fetch data");
      return;
    }
    let pageHeaders = [
      ["Insights - Access Risks"],
      ["Client:", temp.client?.name || ""],
      ["SAP System:", `${temp.sapSystem?.sapSystemName} - ${temp.sapSystem?.sapClient} - ${temp.sapSystem?.sapNickname}`],
      ["Extraction date", getDate(temp.extractionDate || "")],
      ["Username:", userData.userName],
      ["Type", "All Access Risks matches"]
    ]
    setLoading(true);
    toastMessage("Exporting data");
    let data = await exportAllUserTests(analysisId, userId, userData);
    setLoading(false);
    exportExcel(data, fullMatchHeaders, `${userData.userName}-All-Matches`, pageHeaders);
  }
  useEffect(() => {
    if (testId)
      setTabIndex(UserTab.TESTS);
    if (transactionId)
      setTabIndex(UserTab.TRANSACTIONS);
    if (roleId)
      setTabIndex(UserTab.ROLES);
  }, [setTabIndex, analysisId, testId, transactionId, userData, userId, roleId]);
  return (
    <AnalysisTemplate>
      <Breadcrumb>
        <BreadcrumbItem>Analysis</BreadcrumbItem>
        <BreadcrumbItem>Users</BreadcrumbItem>
        <BreadcrumbItem>{userData?.userName}</BreadcrumbItem>
      </Breadcrumb>
      {isPending || !userData
        ? <Loader loadingType="circular" />
        : <>
          <UserDataBox userData={userData} />
          <Panel title={userData.userName} style={{ marginTop: 24 }}>
            <div className="flex w-full gap-4 mb-6">
              <div className="basis-1/6">
                <p className="ap-font-medium">Full name</p>
                <p>{userData.fullName}</p>
              </div>
              <div className="basis-1/6">
                <p className="ap-font-medium">Lock status</p>
                <p>{parseLockStatus(parseInt(userData.lockStatus) || 0)}</p>
              </div>
              <div className="basis-1/6">
                <p className="ap-font-medium">User group</p>
                <p>{userData.userGroup || "None assigned"}</p>
              </div>
              <div className="basis-1/6">
                <p className="ap-font-medium">Last logon</p>
                <p>{getDate(userData.lastLogon)}</p>
              </div>
              <div className="basis-1/6">
                <p className="ap-font-medium">Validity</p>
                <p>{userData.validity.split(" - ").map(m => m.split(" ")[0]).join(" - ")}</p>
              </div>
              <div className="basis-1/6">
                <p className="ap-font-medium">Reference user</p>
                <p>{userData.refUserName || "None"}</p>
              </div>
            </div>
            <div>
              <Button onClick={exportAll} loading={loading} kind="secondary"><span className="Appkit4-icon icon-table-data-outline" ></span>Export all matches</Button>
            </div>
          </Panel>
          <Tabs type="underline" activeIndex={tabIndex} onTabChange={(i: number) => {
            switch (i) {
              case UserTab.TESTS:
                navigate(`/analysis/${analysisId}/user/${userId}/test`);
                setTabIndex(0);
                break;
              case UserTab.TRANSACTIONS:
                navigate(`/analysis/${analysisId}/user/${userId}/transaction`);
                setTabIndex(1);
                break;
              case UserTab.ROLES:
                navigate(`/analysis/${analysisId}/user/${userId}/role`);
                setTabIndex(2);
                break;
              case UserTab.PROFILES:
                navigate(`/analysis/${analysisId}/user/${userId}/profile`);
                setTabIndex(3);
                break;
              case UserTab.SAME:
                navigate(`/analysis/${analysisId}/user/${userId}/samerolesprofiles`);
                setTabIndex(4);
                break;
            }
          }}>
            <Tab label="Tests with matches" value="Tests" />
            <Tab label="Transactions with matches" value="Transactions" />
            <Tab label="Assigned roles" value="Roles" />
            <Tab label="Assigned profiles" value="Profiles" />
            <Tab label="Users with same roles/profiles" value="Same" />
          </Tabs>
          {
            showPage(tabIndex)
          }
        </>
      }
    </AnalysisTemplate>
  )
}

const UserDataBox: FC<{ userData: UserData }> = ({ userData }) => {
  const [visible, setVisible] = useState(false);
  const [float, setFloat] = useState(false);
  const [activeKeys, setActiveKeys] = useState<string[]>(["info"]);

  const collapsed = useAtomValue(collapsedAtom);

  useEffect(() => {
    const handleScroll = () => {
      let visibleLimit = window.scrollY > 300;
      let floatLimit = window.scrollY >= 624;
      if (visible !== visibleLimit)
        setVisible(collapsed ? false : visibleLimit);
      if (float !== floatLimit)
        setFloat(floatLimit);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visible, float, collapsed]);
  useEffect(() => {
    if (collapsed)
      setVisible(false);
  }, [collapsed]);
  return visible
    ? (
      <div className={`ap-userdata-box ${float && "ap-userdata-float"}`}>
        <Accordion multiple={true} onClick={(value: string[]) => setActiveKeys(value)} activeKeys={activeKeys} style={{ padding: 0 }}>
          <AccordionItem title="User information" itemKey="info">
            <h2>{userData.userName}</h2>
            <div className="flex flex-col gap-4">
              <div>
                <p className="ap-font-medium">Full name</p>
                <p>{userData.fullName}</p>
              </div>
              <div>
                <p className="ap-font-medium">User group</p>
                <p>{userData.userGroup || "None assigned"}</p>
              </div>
              <div>
                <p className="ap-font-medium">Lock status</p>
                <p>{(parseLockStatus(parseInt(userData.lockStatus) || 0)) || "Not locked"}</p>
              </div>
              <div>
                <p className="ap-font-medium">Last logon</p>
                <p>{getDate(userData.lastLogon)}</p>
              </div>
              <div>
                <p className="ap-font-medium">Validity</p>
                <p>{userData.validity.split(" - ").map(m => m.split(" ")[0]).join(" - ")}</p>
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    )
    : null
}

const UserRoles: FC<{ userData?: UserData }> = ({ userData }) => {
  const { analysisId, userId, roleId } = useParams();
  const [roles] = useState<Role[]>(userData?.roles || []);
  const [search, setSearch] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);

  //const filteredData = paginate(roles?.filter(f => searchFilter(f, ["description", "name"], search)), getCurrentPage(), getOffset());
  const filteredData = roles?.filter(f => searchFilter(f, ["description", "name"], search));
  // const getTotalPages = filteredData ? Math.ceil(filteredData.length / getOffset()) : 1;
  return !roles
    ? <Loader loadingType="circular" />
    : (
      <>
        <div className="flex items-center gap-4 mt-4">
          <div>
            <Search
              searchType={"secondary"}
              onChange={(value: string) => setSearch(value)}
              className="list-filter"
            />
          </div>
        </div>
        <ActiveFilters rows={filteredData?.length || 0} />
        <Floater>
          <div
            className="ap-list-header ap-business-process-header flex gap-4 p-4 pt-6 pb-6 items-center w-full">
            <div className="basis-12">
            </div>
            <div className="basis-80">
              Role
            </div>
            <div className="basis-32">
              Role type
            </div>
            <div className="basis-28">One or more matches</div>
            <div className="basis-96">Valid from - Valid to</div>
          </div>
        </Floater>
        {
          filteredData?.map((role: Role) => (
            <div className="list-row pointer" key={role.roleId} id={role.name}>
              <Link to={role.roleId === roleId ? `/analysis/${analysisId}/user/${userId}` : `/analysis/${analysisId}/user/${userId}/role/${role.roleId}#${role.name}`} relative="path">
                <RoleItem open={roleId === role.roleId} role={role} />
              </Link>
              {
                roleId === role.roleId && (
                  <div className="ap-accordion-open">
                    {role.hasMatches && (
                      <div className="ap-wrapper-inline ap-wrapper-matches">
                        <Tabs activeIndex={activeIndex} onTabChange={(value) => setActiveIndex(value)}>
                          <Tab label="Matches" value="Matches">
                            <UserMatches userData={userData} type={role.isComposite ? ListType.CompositeRole : ListType.Role} typeId={role.roleId} />
                          </Tab>
                          <Tab label="Technical roles" value="Technical roles" disabled={!role.isComposite}>
                            <TechnicalRoles roles={role.roles || []} />
                          </Tab>
                        </Tabs>
                      </div>
                    )}
                  </div>
                )
              }
            </div>
          ))}
        {/* <Paginate
          getTotalPages={getTotalPages}
        /> */}
      </>
    )
}

interface UserTestsProps {
  businessProcesses?: string[];
  userData?: UserData;
}

const UserTests: FC<UserTestsProps> = ({ businessProcesses, userData }) => {
  const { testId } = useParams();

  const [openProcess, setOpenProcess] = useState<string>(userData?.tests.find(f => f.testId === testId)?.businessProcess.split(":")[1] || "");

  return (
    <>
      <ActiveFilters rows={userData?.tests.length || 0} />
      <div
        className="ap-list-header ap-business-process-header items-center flex p-4 pt-6 pb-6 gap-4"
      >
        <div className="basis-12">
        </div>
        <div className="grow">
          Business process
        </div>
      </div>
      {businessProcesses && businessProcesses.sort().map((process, index) => (
        <div key={process} className="list-row">
          <div
            className="flex items-center p-4 gap-4"
          >
            <div
              className="basis-12">
              <span
                onClick={() => setOpenProcess(openProcess === process ? "" : process)}
                className={openProcess === process ? "Appkit4-icon icon-down-chevron-outline pointer" : "Appkit4-icon icon-right-chevron-outline pointer"}
              />
            </div>
            <div className="grow">
              <b>{process}</b>
            </div>
          </div>
          {
            openProcess === process && <TestDetails userData={userData} process={process} />
          }
        </div>
      ))}
    </>
  );
}

const TestDetails: FC<{ userData?: UserData, process?: string }> = ({ userData, process }) => {
  const { analysisId, userId, testId } = useParams();
  const [cookie] = useCookies();

  const checkUserLevel = (level: UserLevel) => {
    const jwtData = jwtDecode<PwCJwt>(cookie["id_token"]);
    return jwtData?.userLevel && UserLevel[jwtData.userLevel as keyof typeof UserLevel] !== level;
  }
  return (
    <div id={testId}>
      <div
        className="ap-list-header ap-business-process-test-header ap-accordion-open flex items-center p-5 gap-4"
      >
        <div className="basis-12">
        </div>
        <div className="basis-96">
          Test
        </div>
        <div className="basis-20">
          Test type
        </div>
        <div className="grow">
          Included function(s)
        </div>
      </div>
      {userData?.tests?.filter((f: TestData) => f.businessProcess.split(":")[1] === process).map((test, index) => (
        <div key={test.identifier} className="list-row-1">
          <Link id={test.identifier} className="pointer" to={testId === test.testId ? `/analysis/${analysisId}/user/${userId}` : `/analysis/${analysisId}/user/${userId}/test/${test.testId}#${test.identifier}`} relative="path">
            <TestInfo test={test} open={test.testId === testId} />
          </Link>
          {(testId === test.testId && checkUserLevel(UserLevel.Business)) && (
            <div className="ap-accordion-open">
              <div className="ap-wrapper-inline ap-wrapper-matches">
                <UserMatches type={ListType.Test} typeId={testId} userData={userData} />
              </div>
            </div>
          )}
        </div>
      ))
      }
    </div>
  )
}

const UserTransactions: FC<{ userData?: UserData }> = ({ userData }) => {
  const [search, setSearch] = useState("");
  const { analysisId, userId, transactionId } = useParams();

  const userFilter = useAtomValue(filterAtom);
  const { data: transactionData, isPending } = FetchUserTransactions(analysisId, userId, userFilter);

  return (
    <>
      <div className="flex items-center gap-4 mt-4">
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => setSearch(value)}
            className="list-filter"
          />
        </div>
      </div>
      <ActiveFilters rows={transactionData?.length || 0} />
      <Floater>
        <div className="ap-list-header ap-business-process-header flex gap-4 p-4 pt-6 pb-6 items-center w-full">
          <div className="basis-12"></div>
          <div className="basis-96">
            Transaction
          </div>
          <div className="basis-25">
            Transaction type
          </div>
          <div className="grow"></div>
        </div>
      </Floater>
      {
        isPending
          ? <Loader inline />
          : <>
            {transactionData?.filter(f => searchFilter(f, ["description", "identifier"], search)).map((transaction, index) => (
              <div
                key={transaction.transactionId}
                id={transaction.identifier}
                className="list-row"
              >
                <Link
                  className="pointer"
                  to={
                    transactionId === transaction.transactionId
                      ? `/analysis/${analysisId}/user/${userId}`
                      : `/analysis/${analysisId}/user/${userId}/transaction/${transaction.transactionId}#${transaction.identifier}`} relative="path">
                  <div
                    className={`flex items-center p-4 gap-4 ${transactionId === transaction.transactionId ? "ap-accordion-open" : ""}`}
                  >
                    <div className="basis-12">
                      <span
                        className={transactionId === transaction.transactionId ? "Appkit4-icon icon-down-chevron-outline pointer" : "Appkit4-icon icon-right-chevron-outline pointer"}
                      />
                    </div>
                    <div className="basis-96">
                      <b>{transaction.identifier}</b>
                      <p>{transaction.description}</p>
                    </div>
                    <div className="basis-25">
                      {transaction.type === "T"
                        ? <ToolTip content="Transaction"><span className="Appkit4-icon icon-convert-outline"></span></ToolTip>
                        : <ToolTip content="Special transaction"><span className="Appkit4-icon icon-star-outline"></span></ToolTip>
                      }
                    </div>
                    <div className="grow"></div>
                  </div>
                </Link>
                {(userData && transactionId && transactionId === transaction.transactionId) && (
                  <div className="ap-accordion-open">
                    <div className="ap-wrapper-inline ap-wrapper-matches ">
                      <UserMatches type={ListType.Transaction} typeId={transactionId} userData={userData} />
                    </div>
                  </div>
                )}
              </div>
            ))
            }
          </>
      }
    </>
  )
}

const UserProfiles: FC<{ userData?: UserData }> = ({ userData }) => {
  const { analysisId, profileId, userId } = useParams();
  const [search, setSearch] = useState("");
  const [data, setData] = useState<ProfileData[]>(userData?.profiles || []);

  const getData = () => data.filter(f => searchFilter(f, ["name"], search));

  return (
    <>
      <div className="flex items-center gap-4 mt-4">
        <div>
          <Search
            searchType={"secondary"}
            onChange={(value: string) => setSearch(value)}
            className="list-filter"
          />
        </div>
      </div>
      <ActiveFilters rows={data.length} />
      <Floater>
        <div
          className="ap-list-header ap-business-process-header flex items-center p-4 pt-6 pb-6 gap-4 w-full"
        >
          <div className="basis-12"></div>
          <div className="basis-80">
            Profile name
          </div>
          <div className="basis-32">
            Profile type
          </div>
          <div className="basis-28">
            One or more matches
          </div>
        </div>
      </Floater>
      {getData().map(profile => (
        <div key={profile.profileId} className="list-row">
          <Link className="pointer" to={profileId === profile.profileId ? `/analysis/${analysisId}/user/${userId}/profile/` : `/analysis/${analysisId}/user/${userId}/profile/${profile.profileId}`} relative="path">
            <ProfileItem profile={profile} open={profile.profileId === profileId} />
          </Link>
          <div className="ap-accordion-open">
            {profile.profileId === profileId && profile.profiles?.map(p => (
              <div className="list-row-1" key={p.profileId}>
                <ProfileItem profile={p} open={false} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  )
}

const SameRolesProfiles: FC<{ userData?: UserData }> = ({ userData }) => {
  const { analysisId, userId } = useParams();
  const [search, setSearch] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState<SelectValue | undefined>([]);
  const [userGroupFilter, setUserGroupFilter] = useState<SelectValue | undefined>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(25);

  const { data: users, isPending } = FetchSameRolesProfiles(analysisId, userId);

  const getData = () => users?.filter(f => searchFilter(f, ["name", "userName"], search))
    .filter(f => selectFilter(f, "type", userTypeFilter))
    .filter(f => selectFilter(f, "userGroup", userGroupFilter)) || [];

  return isPending
    ? <Loader loadingType="circular" inline showTitle />
    : (<Panel>
      <div className="flex items-center gap-4 mt-4">
        <div>
          <Search
            searchType={"secondary"}
            value={search}
            onChange={(value: string) => {
              setSearch(value);
            }}
            className="list-filter"
          />
        </div>
        <div>
          <Select
            placeholder="User type"
            data={Array.from(new Set(users?.map(m => m.type)))
              .map(m => ({ value: m, label: UserType[m].name, descValue: getData().filter(f => f.type === m).length }))
            }
            multiple={true}
            value={userTypeFilter}
            suffixTemplate={item => {
              return (item.descValue > 0 && <span>{item.descValue} items</span>)
            }}
            onSelect={(v) => {
              setUserTypeFilter(v);
            }}
            className="list-filter"
          />
        </div>
        <div>
          <Select
            placeholder="User group"
            data={Array.from(new Set(users?.map(m => m.userGroup))).map(m => ({ value: m, label: m, descValue: getData().filter(f => f.userGroup === m).length })) || []}
            searchable={true}
            multiple={true}
            value={userGroupFilter}
            suffixTemplate={item => {
              return (item.descValue > 0 && <span>{item.descValue} items</span>)
            }}
            onSelect={(v) => {
              setUserGroupFilter(v);
            }}
            className="list-filter"
          />
        </div>
      </div>
      <ActiveFilters rows={getData().length} />
      <Table
        originalData={getData()}
        hasTitle
        striped
        pageSize={offset}
        currentPage={currentPage}
      >
        <Column field="userName" sortKey="userName" renderCell={(data: ListUser) => (
          <Link to={`/analysis/${analysisId}/user/${data.userId}`} reloadDocument>
            {data.userName}
          </Link>
        )}>
          Username
        </Column>
        <Column field="name" sortKey="name">
          Name
        </Column>
        <Column field="type" sortKey="type" renderCell={(data: ListUser) => (
          <ToolTip content={UserType[data.type].name} position="bottom">
            <span
              className={`Appkit4-icon ${UserType[data.type].icon}`}
            ></span>
          </ToolTip>
        )}>
          Type
        </Column>
        <Column
          field="lockStatus"
          sortKey="lockStatus"
          renderCell={(data: ListUser) => data.lockStatus > 0 && (
            <span className="table-cell">
              <ToolTip content={parseLockStatus(data["lockStatus"] || 0) || ""} position="bottom">
                <span className="Appkit4-icon icon-lockclosed-locked-fill"></span>
              </ToolTip>
            </span>
          )}
        >
          Locked
        </Column>
        <Column field="userGroup" sortKey="userGroup">
          Group
        </Column>
        <Column field="validity" sortKey="validity">
          Validity
        </Column>
        <Column field="hasMatches" sortKey="hasMatches" renderCell={(data: ListUser) => data.hasMatches && (<span className="Appkit4-icon icon-check-hook-outline"></span>)}>
          Exact match
        </Column>
      </Table>
      <Paginate
        getTotalPages={Math.ceil(getData().length / offset) || 0}
        currentPage={currentPage}
        pageOffset={offset}
        setCurrentPage={setCurrentPage}
        setPageOffset={setOffset}
      />
    </Panel>);
}

export default User;
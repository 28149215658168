import { FC } from "react";
import { Loading } from "@appkit4/react-components";

const Loader:FC<{ inline?: boolean, loadingType?: "linear" | "circular", showTitle?: boolean, error?: string | null }> = ({ inline, loadingType, error, showTitle = false}) => error 
? (
  <div style={{ margin: inline ? "0 auto" : "40vh auto", padding: 16, width: "200px" }}>
    <div style={{ textAlign: "center" }}><p className="ap-font-medium">An error occurred, refresh the page to try again.</p></div>
    <div style={{ textAlign: "center" }}><p className="ap-font-medium">If the issue persists, please contact the support.</p></div>
  </div>
)
: (
  <div style={{ margin: inline ? "0 auto" : "40vh auto", padding: 16, width: "200px" }}>
    <Loading loadingType={loadingType || "linear"} circularWidth={loadingType === "circular" ? "64px" : undefined} indeterminate={true}/>
    <div style={{ textAlign: "center" }}>{ showTitle && <p className="ap-font-medium">This may take a while</p> }</div>
  </div>
)

export default Loader;
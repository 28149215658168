import { useQuery } from "@tanstack/react-query";
import { getData } from "services/api-actions";
import { RuleBusinessProcess, RuleCategory } from "types/ruleset";

export const FetchCategories = () => useQuery({
  queryKey: ["categories"],
  queryFn: () => getCategories(),
  staleTime: 1000 * 60 * 5
});
export const FetchBusinessProcesses = () => useQuery({
  queryKey: ["businessProcesses"],
  queryFn: () => getBusinessProcesses(),
  staleTime: 1000 * 60 * 5
});
export const FetchObjectString = (bagId?: string) => useQuery({
  queryKey: ["objectString", bagId],
  queryFn: () => getObjectString(bagId),
  staleTime: 1000 * 60 * 5,
  enabled: !!bagId
});

const getCategories = async () => {
  const data = await getData<RuleCategory[]>(`ruleset/categories`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
const getBusinessProcesses = async () => {
  const data = await getData<RuleBusinessProcess[]>(`ruleset/businessprocesses`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
const getObjectString = async (bagId?: string) => {
  if (!bagId) throw new Error("No bag id provided");
  const data = await getData<string>(`ruleset/objects/${bagId}`);
  if (!data.result) throw new Error("No data found");
  return data.result;
}
import { atom } from "jotai";
import { AnalysisNav } from "types/analysis";
import { GeneralFilter } from "types/common";

export const defaultFilter = {
  tests: [1, 4]
};
export const defaultNav = {
  mainNav: 0
}
export const collapsed = false;

export const filterAtom = atom<GeneralFilter>(defaultFilter);
export const navAtom = atom<AnalysisNav>(defaultNav);
export const collapsedAtom = atom<boolean>(false);
export type IExportBag = {
    bagName: string;
    bagDesc: string;
    setName: string;
    setDesc: string;
    testId: string;
    testDesc: string;
    bProcess: string;
    functionId: string;
    functionDesc: string;
    transactions: {
      trId: string;
      trDesc: string;
      objects: {
        o: string;
        f: string;
        v: string
      }[]
    }[]
  }
  
  export type ListFilter<T> = {
    key: keyof T;
    values: string | string[]
  }
  
  export interface IBagListState {
    loaded: boolean;
    selected: string;
    bags: Bag[];
    sets: Array<RuleSet>
  }
  export type RuleSet = {
    setId?: string;
    name?: string;
    type?: number;
    description?: string;
    versionStatus: number;
    versionNumber: number;
    versionComments: string;
    testRelations?: SetRelation[];
    created?: string;
    modified?: string;
    deleted?: Boolean;
    clientId?: string;
    actions?: string;
  }
  export interface SetRelation {
    setId?: string;
    testId: string;
    test?: Test
  }
  export interface ITestListProps {
    setId: string | null;
    test: (test: Test) => void
  }
  
  export interface ITestList {
    loaded: boolean;
    tests: Array<Test>;
    filter: string;
    selected: string
  }
  export type Client = {
    clientId: string;
    name: string;
    style?: ClientStyle
  }
  type ClientStyle = {
    background: string;
    color: string
  }
  export type IFunctionListProps = {
    testId?: string
  }
  export type Category = 
  {
    categoryId: string;
    name: string
  }
  
  export type FunctionListState = {
    loaded: boolean;
    functions: Function[];
    filter: string;
    select: string
  }
  
  export type Test = {
    testId?: string;
    identifier?: string;
    isGeneral?: boolean;
    type?: "SA" | "SoD";
    description?: string;
    businessProcessId?: string;
    businessProcess?: BusinessProcess;
    categoryId?: string;
    category?: Category;
    functionRelations?: TestRelation[];
    versionNumber: number;
    versionStatus: number;
    versionComments: string
    created?: string;
    modified?: string;
    deleted?: Boolean;
    clientId?: string
  }
  export type BusinessProcess = {
    businessProcessId: string;
    name: string;
    abbreviation: string;
    description: string
  }
  
  export type TestRelation = {
    testRelationId?: number;
    order: number;
    testId?: string;
    functionId: string;
    function?: RuleFunction
  }
  export type RuleFunction = {
    functionId: string;
    identifier: string;
    type?: string;
    description: string;
    created?: string;
    modified?: string;
    clientId?: string;
    userId?: string;
    versionNumber: number;
    versionStatus: RuleStatus;
    versionComments: string;
    deleted?: boolean;
    transactions?: RuleTransaction[];
    _functionId?: string;
  }
  export type Bag = {
    bagId?: string;
    name?: string;
    description?: string;
    bagRelations?: BagRelation[];
    versionStatus: RuleStatus;
    versionNumber: number;
    versionComments?: string;
    created?: string;
    modified?: string;
    deleted?: Boolean;
    clientId?: string;
    actions?: string;
  }
  export type BagRelation = {
    bagId: string;
    setId: string;
    set?: RuleSet
  }
  export type RuleTransaction = {
    transactionId?: string;
    functionId?: string;
    identifier?: string;
    description?: string;
    type?: string;
    created?: Date;
    modified?: Date;
    clientId?: string;
    objects?: RuleObject[];
  }
  
  export type RuleObject = {
    objectId?: string;
    transactionId?: string;
    authObject?: string;
    field?: string;
    matchValues?: string;
    created?: Date;
    modified?: Date;
    clientId?: string;
  }
  export type RuleCategory = {
    categoryId: string;
    name: string;
  }
  export type RuleBusinessProcess = {
    businessProcessId: string;
    name: string;
    abbreviation: string;
    description: string;
  }
  export enum RuleStatus {
    Draft = 0,
    Current,
    Disabled
  }
  export enum IType {
    Default = 0,
    Custom
  }